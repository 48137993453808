import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
    container: {
        flexGrow: 1
    },
    imageSuccess: {
        fill: '#25D366',
        margin: 'auto',
        minWidth: '200px',
        minHeight: '200px'
    },
    imageContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: theme.spacing(8)
    },
    message: {
        color: theme.palette.primary[theme.palette.mode],
        paddingTop: theme.spacing(8),
        fontSize: 30,
        fontWeight: 500,
        textAlign: 'center'
    }
}))

export default useStyles
