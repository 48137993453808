import copy from 'copy-to-clipboard'
import dayjs from 'dayjs'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'

import Divider from '@/components/Divider'
import apiConfig from '@/config/api'
import { useFetch } from '@/hooks/useFetch'
import ApiService from '@/services/Api'
import { faKey } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    FileCopyOutlined as CopyCouponLinkIcon
} from '@mui/icons-material'
import {
    Grid,
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogActions,
    CircularProgress,
    FormControlLabel,
    Checkbox,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    TableBody,
    IconButton
} from '@mui/material'

import useStyles from './styles'

export type IPromotion = {
    uuid: string
    companyUuid: string
    title: string
    advantageDescription: string
    couponQuantityLimit?: number
    disableCouponQuantityLimit: boolean
    couponQuantityTotal?: number
    couponQuantityUsed?: number
    friendlyCode?: string
    password?: string
    beginAt: String
    finishAt?: String
    createdAt: String
    updatedAt: String
}

const Promotion: React.FC = () => {
    document.body.style.background = '#fafafa'

    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()

    const [promotions, setPromotions] = useState<IPromotion[]>([])
    const [promotion, setPromotion] = useState<IPromotion>({} as IPromotion)
    const [openedPromotionDialog, setOpenedPromotionDialog] = useState<boolean>(false)
    const [loadingPromotion, setLoadingPromotion] = useState<boolean>(false)

    const [searchInput, setSearchInput] = useState<string>('')

    const openPromotionDialog = (promotion?: IPromotion) => {
        setPromotion(
            promotion
                ? { ...promotion }
                : {
                    disableCouponQuantityLimit: false,
                    beginAt: dayjs().format('YYYY-MM-DDTHH:mm')
                } as unknown as IPromotion
        )

        setOpenedPromotionDialog(true)
    }

    const handleClosePromotionDialog = () => {
        setOpenedPromotionDialog(false)
    }

    const getPromotions = async () => {
        try {
            const { data } = await ApiService.get<IPromotion[]>('telegram|/promotion')

            setPromotions(data)
        } catch (e) {
            console.log('Erro ao buscar as promoções:', e)
        }
    }

    const savePromotion = async () => {
        try {
            setLoadingPromotion(true)

            await ApiService.post('telegram|/promotion', promotion)

            handleClosePromotionDialog()
        } catch (data) {
            enqueueSnackbar((data || 'Ocorreu um erro ao criar a promoção.') as String, {
                variant: 'error'
            })
        }

        getPromotions()

        setLoadingPromotion(false)
    }

    const handleCopyCouponLink = async (friendlyCode: string) => {
        copy(`${apiConfig.couponsBackendUrl.replace('https://', '').replace('http://', '')}/${friendlyCode}`)

        enqueueSnackbar('Link copiado com sucesso.', {
            variant: 'success'
        })
    }

    const handleCopyPromotionPassword = async (password: string) => {
        copy(password)

        enqueueSnackbar('Senha da promoção copiado com sucesso.', {
            variant: 'success'
        })
    }

    // const maskCnpjCpf = (mask?: string): string => {
    //     return '' || (mask && mask?.length <= 11 ? '999.999.999-99' : '99.999.999/9999-99')
    // }

    useFetch<IPromotion[]>(
        'telegram|/promotion',
        (data: IPromotion[]) => {
            setPromotions(data)
        }
    )

    return <>
        <Grid container justifyContent="space-between">
            <TextField
                label="Buscar promoção" size="small" color="primary" variant="outlined"
                id="promotion-search-textfield"
                value={searchInput}
                onChange={({ target }) => setSearchInput(target.value)}
            />

            <Button color="primary" variant="contained" id="promotion-open-dialog-button" onClick={() => openPromotionDialog()}>
                Criar nova promoção
            </Button>
        </Grid>

        <Divider size={3} />

        <Grid container>
            <TableContainer component={Paper} className={classes.table}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Título</TableCell>
                            <TableCell align="right">Descrição da vantagem</TableCell>
                            <TableCell align="right">Limite de cupons</TableCell>
                            <TableCell align="right">Cupons gerados</TableCell>
                            <TableCell align="right">Cupons usados</TableCell>
                            <TableCell align="right">Data de início</TableCell>
                            <TableCell align="right">Data de término</TableCell>
                            <TableCell align="right">Criado em</TableCell>
                            <TableCell align="right">Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            promotions &&
                            promotions.filter(promotion =>
                                promotion?.title?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                                    .includes(searchInput.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
                            )
                                ?.map((promotion) => (
                                    <TableRow key={promotion.uuid}>
                                        <TableCell component="th" scope="row">{promotion.title}</TableCell>
                                        <TableCell align="right">{promotion.advantageDescription}</TableCell>
                                        <TableCell align="right">{promotion.couponQuantityLimit}</TableCell>
                                        <TableCell align="right">{promotion.couponQuantityTotal}</TableCell>
                                        <TableCell align="right">{promotion.couponQuantityUsed}</TableCell>
                                        <TableCell align="right">{dayjs(String(promotion.beginAt)).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                                        <TableCell align="right">{dayjs(String(promotion.finishAt)).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                                        <TableCell align="right">{dayjs(String(promotion.createdAt)).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                                        <TableCell align="right">
                                            {/* <IconButton
                                                onClick={() => openPromotionDialog(promotion)}
                                            >
                                                <EditIcon />
                                            </IconButton> */}

                                            <IconButton
                                                id="promotion-copy-promotion-password-iconbutton"
                                                onClick={() => handleCopyPromotionPassword(String(promotion?.password || ''))}
                                                size="large">
                                                <FontAwesomeIcon icon={faKey} fixedWidth />
                                            </IconButton>

                                            <IconButton
                                                id="promotion-copy-coupon-link-iconbutton"
                                                onClick={() => handleCopyCouponLink(String(promotion?.friendlyCode || ''))}
                                                size="large">
                                                <CopyCouponLinkIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>

        <Dialog open={openedPromotionDialog}>
            <DialogTitle className={classes.dialogTitle}>
                {!promotion?.uuid ? 'Criando nova ' : 'Editando a '} promoção
            </DialogTitle>
            <Grid container className={classes.promotionDialogContainer} >
                <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                    <TextField
                        className={classes.itemInput}
                        label="Título" size="small" color="primary" variant="outlined"
                        placeholder="Drogaria Catarinense"
                        id="promotion-titulo-textfield"
                        value={promotion.title}
                        onChange={({ target }) => setPromotion({ ...promotion, title: target.value })}
                    />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                    <TextField
                        className={classes.itemInput}
                        label="Descrição da vantagem" size="small" color="primary" variant="outlined"
                        placeholder="25% de desconto"
                        id="promotion-description-textfield"
                        value={promotion.advantageDescription}
                        onChange={({ target }) => setPromotion({ ...promotion, advantageDescription: target.value })}
                    />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                    <TextField
                        className={classes.itemInput}
                        label="Quantidade limite de cupons" size="small" color="primary" variant="outlined"
                        placeholder="150"
                        id="promotion-limit-textfield"
                        value={promotion.couponQuantityLimit}
                        disabled={promotion.disableCouponQuantityLimit}
                        onChange={({ target }) => setPromotion({ ...promotion, couponQuantityLimit: +target.value })}
                    />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="promotion-set-promotion-checkbox"
                                checked={promotion.disableCouponQuantityLimit}
                                onChange={() => setPromotion({ ...promotion, disableCouponQuantityLimit: !promotion.disableCouponQuantityLimit })}
                                color="primary"
                            />
                        }
                        label="Cupons infinitos"
                    />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                    <TextField
                        label='Data de início'
                        variant='outlined'
                        size='small'
                        id="promotion-start-date-textfield"
                        color='primary'
                        type="datetime-local"
                        // className={classes.fromInput}
                        value={promotion.beginAt}
                        onChange={({ target }) => setPromotion({ ...promotion, beginAt: target.value })}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                    <TextField
                        label='Data de término'
                        variant='outlined'
                        size='small'
                        id="promotion-end-date-textfield"
                        color='primary'
                        type="datetime-local"
                        // className={classes.fromInput}
                        value={promotion.finishAt}
                        onChange={({ target }) => setPromotion({ ...promotion, finishAt: target.value })}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                </Grid>
            </Grid>

            <Divider size={2} />

            <DialogActions>
                <Button onClick={handleClosePromotionDialog} id="promotion-close-promotion-button" color="primary">
                    Cancelar
                </Button>
                <Button
                    onClick={savePromotion} id="promotion-save-promotion-button" color="primary" autoFocus variant="contained"
                    endIcon={loadingPromotion && (
                        <CircularProgress size={20} color="inherit" />
                    )}
                >
                    {promotion.uuid ? 'Salvar' : 'Criar'}
                </Button>
            </DialogActions>
        </Dialog>
    </>
}

export default Promotion
