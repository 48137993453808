import { useSnackbar } from 'notistack'
import React, { useState } from 'react'

import ApiService from '@/services/Api'
import {
    Grid,
    TextField,
    Button,
    CircularProgress
} from '@mui/material'

import useStyles from './styles'

interface ICredentials {
    phoneNumber?: string
    code?: string
    password?: string
}

type Step = 'phone' | 'code' | 'password'

type RegisterPhoneProps = {
    onSuccess: () => void
}

const RegisterPhone: React.FC<RegisterPhoneProps> = ({ onSuccess }) => {
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()

    const [credentials, setCredentials] = useState<ICredentials>()
    const [step, setStep] = useState<Step>('phone')
    const [loading, setLoading] = useState<boolean>(false)

    const sendPhoneNumber = async () => {
        setLoading(true)

        try {
            await ApiService.post('telegram|/telegram/sendPhoneNumber', {
                phoneNumber: credentials?.phoneNumber
            })

            setStep('code')
        } catch ({ data }) {
            enqueueSnackbar(String(data || 'Ocorreu um erro ao tentar se conectar ao telegram.'), { variant: 'error' })
        }

        setLoading(false)
    }

    const sendCode = async () => {
        setLoading(true)

        try {
            const { data } = await ApiService.post('telegram|/telegram/sendCode', {
                code: credentials?.code
            })

            setLoading(false)

            if (data.needPassword) return setStep('password')

            onSuccess()
            enqueueSnackbar(String(data || 'Telegram cadastrado com sucesso'), { variant: 'success' })
        } catch ({ data }) {
            enqueueSnackbar(String(data || 'Ocorreu um erro ao tentar confirmar o codigo.'), { variant: 'error' })
        }

        setLoading(false)
    }

    const sendPassword = async () => {
        setLoading(true)

        try {
            const { data } = await ApiService.post('telegram|/telegram/sendPassword', {
                password: credentials?.password
            })

            onSuccess()
            enqueueSnackbar(String(data || 'Telegram cadastrado com sucesso'), { variant: 'success' })
        } catch ({ data }) {
            enqueueSnackbar(String(data || 'Ocorreu um erro ao tentar confirmar a senha 2FA.'), { variant: 'error' })
        }

        setLoading(false)
    }

    return (
        <Grid container className={classes.container} alignContent="center" justifyContent="center">
            <Grid item xs={12} md={12} className={classes.containerContent}>
                {
                    step === 'phone' &&
                    <>
                        <TextField
                            className={classes.itemInput}
                            label="Número de celular" size="small" color="primary" variant="outlined"
                            placeholder="47 9 9999-9999"
                            id="registerphone-cellphone-number-textfield"
                            value={credentials?.phoneNumber}
                            onChange={({ target }) => setCredentials({ ...credentials, phoneNumber: target.value.replace(/\D+/g, '') })}
                        />

                        <Button
                            id="registerphone-send-phonenumber-button"
                            fullWidth
                            className={classes.nextButton}
                            color="primary"
                            variant="contained"
                            onClick={sendPhoneNumber}
                            disabled={loading}
                            endIcon={loading && (
                                <CircularProgress size={20} color="inherit" />
                            )}
                        >
                            Proximo
                        </Button>
                    </>
                }
                {
                    step === 'code' &&
                    <>
                        <TextField
                            className={classes.itemInput}
                            label="Codigo recebido no telegram" size="small" color="primary" variant="outlined"
                            placeholder="12345"
                            id="registerphone-cellphone-code-textfield"
                            value={credentials?.code}
                            onChange={({ target }) => setCredentials({ ...credentials, code: target.value.replace(/\D+/g, '') })}
                        />

                        <Button
                            id="registerphone-send-code-button"
                            fullWidth
                            className={classes.nextButton}
                            color="primary"
                            variant="contained"
                            onClick={sendCode}
                            disabled={loading}
                            endIcon={loading && (
                                <CircularProgress size={20} color="inherit" />
                            )}
                        >
                            Proximo
                        </Button>
                    </>
                }
                {
                    step === 'password' &&
                    <>
                        <TextField
                            className={classes.itemInput}
                            label="Senha de dois fatores (2FA)" size="small" color="primary" variant="outlined"
                            value={credentials?.password}
                            id="registerphone-password-textfield"
                            onChange={({ target }) => setCredentials({ ...credentials, password: target.value })}
                        />

                        <Button
                            fullWidth
                            id="registerphone-send-password-button"
                            className={classes.nextButton}
                            color="primary"
                            variant="contained"
                            onClick={sendPassword}
                            disabled={loading}
                            endIcon={loading && (
                                <CircularProgress size={20} color="inherit" />
                            )}
                        >
                            Proximo
                        </Button>
                    </>
                }
            </Grid>
        </Grid >
    )
}

export default RegisterPhone
