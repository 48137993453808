import React, { useContext } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { AuthContext } from '@/contexts/auth'
import Metrics from '@/pages/App'
import Login from '@/pages/Auth/Login'
import RecoveryPassword from '@/pages/Auth/RecoveryPassword'
import Signup from '@/pages/Auth/Signup'
import PublicCampaignMetrics from '@/pages/Public/CampaignMetrics'
import {
    Grid,
    CircularProgress
} from '@mui/material'

import useStyles from './styles'

const RoutesApp = () => {
    const classes = useStyles()

    const { loading } = useContext(AuthContext)

    if (loading) {
        return (
            <Grid container className={classes.loadingContainer} justifyContent="center" alignContent="center">
                <CircularProgress size={80} className={classes.circularProgress} />
            </Grid>
        )
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/campaigns/:campaignUuid/metrics" element={<PublicCampaignMetrics />} />
                <Route path="/c/:code" element={<PublicCampaignMetrics />} />
                <Route path="/recovery-password/:token" element={<RecoveryPassword/>} />

                <Route path="/auth/login" element={<Login />} />
                <Route path="/auth/signup" element={<Signup />} />

                <Route path="/*" element={<Metrics />} />
            </Routes>
        </BrowserRouter>
    )
}

export default RoutesApp
