import React from 'react'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import {
    Grid, SvgIcon, Typography
} from '@mui/material'

import useStyles from './styles'

const WhatsappConnected: React.FC = () => {
    const classes = useStyles()

    return (
        <Grid container alignContent="center" justifyContent="center" className={classes.container}>
            <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                className={classes.imageContainer}
            >
                <SvgIcon component={CheckCircleIcon} className={classes.imageSuccess}></SvgIcon>

                <Typography className={classes.message}>
                    Seu whatsapp está configurado e ativo.
                </Typography>
            </Grid>
        </Grid>
    )
}

export default WhatsappConnected
