import React, { useState } from 'react'

import useDidMount from '@/hooks/useMount'
import Draw from '@/pages/App/Telegram/Draw'
import RegisterPhone from '@/pages/App/Telegram/RegisterPhone'
import useStyles from '@/pages/App/Telegram/styles'
import ApiService from '@/services/Api'
import {
    Grid,
    CircularProgress
} from '@mui/material'

const Telegram: React.FC = () => {
    const classes = useStyles()

    const [telegram, setTelegram] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)

    document.body.style.background = '#fafafa'

    useDidMount(async () => {
        try {
            await ApiService.get('telegram|/telegram')

            setTelegram(true)
        } catch (error) {
            setTelegram(false)
        }

        setLoading(false)
    })

    if (loading) {
        return (
            <Grid container className={classes.loadingContainer} justifyContent="center" alignContent="center">
                <CircularProgress size={80} color="primary" />
            </Grid>
        )
    }

    if (!loading && !telegram) return <RegisterPhone onSuccess={() => setTelegram(true)} />
    else return <Draw />
}

export default Telegram
