import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
    container: {
        flexGrow: 1
    },
    table: {
        background: theme.palette.secondary.light,
        marginTop: theme.spacing(8)
    },
    drawDialogContainer: {
        padding: theme.spacing(3)
    },
    dialogTitle: {
        textAlign: 'center'
    },
    dialogItemInput: {
        padding: theme.spacing(1)
    },
    itemInput: {
        width: '100%'
    },
    formControl: {
        minWidth: '100%'
    },
    select: {
        height: theme.spacing(5)
    },
    wordFilterInput: {
        paddingRight: theme.spacing(1)
    },
    fromInput: {
        width: 260,
        // paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    toInput: {
        width: 260,
        // paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        marginRight: theme.spacing(3)
    },
    filterButton: {
        height: theme.spacing(5),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
    },
    valueText: {
        fontWeight: 500,
        fontSize: '20px',
        color: theme.palette.secondary.contrastText
    },
    NotTable: {
        fontWeight: 10,
        fontSize: '50px',
        paddingTop: theme.spacing(36),
        color: theme.palette.secondary.contrastText
    },
    buttonExit: {
        fontSize: '20px',
        flexWrap: 'wrap',
        alignContent: 'space-around',
        justifyContent: 'center'
    },
    notRemoveDraw: {
        background: '#FAFAFA',
        opacity: ''
    },
    removeDraw: {
        background: 'rgba(255, 50, 60, .2)'
    },
    deleteUserDialogCancelButton: {
        color: theme.palette.secondary.dark
    }
}))

export default useStyles
