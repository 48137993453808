import dayjs from 'dayjs'

export const smoothFluctuation = (data: {sessions: number, timestamp: string}[], maxChangePercent: number = 50): number[] => {
    if (data.length === 0) return []

    const adjustedData = data
    const FIXED_DATE = dayjs('2024-10-30')

    for (let i = 0; i < adjustedData.length - 1; i++) {
        const currentDate = dayjs(adjustedData[i].timestamp)
        const currentValue = adjustedData[i].sessions
        const nextValue = adjustedData[i + 1].sessions

        if (currentDate.isBefore(FIXED_DATE)) continue

        if (currentValue >= 100) {
            const percentageChange = Math.abs(((currentValue - nextValue) / currentValue) * 100)

            if (percentageChange >= maxChangePercent) {
                const difference = currentValue - nextValue
                const adjustment = Math.round(nextValue + difference * 0.85)

                adjustedData[i + 1].sessions = adjustment
                i--
            }
        }
    }
    return adjustedData.map(item => item.sessions)
}
