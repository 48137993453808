import { isMobileScreen } from '@/utils/device'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
    concurrenceContainer: {
        paddingTop: theme.spacing(2)
    },
    originsContainer: {
        paddingTop: theme.spacing(2),
        paddingRight: !isMobileScreen ? theme.spacing(1) : theme.spacing(0)
    },
    platformsContainer: {
        paddingTop: theme.spacing(2),
        paddingLeft: !isMobileScreen ? theme.spacing(1) : theme.spacing(0)
    },
    osContainer: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(1)
    },
    footer: {
        padding: theme.spacing(4),
        textAlign: 'center'
    }
}))

export default useStyles
