import React, { useState } from 'react'

import { CurrentAudienceContextProvider } from '@/contexts/currentAudience'
import { WhatsappQRCodeContextProvider } from '@/contexts/qrcode'
import Menu from '@/pages/App/Menu'
import Navbar from '@/pages/App/Navbar'
import MetricsRoutes from '@/routes/metrics'
import {
    Grid,
    Container
} from '@mui/material'

import useStyles from './styles'

const Metrics: React.FC = () => {
    const [openedMenu, setOpenedMenu] = useState<boolean>(false)

    const classes = useStyles()

    return (
        <Grid
            container
            direction="column"
            wrap="nowrap"
        >
            <Navbar
                onOpenMenu={() => setOpenedMenu(true)}
            />

            <Grid
                container
                className={classes.privateContainer}
            >
                <Menu
                    opened={openedMenu}
                    onClose={() => setOpenedMenu(false)}
                />

                <Grid
                    container
                    justifyContent="center"
                    className={classes.routesContainer}
                >
                    <WhatsappQRCodeContextProvider>
                        <CurrentAudienceContextProvider>
                            <Container className={classes.routesContent}>
                                <MetricsRoutes />
                            </Container>
                        </CurrentAudienceContextProvider>
                    </WhatsappQRCodeContextProvider>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Metrics
