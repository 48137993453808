import dayjs from 'dayjs'
import _ from 'lodash'
import React, { useContext, useState, useEffect } from 'react'
import { HeatMapGrid } from 'react-grid-heatmap'

import { AuthContext } from '@/contexts/auth'
import { DateFilterContext } from '@/contexts/dateFilter'
import ApiService from '@/services/Api'
import {
    Box,
    Card,
    Grid,
    Skeleton
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

import useStyles from './styles'

// mock de dados
// import mockAudienceHeatMap from './mock-audience-heatmap.json'

const AudienceHeatMap: React.FC = () => {
    const classes = useStyles()

    const theme = useTheme()

    const { darkMode } = useContext(AuthContext)

    const { dateFilter } = useContext(DateFilterContext)

    const [xLabels, setXLabels] = useState<string[]>([])
    const [yLabels, setYLabels] = useState<string[]>([])
    const [dataLabel, setDataLabel] = useState<number[][]>([])

    const hourLabel = new Array(24).fill(0).map((_, i) => `0${i}h`.slice(-3))
    const [weekdayLarge, setWeekdayLarge] = useState<string[]>([])
    const [dataSmall, setDataSmall] = useState<number[][]>([])
    const [dataLarge, setDataLarge] = useState<number[][]>([])
    const [loading, setLoading] = useState<boolean>(true)

    window.addEventListener('resize', handleResize)
    let currentWidth = window.innerWidth
    let resizeTimeout: any

    useEffect((): void => {
        async function fetchData() {
            setLoading(true)
            const { data }: any = await ApiService.get<any[]>(
                '/metrics/audience/heatmap',
                {
                    params: {
                        from: dayjs(dateFilter.from).toISOString(),
                        to: dayjs(dateFilter.to).toISOString()
                    }
                }
            )

            // mock de dados
            // const data = mockAudienceHeatMap

            const newArraySmall = cleanArray('small')
            const newArrayLarge = cleanArray('large')
            const daysOfWeekLarge: string[] = []

            for (const item of data) {
                const weekday = parseInt(item.weekday, 10)
                const hour = parseInt(item.hour, 10)

                newArrayLarge[weekday][hour] = item.sessions
                newArraySmall[hour][weekday] = item.sessions

                if (!daysOfWeekLarge.includes(['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'][weekday])) {
                    daysOfWeekLarge.push(['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'][weekday])
                }
            }

            fillData({
                weekdayLarge: daysOfWeekLarge,
                dataSmall: newArraySmall,
                dataLarge: newArrayLarge
            })
            setLoading(false)
        }
        fetchData()
    }, [dateFilter])

    function cleanArray(size: 'small' | 'large') {
        return new Array(size === 'small' ? 24 : 7).fill(0).map(() => new Array(size === 'small' ? 7 : 24).fill(0))
    }

    function reduceArray(oldArray: number[][]) {
        const finalArray = []

        for (const hour in oldArray) {
            const sum = oldArray[hour].reduce((accumulator: number, currentValue: number) => accumulator + currentValue)
            if (sum) finalArray.push(oldArray[hour])
        }

        return finalArray
    }

    async function fillData(newData: any = undefined) {
        currentWidth = window.innerWidth

        if (newData) {
            setXLabels(currentWidth > 960 ? hourLabel : ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'])
            setYLabels(currentWidth > 960 ? newData.weekdayLarge : hourLabel)
            setDataLabel(currentWidth > 960 ? reduceArray(newData.dataLarge) : newData.dataSmall)
            setDataSmall(newData.dataSmall)
            setDataLarge(reduceArray(newData.dataLarge))
            setWeekdayLarge(newData.weekdayLarge)
            return
        }

        setXLabels(currentWidth > 960 ? hourLabel : ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'])
        setYLabels(currentWidth > 960 ? weekdayLarge : hourLabel)
        setDataLabel(currentWidth > 960 ? dataLarge : dataSmall)
    }

    function handleResize() {
        clearTimeout(resizeTimeout)

        if ((currentWidth <= 960 && window.innerWidth > 960) || (currentWidth > 960 && window.innerWidth <= 960)) {
            resizeTimeout = setTimeout(fillData, 300)
        }
    }

    return (
        <Card className={classes.cardContainer}>
            <Box display="flex" justifyContent="center" alignItems="center" className={classes.titleCard}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h1 className={classes.titleText}>MAPA DE CALOR DE AUDIÊNCIA</h1>
                </div>
            </Box>
            {
                loading
                    ? <Grid item xs={12} sm={12} className={classes.loginGrid}>
                        {
                            _.times(24, (i: any) => (
                                _.times(7, (j: any) => (
                                    <Skeleton
                                        key={`${i}-${j}`}
                                        variant="rectangular"
                                        width="3%"
                                        height={35}
                                        className={classes.skeletonGrid}
                                    />
                                ))
                            ))
                        }
                    </Grid>
                    : <div className={classes.heatMapContainer}>
                        <HeatMapGrid
                            data={dataLabel}
                            xLabels={xLabels}
                            yLabels={yLabels}
                            cellHeight={window.innerWidth <= 960 ? '1.5rem' : '3rem'}
                            cellRender={(x, y, value) => (
                                <div title={`Pos(${x}, ${y}) = ${value}`}></div>
                            )}
                            xLabelsPos='bottom'
                            cellStyle={(_x, _y, ratio) => {
                                function lerp(start: any, end: any, t: any) {
                                    return start + t * (end - start)
                                }

                                function degradeColor(percentage: any) {
                                    const green = { r: 115, g: 191, b: 105 }
                                    const orange = { r: 250, g: 132, b: 15 }
                                    const red = { r: 242, g: 73, b: 92 }

                                    const startColor = percentage <= 0.5 ? green : orange
                                    const endColor = percentage <= 0.5 ? orange : red
                                    const t = percentage <= 0.5 ? percentage / 0.5 : (percentage - 0.5) / 0.5

                                    const r = Math.round(lerp(startColor.r, endColor.r, t))
                                    const g = Math.round(lerp(startColor.g, endColor.g, t))
                                    const b = Math.round(lerp(startColor.b, endColor.b, t))

                                    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`
                                }

                                return ({
                                    background: !ratio ? (dataLabel[_x][_y] > 0 ? '#87C675' : (darkMode ? '#363636' : '#ccccdc')) : degradeColor(ratio),
                                    borderColor: '#181b1f',
                                    margin: 1,
                                    fontFamily: 'Roboto',
                                    fontSize: '.7rem'
                                })
                            }}
                            yLabelsStyle={index => ({
                                color: theme.palette.primary[theme.palette.mode],
                                fontFamily: 'Roboto',
                                textAlign: window.innerWidth <= 960 ? 'middle' : 'left',
                                paddingTop: 1.95,
                                verticalAlign: 'middle'
                            })}
                            xLabelsStyle={() => ({
                                color: theme.palette.primary[theme.palette.mode],
                                textAlign: 'center',
                                fontFamily: 'Roboto',
                                textTransform: 'uppercase'
                            })}
                        />
                    </div>
            }
        </Card>
    )
}

export default AudienceHeatMap
