export type ApiConfigType = {
    metricsBackendUrl: string
    telegramBackendUrl: string
    couponsBackendUrl: string
    whatsappBackendUrl: string
}

const config: ApiConfigType = {
    metricsBackendUrl: process.env.REACT_APP_METRICS_BACKEND_URL?.toString() || '',
    telegramBackendUrl: process.env.REACT_APP_TELEGRAM_BACKEND_URL?.toString() || '',
    couponsBackendUrl: process.env.REACT_APP_COUPONS_BACKEND_URL?.toString() || '',
    whatsappBackendUrl: process.env.REACT_APP_WHATSAPP_BACKEND_URL?.toString() || ''
}

export default config
