import { Grid } from "@mui/material";
import React from "react";
import Lives from "./Lives";
import useStyles from './styles'

const Youtube: React.FC = () => {
    const classes = useStyles()

    document.title = "Youtube | Loopert";

    return (
        <Grid item xs={12} className={classes.loadingContainer}>
            <Lives />
        </Grid>
    );
};

export default Youtube;
