import axios from 'axios'

import ApiConfig from '@/config/api'

const ApiService = axios.create({ baseURL: ApiConfig.metricsBackendUrl })

ApiService.interceptors.request.use(config => {
    // @ts-ignore: Unreachable code error
    if (localStorage.getItem('token')) config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`

    const urls = config?.url?.split('|')

    if (urls && urls?.length > 1) {
        const proxies: any = {
            telegram: ApiConfig.telegramBackendUrl,
            coupons: ApiConfig.couponsBackendUrl,
            whatsapp: ApiConfig.whatsappBackendUrl
        }

        config.url = (proxies[urls[0]] || ApiConfig.metricsBackendUrl) + urls[1]
    }

    return config
})

ApiService.interceptors.response.use((config) => { return config }, (error) => {
    if (error && error.response && error.response.status !== 200) return Promise.reject(error.response)
})

export default ApiService
