import dayjs from 'dayjs'
import IMask from 'imask'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'

import Divider from '@/components/Divider'
import { useFetch } from '@/hooks/useFetch'
import useDidMount from '@/hooks/useMount'
import ApiService from '@/services/Api'
import { isMobileScreen } from '@/utils/device'
import {
    HighlightOff
} from '@mui/icons-material'
import {
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogActions,
    Typography,
    CircularProgress,
    IconButton
} from '@mui/material'

import useStyles from './styles'

export type IDateFilter = {
    from: string
    to: string
}

export interface IDraw {
    uuid: string
    telegramContact: ITelegramContact
    wordFilter?: string
    drawAt: Date
    canceledAt?: Date
}

export interface ITelegramContact {
    phoneNumber: string
    name: string
    username?: string
    picture?: string
}

const Draw: React.FC = () => {
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()

    const [searchInput, setSearchInput] = useState<string>('')
    const [draws, setDraws] = useState<IDraw[]>([])

    const [wordFilter, setWordFilter] = useState<string>('')
    const [dateFrom, setDateFrom] = useState<string>(new Date().toString())
    const [dateTo, setDateTo] = useState<string>(new Date().toString())

    const [loadingDraw, setLoadingDraw] = useState<boolean>(false)
    const [loadingDraws, setLoadingDraws] = useState<boolean>(false)
    const [openedDrawDialog, setOpenedDrawDialog] = useState<boolean>(false)
    const [openedConfirmDialog, setOpenedConfirmDialog] = useState<boolean>(false)
    const [draw, setDraw] = useState<IDraw>()
    const [uuid, setUuid] = useState<string>('')

    const formatPhoneNumberMask = (datetime?: string): string => {
        const phoneNumberMask = IMask.createMask({
            mask: '+00 00 0 0000-0000',
            lazy: false
        })

        return phoneNumberMask.resolve(datetime || '')
    }

    const valueIncludes = (value1: string, value2: string): boolean => {
        return value1.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(value2.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
    }

    const openConfirmDraw = (uuid: string) => {
        setOpenedConfirmDialog(true)
        setUuid(uuid)
    }

    const confirmDraw = async (uuid: string): Promise<void> => {
        setOpenedConfirmDialog(false)
        try {
            await ApiService.delete<IDraw>(`telegram|/draw/${uuid}`)

            enqueueSnackbar('Usuário removido com sucesso.', {
                variant: 'success'
            })
            getDraws()
        } catch (e) {
            console.log(e)
        }
    }

    const getDraws = async (): Promise<void> => {
        setLoadingDraws(true)
        try {
            const { data } = await ApiService.get<IDraw[]>('telegram|/draw')

            setDraws(data)
        } catch (e) {
            console.log(e)
        }

        setLoadingDraws(false)
    }

    const handleDraw = async (): Promise<void> => {
        try {
            setLoadingDraw(true)

            const { data } = await ApiService.post<IDraw>('telegram|/draw', {
                wordFilter,
                from: new Date(dateFrom).toISOString(),
                to: new Date(dateTo).toISOString()
            })

            setDraw(data)
            setOpenedDrawDialog(true)

            getDraws()
        } catch ({ data }) {
            enqueueSnackbar((data || 'Ocorreu um erro desconhecido ao fazer o sorteio') as String, {
                variant: 'error'
            })
        }

        setLoadingDraw(false)
    }

    useFetch<IDraw[]>(
        'telegram|/draw',
        (data: IDraw[]) => {
            setDraws(data)
        }
    )

    useDidMount(() => {
        getDraws()
    })

    return <>
        <Grid container justifyContent="space-between"
            alignItems={isMobileScreen ? 'center' : 'flex-end'}
            className={classes.container}
        >
            <TextField
                label="Buscar vencedor" size="small" color="primary" variant="outlined"
                id="draw-search-winner-textfield"
                value={searchInput}
                onChange={({ target }) => setSearchInput(target.value)}
            />

            {window.innerWidth <= 1170 && <Divider size={4} />}

            <Grid item>
                <TextField
                    label="Filtrar palavra" size="small" color="primary" variant="outlined"
                    className={classes.wordFilterInput}
                    id="draw-filter-word-textfield"
                    value={wordFilter}
                    onChange={({ target }) => setWordFilter(target.value)}
                />

                {isMobileScreen && <Divider size={2} />}

                <TextField
                    label='Data inicial'
                    variant='outlined'
                    size='small'
                    color='primary'
                    id="draw-initial-date-textfield"
                    type="datetime-local"
                    className={classes.fromInput}
                    value={dateFrom}
                    onChange={({ target }) => setDateFrom(target.value)}
                    InputLabelProps={{
                        shrink: true
                    }}
                />

                {isMobileScreen && <Divider size={2} />}

                <TextField
                    label='Data final'
                    variant='outlined'
                    size='small'
                    id="draw-final-date-textfield"
                    color='primary'
                    type="datetime-local"
                    className={classes.toInput}
                    value={dateTo}
                    onChange={({ target }) => setDateTo(target.value)}
                    InputLabelProps={{
                        shrink: true
                    }}
                />

                {isMobileScreen && (<Divider size={2} />)}

                <Button
                    id='draw-handle-draw-button'
                    color='primary'
                    className={classes.filterButton}
                    variant="contained"
                    onClick={handleDraw}
                    disabled={loadingDraw}
                    endIcon={loadingDraw && (
                        <CircularProgress size={15} color="inherit" />
                    )}
                >
                    Sortear
                </Button>
            </Grid>
        </Grid>

        <Divider size={3} />

        <Grid container justifyContent="center">
            {
                !!draws.length &&
                <TableContainer component={Paper} className={classes.table}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Nome</TableCell>
                                <TableCell align="right">Número de celular</TableCell>
                                <TableCell align="right">Nome de usuário</TableCell>
                                <TableCell align="right">Palavra Filtrada</TableCell>
                                <TableCell align="right">Sorteado em</TableCell>
                                <TableCell align="right">Removido em</TableCell>
                                <TableCell align="right">Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                draws && draws.filter(draw => (
                                    valueIncludes(draw.telegramContact.name, searchInput) ||
                                    valueIncludes(draw.telegramContact.phoneNumber, searchInput)
                                ))?.map((draw) => (
                                    <TableRow key={draw.uuid}
                                        className={draw.canceledAt !== null ? classes.removeDraw : classes.notRemoveDraw}
                                    >
                                        <TableCell component="th" scope="row">{draw.telegramContact.name}</TableCell>
                                        <TableCell align="right">{formatPhoneNumberMask(draw.telegramContact.phoneNumber)}</TableCell>
                                        <TableCell align="right">{draw.telegramContact.username}</TableCell>
                                        <TableCell align="right">{draw.wordFilter}</TableCell>
                                        <TableCell align="right">{dayjs(draw.drawAt).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                                        <TableCell align="right">{draw.canceledAt !== null ? dayjs(draw.canceledAt).format('DD/MM/YYYY HH:mm:ss') : ''}</TableCell>
                                        <TableCell align="right">
                                            <IconButton
                                                disabled={draw.canceledAt !== null}
                                                onClick={() => openConfirmDraw(draw.uuid)}
                                                size="large">
                                                < HighlightOff />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            }

            {
                (!draws.length && !loadingDraws) &&
                <Typography className={classes.NotTable}>
                    Nenhum sorteio efetuado
                </Typography>
            }
        </Grid>

        <Dialog aria-labelledby="simple-dialog-title" open={openedConfirmDialog}>
            <DialogTitle className={classes.dialogTitle}>
                Tem certeza que deseja remover? {draw?.uuid}
            </DialogTitle>

            <Divider size={2} />

            <DialogActions>
                <Button
                    id="draw-open-confirm-dialog-button"
                    onClick={() => setOpenedConfirmDialog(false)}
                    className={classes.deleteUserDialogCancelButton}
                >
                    Fechar
                </Button>
                <Button
                    onClick={() => confirmDraw(uuid)}
                    color="primary"
                    id="draw-confirm-dialog-button"
                >
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog open={openedDrawDialog}>
            <DialogTitle className={classes.dialogTitle}>
                Ganhador: {draw?.telegramContact.name}
                {/* {!company?.uuid ? 'Criando nova ' : 'Editando a '}rádio */}
            </DialogTitle>

            <Grid container className={classes.drawDialogContainer} >
                <Typography className={classes.valueText}>
                    Nome: {draw?.telegramContact.name} ({draw?.telegramContact.username})
                </Typography>
                <Divider size={1} />
                <Typography className={classes.valueText}>
                    Número de celular: {formatPhoneNumberMask(draw?.telegramContact.phoneNumber)}
                </Typography>
            </Grid>

            <Divider size={2} />

            <DialogActions className={classes.buttonExit}>
                <Button
                    onClick={() => setOpenedDrawDialog(false)}
                    color="primary"
                    autoFocus
                    variant="contained"
                    id="draw-open-draw-dialog-button"
                >
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    </>
}

export default Draw
