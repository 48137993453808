import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: '500px'
    },
    itemInput: {
        width: '100%'
    },
    nextButton: {
        marginTop: theme.spacing(2)
    },
    loadingContainer: {
        height: '100%'
    }
}))

export default useStyles
