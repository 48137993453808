import React, { useContext } from 'react'
import QRCode from 'react-qr-code'

import { WhatsappQRCodeContext } from '@/contexts/qrcode'
import {
    CircularProgress,
    Grid,
    Typography
} from '@mui/material'

import useStyles from './styles'

const Authentication: React.FC = () => {
    const classes = useStyles()
    const { qrcode } = useContext(WhatsappQRCodeContext)

    return (
        <Grid container alignContent="center" justifyContent="center" className={classes.container}>
            <Grid
                item
                xs={12}
                textAlign="center"
                className={classes.messageContainer}
            >
                <Typography
                    className={classes.messageExplanation}>

                    Abra o Whatsapp no seu celular.<br />
                    Acesse <b>Configurações</b> clicando na sua foto de perfil, <b>Menu</b> ou <b>Configurações</b><br />
                    Clique em <b>Dispositivos Conectados</b> e então em <b>Conectar um dispositivo</b><br />
                    Aponte a câmera do seu celular para o QR Code na tela.
                </Typography>
            </Grid>

            <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                className={classes.imageContainer}
            >
                <Grid className={classes.imageBoxGrid}>
                    {/* <Box
                        className={classes.imageBox}
                        component="img"
                        src={qrcode}
                        alt="qrcode"
                    /> */}
                    {
                        !qrcode
                            ? <CircularProgress size={80} color="primary" />
                            : <QRCode value={qrcode} />
                    }
                </Grid>

                <Typography className={classes.messageRemember}>
                    Lembre-se: Caso o seu celular não reconheça o QR Code diretamente pela câmera, entre em contato com o <a href="https://wa.me/554784742579?text=%22Ol%C3%A1,%20tive%20problemas%20ao%20ler%20o%20QR%20Code%20para%20conectar%20meu%20whatsapp%20a%20plataforma%20Metrics.%22">suporte da Loopert pelo Whatsapp</a>.
                </Typography>
            </Grid>
        </Grid>
    )
}

export default Authentication
