import dayjs from 'dayjs'
import { set } from 'lodash'
import { useSnackbar } from 'notistack'
import React, { useContext, useState } from 'react'

import Divider from '@/components/Divider'
import { AuthContext } from '@/contexts/auth'
import { WeekdayTypeEnum } from '@/enum/WeekdayTypeEnum'
import { getWeekdayListLiteral } from '@/helpers/getWeekdayListLiteral'
import { useFetch } from '@/hooks/useFetch'
import ApiService from '@/services/Api'
import {
    DeleteOutline as DeleteIcon,
    EditOutlined as EditIcon
} from '@mui/icons-material'
import {
    Grid,
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogActions,
    CircularProgress,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    TableBody,
    tableCellClasses,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    Stack,
    IconButton,
    Typography,
    useTheme
} from '@mui/material'

import useStyles from './styles'

type IGrid = {
    uuid?: string
    weekdays: Array<WeekdayTypeEnum>
    startAt: string | null
    endAt: string | null
}

export type IProgram = {
    uuid: string
    companyUuid: string
    name: string
    segment: Array<string>
    grid: Array<IGrid>,
    createdAt: Date
    updatedAt: Date
}

const Program: React.FC = () => {
    const { darkMode } = useContext(AuthContext)

    const theme = useTheme()

    const tableCellStyle = {
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: darkMode ? '#181924' : '#fafafa',
            color: theme.palette.success[theme.palette.mode]
        },
        [`&.${tableCellClasses.body}`]: {
            color: theme.palette.success[theme.palette.mode]
        }
    }

    const tableRowStyle = {
        '&:nth-of-type(odd)': {
            backgroundColor: darkMode ? '#181924' : '#fafafa',
            color: theme.palette.success[theme.palette.mode]
        },
        '&:nth-of-type(even)': {
            backgroundColor: darkMode ? '#181924' : '#fafafa',
            color: theme.palette.success[theme.palette.mode]
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0
        }
    }

    const textFieldStyle = {
        '& ::-webkit-calendar-picker-indicator': {
            filter: 'invert(1)'
        },
        '& .MuiInputLabel-root': {
            color: theme.palette.success[theme.palette.mode]
        },
        '& .MuiOutlinedInput-root': {
            color: `${theme.palette.success[theme.palette.mode]} '!important'`,
            '& fieldset': {
                borderColor: `${theme.palette.success[theme.palette.mode]} '!important'`
            }
        }
    }

    const formControlStyle = {
        '& .MuiInputLabel-root': {
            color: `${theme.palette.success[theme.palette.mode]} '!important'`
        },
        '& .MuiOutlinedInput-root': {
            color: `${theme.palette.success[theme.palette.mode]} '!important'`,
            '& fieldset': {
                borderColor: `${theme.palette.success[theme.palette.mode]} '!important'`
            }
        },
        '& .MuiSvgIcon-root': {
            fill: `${theme.palette.success[theme.palette.mode]} '!important'`
        }
    }

    document.title = 'Programas | Loopert'
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()

    const [programs, setPrograms] = useState<IProgram[]>([])
    const [program, setProgram] = useState<IProgram>({} as IProgram)
    const [grid, setGrid] = useState<IGrid>({ startAt: null, endAt: null } as IGrid)
    const [deleteUuid, setDeleteUuid] = useState<string>('')
    const [openedProgramDialog, setOpenedProgramDialog] = useState<boolean>(false)
    const [openedDeleteConfirmationDialog, setOpenedDeleteConfirmationDialog] = useState<boolean>(false)
    const [loadingProgram, setLoadingProgram] = useState<boolean>(false)
    const [isValidTimeRang, setIsValidTimeRang] = useState<boolean>(false)

    const [searchInput, setSearchInput] = useState<string>('')

    const openProgramDialog = (program?: IProgram) => {
        setProgram(
            program || ({ segment: [] } as unknown as IProgram)
        )
        setGrid({ startAt: null, endAt: null, weekdays: [dayjs().day()] } as IGrid)

        setOpenedProgramDialog(true)
    }

    const validateTimeRange = (startAt: string, endAt: string) => {
        const start = parseFloat(startAt.split(':').join('.'))
        const end = parseFloat(endAt.split(':').join('.'))

        const timeRange = start > end ? (end + 24) - start : end - start

        return timeRange <= 10
    }

    const validateClientGrid = (startAt: string, endAt: string, weekdays: Array<number>, uuid?: string) => {
        const start = parseFloat(startAt.split(':').join('.'))
        const end = parseFloat(endAt.split(':').join('.'))

        return !(program?.grid && program?.grid?.length > 0 && program?.grid?.filter(_grid => !uuid || _grid.uuid !== uuid).some(_grid => {
            const gridStart = parseFloat(_grid?.startAt!.split(':').join('.'))
            const gridEnd = parseFloat(_grid?.endAt!.split(':').join('.'))
            const someWeekdays = _grid.weekdays.some(weekday => weekdays.includes(weekday))
            const someLastWeekdays = _grid.weekdays.some(weekday => weekdays.includes(weekday === 0 ? 6 : weekday - 1))
            const someNextWeekdays = _grid.weekdays.some(weekday => weekdays.includes(weekday === 6 ? 0 : weekday + 1))

            return Boolean(
                (
                    someNextWeekdays &&
                    start > end &&
                    (
                        gridStart < end ||
                        gridEnd < end
                    )
                ) ||
                (
                    someWeekdays &&
                    (
                        (
                            (
                                gridStart <= start &&
                                gridEnd >= end
                            ) &&
                            (start < end)
                        ) ||
                        (
                            gridStart < gridEnd &&
                            start < end &&
                            (
                                (
                                    gridStart > start &&
                                    gridEnd < end
                                ) ||
                                (
                                    gridStart <= start &&
                                    gridEnd > start
                                ) ||
                                (
                                    gridStart < end &&
                                    gridEnd >= end
                                )
                            )
                        ) ||
                        (
                            gridStart > gridEnd &&
                            (
                                gridStart < start ||
                                gridStart < end ||
                                (gridEnd > end && start > end) ||
                                (gridStart >= start && start > end)
                            )
                        )
                    )
                ) ||
                (
                    someLastWeekdays &&
                    gridStart > gridEnd &&
                    start < end &&
                    (
                        gridEnd > start ||
                        gridEnd > end
                    )
                )

            )
        }))
    }

    const validateGrid = async (_grid: IGrid) => {
        const startAt = _grid.startAt
        const endAt = _grid.endAt

        if (!startAt || !endAt) {
            return setIsValidTimeRang(false)
        }

        if (startAt === endAt) {
            enqueueSnackbar(('O horário de inicio e término não podem ser iguais.') as String, {
                variant: 'error'
            })

            return setIsValidTimeRang(false)
        }

        if (!startAt || !endAt) {
            enqueueSnackbar(('É necessário selecionar os horários de inicio e fim.') as String, {
                variant: 'error'
            })

            return setIsValidTimeRang(false)
        }

        if (_grid.weekdays.length <= 0) {
            enqueueSnackbar(('É necessário selecionar pelo menos um dia da semana.') as String, {
                variant: 'error'
            })

            return setIsValidTimeRang(false)
        }

        const validTimeRange: boolean = validateTimeRange(startAt, endAt)

        if (!validTimeRange) {
            enqueueSnackbar(('A diferença de horário não pode ser maior que 10 horas.') as String, {
                variant: 'error'
            })

            return setIsValidTimeRang(false)
        }

        const validGrid: boolean = validateClientGrid(startAt, endAt, _grid.weekdays, _grid.uuid)

        if (!validGrid) {
            enqueueSnackbar(('Horário de programa não disponível para os dias selecionados.') as String, {
                variant: 'error'
            })

            return setIsValidTimeRang(false)
        }

        await ApiService.get<IProgram[]>(`/metrics/program/is-available?startAt=${startAt}&endAt=${endAt}&weekdays=${_grid.weekdays.join('_')}${_grid.uuid ? `&uuid=${_grid.uuid}` : ''}`)
            .then(() => {
                setIsValidTimeRang(true)
            })
            .catch(() => {
                enqueueSnackbar(('Horário de programa não disponível para os dias selecionados.') as String, {
                    variant: 'error'
                })
                setIsValidTimeRang(false)
            })
    }

    const handleCloseProgramDialog = () => {
        setOpenedProgramDialog(false)
    }

    const handleValidateGrid = async ({
        startAt,
        endAt
    }: {
        startAt?: string,
        endAt?: string,
        weekday?: string
    }) => {
        let _grid: IGrid = grid

        if (startAt) _grid = { ...grid, startAt }
        if (endAt) _grid = { ...grid, endAt }

        await validateGrid(_grid)
    }

    const handleSetGrid = async ({
        startAt,
        endAt
    }: {
        startAt?: string,
        endAt?: string,
        weekday?: string
    }) => {
        let _grid: IGrid = grid

        if (startAt) _grid = { ...grid, startAt }
        if (endAt) _grid = { ...grid, endAt }

        setGrid(_grid)
    }

    const getPrograms = async () => {
        try {
            const { data } = await ApiService.get<IProgram[]>('/metrics/program')

            setPrograms(data)
        } catch (e) {
            console.log('Erro ao buscar os programas:', e)
        }
    }

    const saveProgram = async () => {
        try {
            setLoadingProgram(true)

            await ApiService.post('/metrics/program', program)

            handleCloseProgramDialog()
        } catch (data) {
            enqueueSnackbar((data || 'Ocorreu um erro ao criar o programa.') as String, {
                variant: 'error'
            })
        }

        getPrograms()

        setLoadingProgram(false)
    }

    const handleWeekdayList = async ({ weekday }: { weekday: WeekdayTypeEnum }) => {
        const { weekdays } = grid

        let _grid: IGrid

        if (!weekdays) {
            _grid = { ...grid, weekdays: [weekday] }
        } else if (weekdays.includes(weekday)) {
            weekdays.splice(weekdays.indexOf(weekday), 1)

            _grid = { ...grid, weekdays }
        } else {
            _grid = { ...grid, weekdays: [...weekdays, weekday] }
        }

        setGrid(_grid)

        await validateGrid(_grid)
    }

    const handleSetProgramGrid = async () => {
        const gridList = program?.grid

        if (grid.uuid) {
            handleRemoveGrid(program.grid.indexOf(grid))
        }

        if (!gridList) {
            setProgram({
                ...program,
                grid: [{
                    ...(grid.uuid ? { uuid: grid.uuid } : {}),
                    ...{
                        weekdays: grid.weekdays,
                        startAt: grid.startAt,
                        endAt: grid.endAt
                    }
                }]
            })
        } else {
            setProgram({
                ...program,
                grid: [
                    ...gridList,
                    {
                        weekdays: grid.weekdays,
                        startAt: grid.startAt,
                        endAt: grid.endAt
                    }
                ]
            })
        }

        setGrid({ startAt: null, endAt: null, weekdays: [dayjs().day()] } as IGrid)
        setIsValidTimeRang(false)
    }

    const handleRemoveGrid = (index: number) => {
        const programGrid = program.grid

        programGrid.splice(index)

        setProgram({ ...program, grid: programGrid })
    }

    const handleProgramsFilled = (): boolean => {
        return Boolean(program?.name &&
            program?.segment?.length > 0 &&
            program?.grid?.length > 0
        )
    }

    const deleteProgram = async () => {
        setOpenedDeleteConfirmationDialog(false)
        try {
            await ApiService.delete<IProgram>(`/metrics/program/${deleteUuid}`)

            enqueueSnackbar('Programa removido com sucesso.', {
                variant: 'success'
            })
            getPrograms()
        } catch (e) {
            enqueueSnackbar('Não foi possivel apagar o programa.', {
                variant: 'error'
            })
        }
    }

    useFetch<IProgram[]>(
        '/metrics/program',
        (data: IProgram[]) => {
            setPrograms(data)
        }
    )

    return <>
        <Grid container justifyContent="space-between">
            <TextField
                className={classes.inputs}
                label="Buscar programa"
                size="small" color="info" variant="outlined"
                value={searchInput}
                onChange={({ target }) => setSearchInput(target.value)}
                sx={textFieldStyle}
            />

            <Button color="primary" className={classes.inputs} id="program-open-dialog-button" variant="contained" onClick={() => openProgramDialog()}>
                Criar novo programa
            </Button>
        </Grid>

        <Divider size={3} />

        <Grid container className={classes.tableContainer}>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={tableCellStyle}>Nome</TableCell>
                            <TableCell sx={tableCellStyle} align="center">Segmento</TableCell>
                            <TableCell sx={tableCellStyle} align="left">Grade</TableCell>
                            <TableCell sx={tableCellStyle} align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            programs &&
                            programs.filter(program =>
                                program?.name?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                                    .includes(searchInput.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
                            )
                                ?.map((program) => (
                                    <TableRow sx={tableRowStyle} key={program?.uuid}>
                                        <TableCell sx={tableCellStyle} component="th" scope="row">{program?.name}</TableCell>
                                        <TableCell sx={tableCellStyle} align="center">{program?.segment.join(', ')}</TableCell>
                                        <TableCell sx={tableCellStyle} align="left">{
                                            program?.grid?.map(_grid => (
                                                <>
                                                    <Typography>
                                                        {_grid.startAt} a {_grid.endAt} ({_grid.weekdays.map(weekday => getWeekdayListLiteral(weekday)).join(', ')})
                                                    </Typography>
                                                </>
                                            )
                                            )
                                        }</TableCell>
                                        <TableCell sx={tableCellStyle} align="right">
                                            <IconButton
                                                id="program-open-dialog-iconbutton"
                                                color={darkMode ? 'secondary' : 'primary'}
                                                onClick={() => {
                                                    openProgramDialog(program)
                                                }}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                id="program-delete-uuid-iconbutton"
                                                color={darkMode ? 'secondary' : 'primary'}
                                                onClick={() => {
                                                    setOpenedDeleteConfirmationDialog(true)
                                                    setDeleteUuid(program?.uuid)
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>

        <Dialog open={openedProgramDialog}>
            <DialogTitle className={classes.dialogTitle}>
                {!program?.uuid ? 'Criando novo ' : 'Editando o '} programa
            </DialogTitle>
            <Grid container className={classes.dialogContainer}>
                <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                    <TextField
                        className={classes.itemInput}
                        label="Nome" size="small" color="info" variant="outlined"
                        placeholder="Jornal do almoço"
                        value={program?.name}
                        onChange={({ target }) => setProgram({ ...program, name: target.value })}
                        sx={textFieldStyle}
                    />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.selectItemInput}>
                    <FormControl sx={formControlStyle} className={classes.formControl}>
                        <InputLabel htmlFor="segment" className={classes.selectInput}>Segmento</InputLabel>
                        <Select
                            onChange={({ target }) => {
                                setProgram({ ...program, segment: typeof target.value === 'string' ? [target.value] : target.value })
                            }}
                            variant="outlined"
                            color='info'
                            id="segment"
                            label="Segmento"
                            multiple
                            className={classes.itemSelect}
                            value={program?.segment}
                        >
                            <MenuItem value="Notícias e Jornalismo">Notícias e Jornalismo</MenuItem>
                            <MenuItem value="Esportes">Esportes</MenuItem>
                            <MenuItem value="Musical e Entretenimento">Musical e Entretenimento</MenuItem>
                            <MenuItem value="Entrevistas">Entrevistas</MenuItem>
                            <MenuItem value="Cultural">Cultural</MenuItem>
                            <MenuItem value="Humor">Humor</MenuItem>
                            <MenuItem value="Educativo">Educativo</MenuItem>
                            <MenuItem value="Programas de Opinião">Programas de Opinião</MenuItem>
                            <MenuItem value="Religiosos">Religiosos</MenuItem>
                            <MenuItem value="Saúde e Bem-estar">Saúde e Bem-estar</MenuItem>
                            <MenuItem value="Lifestyle e Moda">Lifestyle e Moda</MenuItem>
                            <MenuItem value="Viagem e Turismo">Viagem e Turismo</MenuItem>
                            <MenuItem value="Infantil">Infantil</MenuItem>
                            <MenuItem value="Tecnologia e Ciência">Tecnologia e Ciência</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid container>
                    <Stack className={classes.stackWeek}>
                        <Grid item xs={12} sm={12} style={{ textAlign: 'center' }}>
                            <Button
                                id='program-weekday-list-dom-button'
                                color={darkMode ? 'secondary' : 'primary'}
                                variant={grid?.weekdays?.includes(WeekdayTypeEnum.DOMINGO) ? 'contained' : 'outlined'}
                                size="large"
                                onClick={
                                    () => grid?.weekdays?.length === 1 &&
                                    grid?.weekdays?.some(weekday => weekday === WeekdayTypeEnum.DOMINGO)
                                        ? () => {}
                                        : handleWeekdayList({ weekday: WeekdayTypeEnum.DOMINGO })
                                }
                                className={classes.weekdayButton}
                            >D</Button>
                            <Button
                                id='program-weekday-list-seg-button'
                                color={darkMode ? 'secondary' : 'primary'}
                                variant={grid?.weekdays?.includes(WeekdayTypeEnum.SEGUNDA) ? 'contained' : 'outlined'}
                                size="large"
                                onClick={
                                    () => grid?.weekdays?.length === 1 &&
                                    grid?.weekdays?.some(weekday => weekday === WeekdayTypeEnum.SEGUNDA)
                                        ? () => {}
                                        : handleWeekdayList({ weekday: WeekdayTypeEnum.SEGUNDA })
                                }
                                className={classes.weekdayButton}
                            >S</Button>
                            <Button
                                id='program-weekday-list-ter-button'
                                color={darkMode ? 'secondary' : 'primary'}
                                variant={grid?.weekdays?.includes(WeekdayTypeEnum.TERCA) ? 'contained' : 'outlined'}
                                size="large"
                                onClick={
                                    () => grid?.weekdays?.length === 1 &&
                                    grid?.weekdays?.some(weekday => weekday === WeekdayTypeEnum.TERCA)
                                        ? () => {}
                                        : handleWeekdayList({ weekday: WeekdayTypeEnum.TERCA })
                                }
                                className={classes.weekdayButton}
                            >T</Button>
                            <Button
                                id='program-weekday-list-qua-button'
                                color={darkMode ? 'secondary' : 'primary'}
                                variant={grid?.weekdays?.includes(WeekdayTypeEnum.QUARTA) ? 'contained' : 'outlined'}
                                size="large"
                                onClick={
                                    () => grid?.weekdays?.length === 1 &&
                                    grid?.weekdays?.some(weekday => weekday === WeekdayTypeEnum.QUARTA)
                                        ? () => {}
                                        : handleWeekdayList({ weekday: WeekdayTypeEnum.QUARTA })
                                }
                                className={classes.weekdayButton}
                            >Q</Button>
                            <Button
                                id='program-weekday-list-qui-button'
                                color={darkMode ? 'secondary' : 'primary'}
                                variant={grid?.weekdays?.includes(WeekdayTypeEnum.QUINTA) ? 'contained' : 'outlined'}
                                size="large"
                                onClick={
                                    () => grid?.weekdays?.length === 1 &&
                                    grid?.weekdays?.some(weekday => weekday === WeekdayTypeEnum.QUINTA)
                                        ? () => {}
                                        : handleWeekdayList({ weekday: WeekdayTypeEnum.QUINTA })
                                }
                                className={classes.weekdayButton}
                            >Q</Button>
                            <Button
                                id='program-weekday-list-sex-button'
                                color={darkMode ? 'secondary' : 'primary'}
                                variant={grid?.weekdays?.includes(WeekdayTypeEnum.SEXTA) ? 'contained' : 'outlined'}
                                size="large"
                                onClick={
                                    () => grid?.weekdays?.length === 1 &&
                                    grid?.weekdays?.some(weekday => weekday === WeekdayTypeEnum.SEXTA)
                                        ? () => {}
                                        : handleWeekdayList({ weekday: WeekdayTypeEnum.SEXTA })
                                }
                                className={classes.weekdayButton}
                            >S</Button>
                            <Button
                                id='program-weekday-list-sab-button'
                                color={darkMode ? 'secondary' : 'primary'}
                                variant={grid?.weekdays?.includes(WeekdayTypeEnum.SABADO) ? 'contained' : 'outlined'}
                                size="large"
                                onClick={
                                    () => grid?.weekdays?.length === 1 &&
                                    grid?.weekdays?.some(weekday => weekday === WeekdayTypeEnum.SABADO)
                                        ? () => {}
                                        : handleWeekdayList({ weekday: WeekdayTypeEnum.SABADO })
                                }
                                className={classes.weekdayButton}
                            >S</Button>
                        </Grid>

                    </Stack>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <TextField
                            className={classes.itemInput}
                            type='time'
                            label="Horário de início" size="small" color="info" variant="outlined"
                            value={grid.startAt}
                            onChange={({ target }) => handleSetGrid({ startAt: target.value })}
                            onBlur={({ target }) => handleValidateGrid({ startAt: target.value })}
                            InputLabelProps={{
                                shrink: true
                            }}
                            sx={textFieldStyle}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.dialogItemInput}>
                        <TextField
                            className={classes.itemInput}
                            type='time'
                            label="Horário do término" size="small" color="info" variant="outlined"
                            value={grid.endAt}
                            onChange={({ target }) => handleSetGrid({ endAt: target.value })}
                            onBlur={({ target }) => handleValidateGrid({ endAt: target.value })}
                            disabled={!grid.startAt}
                            InputLabelProps={{
                                shrink: true
                            }}
                            sx={textFieldStyle}
                        />
                    </Grid>

                    <Button
                        onClick={handleSetProgramGrid}
                        color={darkMode ? 'secondary' : 'primary'}
                        autoFocus
                        variant="outlined"
                        className={isValidTimeRang ? classes.setGridButtonValid : classes.setGridButtonNotValid}
                        disabled={!isValidTimeRang}
                    >
                        {!grid.uuid ? 'Adicionar' : 'Editar'}
                    </Button>

                    <TableContainer component={Paper} className={classes.gridTable}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={tableCellStyle}>Dias da Semana</TableCell>
                                    <TableCell sx={tableCellStyle} align="right">Horário início</TableCell>
                                    <TableCell sx={tableCellStyle} align="right">Horário término</TableCell>
                                    <TableCell sx={tableCellStyle} align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    program?.grid?.map((_grid) => (
                                        <TableRow sx={tableRowStyle} key={program?.uuid}>
                                            <TableCell sx={tableCellStyle} component="th" scope="row">{
                                                _grid.weekdays
                                                    .map((weekday: WeekdayTypeEnum) => getWeekdayListLiteral(weekday)).join(', ')
                                            }</TableCell>
                                            <TableCell sx={tableCellStyle} align="right">{_grid.startAt}</TableCell>
                                            <TableCell sx={tableCellStyle} align="right">{_grid.endAt}</TableCell>
                                            <TableCell sx={tableCellStyle} align="right">
                                                <IconButton
                                                    color="secondary"
                                                    style={{ color: theme.palette.success[theme.palette.mode] }}
                                                    onClick={() => {
                                                        setGrid({
                                                            ..._grid,
                                                            startAt: _grid.startAt,
                                                            endAt: _grid.endAt
                                                        })
                                                    }}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    id="program-remove-grid-iconbutton"
                                                    style={{ color: theme.palette.success[theme.palette.mode] }}
                                                    onClick={() => handleRemoveGrid(program.grid.indexOf(_grid))}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

            <DialogActions className={classes.dialogActions}>
                <Button onClick={handleCloseProgramDialog} id="program-handle-close-dialog-button" color={darkMode ? 'secondary' : 'primary'}
                >
                    Cancelar
                </Button>
                <Button
                    onClick={saveProgram} color={darkMode ? 'secondary' : 'primary'}
                    autoFocus variant="contained"
                    id="program-save-program-button"
                    disabled={!handleProgramsFilled()}
                    endIcon={loadingProgram && (
                        <CircularProgress size={20} color="inherit" />
                    )}
                >
                    {program?.uuid ? 'Salvar' : 'Criar'}
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog aria-labelledby="simple-dialog-title" open={openedDeleteConfirmationDialog}>
            <DialogTitle className={classes.dialogTitle}>
                Tem certeza que deseja remover?
            </DialogTitle>

            <DialogActions className={classes.dialogActions}>
                <Button
                    onClick={() => setOpenedDeleteConfirmationDialog(false)}
                    id='program-delete-confirmation-button'
                    className={classes.deleteProgramDialogCancelButton}

                >
                    Fechar
                </Button>
                <Button
                    onClick={() => deleteProgram()}
                    color={darkMode ? 'secondary' : 'primary'}
                    id="program-delete-program-button"
                >
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    </>
}

export default Program
