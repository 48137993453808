import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
    cardContainer: {
        padding: 0,
        background: theme.palette.secondary[theme.palette.mode], // theme.palette.secondary.light
        border: '1px solid #ccccdc12',
        position: 'relative'
    },
    difference: {
        fontSize: '1rem'
    },
    cardContent: {
        background: theme.palette.secondary[theme.palette.mode],
        paddingBottom: `${theme.spacing(1)} !important`,
        paddingLeft: theme.spacing(3)
    },
    valueGrid: {
        height: '52px',
        whiteSpace: 'nowrap'
    },
    valueText: {
        fontWeight: 700,
        fontSize: '2.225rem',
        color: '#73bf69', // theme.palette.secondary.contrastText
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    titleGrid: {
        height: '26px',
        whiteSpace: 'nowrap',
        textOverflow: 'hidden'
    },
    titleText: {
        fontSize: '1.1rem',
        color: theme.palette.primary[theme.palette.mode],
        paddingLeft: theme.spacing(0.2)
    },
    chipIncreasedValue: {
        marginLeft: theme.spacing(2),
        color: theme.palette.background.default, // theme.palette.primary.contrastText,
        fontSize: '15px',
        right: 0,
        cursor: 'pointer'
    },
    chipIconIncreasedValue: {
        color: `${theme.palette.secondary[theme.palette.mode]} !important`
    },
    chipIncreasedValueHelper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        fontSize: '15px',
        color: '#181b1f'// theme.palette.primary.contrastText
    },
    helperMessageChip: {
        position: 'absolute',
        color: '#fafafa', // theme.palette.primary.contrastText,
        fontSize: '15px',
        right: -10,
        top: -2,
        cursor: 'pointer'
    },
    helperMessageDialog: {
        background: theme.palette.secondary[theme.palette.mode],
        color: theme.palette.primary[theme.palette.mode]
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2)
    },
    titleWhatsappIcon: {
        marginBottom: '-4px',
        marginLeft: '3px',
        height: '20px'
    },
    betaChip: {
        // marginBottom: '-4px',
        marginLeft: '6px',
        height: '17px',
        paddingTop: '2.5px'
    }
}))

export default useStyles
