import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        height: '100%'
    },
    cardContainer: {
        maxWidth: '90%',
        width: '350px',
        minWidth: '280px',
        background: theme.palette.secondary.light,
        borderRadius: theme.spacing(1),
        padding: theme.spacing(2)
    },
    cardContent: {
        borderBottom: `${theme.spacing(1)} !important`
    },
    input: {
        paddingBottom: theme.spacing(2)
    },
    signinButton: {
        marginTop: theme.spacing(2),
        paddingTop: theme.spacing(1),
        fontWeight: 700,
        background: 'linear-gradient(40deg, rgba(46, 94, 170, 0.70) 0%, rgba(46, 94, 170, 1) 100%)'
    },
    recoveryLink: {
        fontSize: 14,
        fontWeight: 700
    },
    logoGrid: {
        maxHeight: '7%',
        marginTop: '-6%',
        marginBottom: theme.spacing(2)
    },
    logoImage: {
        height: '70px'
    }
}))

export default useStyles
