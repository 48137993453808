import { Chart, CategoryScale, BarElement, PointElement, LineElement, LinearScale, TimeSeriesScale, Legend, Tooltip, TooltipModel, TooltipItem } from 'chart.js'
import _ from 'lodash'
import React, { useContext, useMemo, useState } from 'react'
import { Bar } from 'react-chartjs-2'

import WarningIllustration from '@/assets/illustrations/warning.svg'
import { AuthContext } from '@/contexts/auth'
import { DateFilterContext } from '@/contexts/dateFilter'
import ApiService from '@/services/Api'
import {
    Grid,
    Typography,
    Card,
    CardContent,
    Skeleton
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

import useStyles from './styles'

interface ColumnsDataType {
    [key: string]: string | number
}

type ColumnsProps = {
    fieldName: string
    columnName: string
}

Chart.register(CategoryScale, BarElement, PointElement, LineElement, TimeSeriesScale, Legend, Tooltip, LinearScale)

const Columns: React.FC<ColumnsProps> = ({ columnName, fieldName }) => {
    const classes = useStyles()

    const theme = useTheme()

    const { dateFilter } = useContext(DateFilterContext)
    const { darkMode } = useContext(AuthContext)

    const [chartData, setChartData] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(true)

    const getColumnsData = async () => {
        setLoading(true)

        const { data } = await ApiService.get<ColumnsDataType[]>(
            `/metrics/listeners/${columnName}?from=${dateFilter.from}&to=${dateFilter.to}&limit=5`
        )

        // mock de dados
        // const fieldName = 'origin'

        // const data = [
        //     {
        //         quantity: 162,
        //         origin: 'Aplicativo'
        //     },
        //     {
        //         quantity: 123,
        //         origin: 'Outros'
        //     },
        //     {
        //         quantity: 85,
        //         origin: 'Agregadores'
        //     },
        //     {
        //         quantity: 63,
        //         origin: 'Site'
        //     }
        // ]

        const labels = data?.map(row => row[fieldName])

        const datasets = [{
            tension: 0.2,
            fill: false,
            borderColor: '#73bf69',
            backgroundColor: darkMode ? '#73bf6980' : '#299a1a80',
            borderWidth: 1,
            yAxisID: 'y',
            data: data?.map(row => +row.quantity)
        }]

        setChartData({
            labels,
            datasets
        })

        setLoading(false)
    }

    useMemo(() => {
        getColumnsData()
    }, [dateFilter, darkMode])

    return (
        <Card className={classes.cardContainer}>
            <CardContent className={classes.cardContent}>
                <Grid container className={classes.containerGrid}>
                    {
                        loading
                            ? (
                                <Grid item xs={12} sm={12} className={classes.skeletonInsideGrid}>
                                    {
                                        _.times(5, (i: any) => (
                                            <Skeleton
                                                key={i}
                                                variant="rectangular" width="14%" height={(5 - i + 1) * 50}
                                                style={{
                                                    float: 'left',
                                                    marginLeft: '5%',
                                                    marginTop: 338 - ((5 - i + 1) * 50)
                                                }}
                                            />
                                        ))
                                    }
                                </Grid>
                            )
                            : (
                                <>
                                    <Grid item xs={12} sm={12} className={classes.cardTitle}>
                                        <Grid container justifyContent="center">
                                            <Typography className={classes.titleText}>
                                                {columnName === 'cities' && 'OUVINTES POR CIDADE'}
                                                {columnName === 'platforms' && 'OUVINTES POR PLATAFORMA'}
                                                {columnName === 'origins' && 'OUVINTES POR ORIGEM'}
                                                {/* {columnName === 'os' && ' OS'} */}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        {
                                            !loading
                                                ? (
                                                    chartData &&
                                                    chartData?.datasets &&
                                                    <Bar
                                                        height={120}
                                                        data={chartData}
                                                        options={{
                                                            plugins: {
                                                                legend: {
                                                                    display: false
                                                                },
                                                                tooltip: {
                                                                    backgroundColor: '#202227',
                                                                    titleColor: '#ccccdc',
                                                                    boxHeight: 1,
                                                                    boxPadding: 3,
                                                                    bodySpacing: 5,
                                                                    callbacks: {
                                                                        label: function (this: TooltipModel<'bar'>, tooltipItem: TooltipItem<'bar'>) {
                                                                            return tooltipItem.formattedValue + ' ouvintes'
                                                                        }
                                                                    }
                                                                }
                                                            },
                                                            responsive: true,
                                                            interaction: {
                                                                mode: 'index',
                                                                intersect: false
                                                            },
                                                            elements: {
                                                                point: {
                                                                    radius: 0
                                                                }
                                                            },
                                                            scales: {
                                                                x: {
                                                                    type: 'category',
                                                                    grid: {
                                                                        color: theme.palette.info[theme.palette.mode],
                                                                        borderColor: theme.palette.info[theme.palette.mode]
                                                                    },
                                                                    ticks: {
                                                                        color: theme.palette.primary[theme.palette.mode]
                                                                    }
                                                                },
                                                                y: {
                                                                    type: 'linear',
                                                                    display: true,
                                                                    position: 'left',
                                                                    grid: {
                                                                        color: theme.palette.info[theme.palette.mode],
                                                                        borderColor: theme.palette.info[theme.palette.mode]
                                                                    },
                                                                    ticks: {
                                                                        color: theme.palette.primary[theme.palette.mode]
                                                                    }
                                                                }
                                                            }
                                                        }}
                                                    />
                                                )
                                                : (
                                                    <>
                                                        <Grid container
                                                            justifyContent="center"
                                                            alignContent="center"
                                                            className={classes.warningIllustrationGridContainer}
                                                        >
                                                            <img
                                                                className={classes.warningIllustration}
                                                                src={WarningIllustration}
                                                                alt="Não encontramos nenhum resultado"
                                                            />
                                                        </Grid>
                                                        <Grid container
                                                            justifyContent="center"
                                                            alignContent="center"
                                                            alignItems="center"
                                                        // className={classes.warningIllustrationGridContainer}
                                                        >
                                                            <Typography className={classes.warningIllustrationText}>
                                                                Não encontramos nenhum resultado
                                                            </Typography>
                                                        </Grid>
                                                    </>
                                                )
                                        }
                                    </Grid>
                                </>
                            )
                    }
                </Grid>
            </CardContent>
        </Card>
    )
}

export default Columns
