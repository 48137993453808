import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        height: '100%'
    },
    cardContainer: {
        maxWidth: '90%',
        width: '350px',
        minWidth: '280px',
        background: theme.palette.secondary.light,
        borderRadius: theme.spacing(1),
        padding: theme.spacing(2)
    },
    cardContent: {
        borderBottom: `${theme.spacing(1)} !important`
    },
    input: {
        paddingBottom: theme.spacing(2)
    },
    commitButton: {
        marginTop: theme.spacing(2),
        paddingTop: theme.spacing(1),
        fontWeight: 700,
        background: 'linear-gradient(40deg, rgba(46, 94, 170, 0.70) 0%, rgba(46, 94, 170, 1) 100%)'
    },
    logoGrid: {
        maxHeight: '7%',
        marginTop: '-6%',
        marginBottom: theme.spacing(2)
    },
    logoImage: {
        height: '70px'
    },
    dialogActions: {
        background: '#131520',
        color: '#ccccdc'
    },
    dialogTitle: {
        background: '#111217',
        color: '#ccccdc',
        textAlign: 'center'
    },
    requestNewPasswordRecoveryDialogCancelButton: {
        color: theme.palette.secondary.dark
    },
    imageSuccess: {
        fill: '#25D366',
        margin: 'auto',
        minWidth: '154px',
        minHeight: '154px'
    },
    imageWarning: {
        fill: '#ffa726',
        minWidth: '40px',
        minHeight: '40px'
    },
    message: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(4),
        fontSize: 21,
        fontWeight: 300,
        textAlign: 'center'
    },
    loadingContainer: {
        height: '250px'
    }
}))

export default useStyles
