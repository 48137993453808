import dayjs from 'dayjs'
import React, { createContext, useState } from 'react'

export type DateFilterProps = {
    from: string
    to: string
}

type DateFilterContextProps = {
    dateFilter: DateFilterProps,
    setDateFilter: (dateFilter: DateFilterProps) => void
}

export const DateFilterContext = createContext<DateFilterContextProps>({} as DateFilterContextProps)

export const DateFilterContextProvider: React.FC<any> = ({ children }: any) => {
    const urlParams = new URLSearchParams(window.location.search)
    const urlFrom = urlParams.get('from')
    const urlTo = urlParams.get('to')

    const dateFilterObj = {
        from: urlFrom ? dayjs(+urlFrom).toISOString() : dayjs().hour(0).minute(0).second(0).millisecond(0).toISOString(),
        to: !urlTo || urlTo === 'now' ? dayjs().toISOString() : dayjs(+urlTo).toISOString()
    }

    const [dateFilter, setDateFilter] = useState<DateFilterProps>(dateFilterObj)

    return (
        <DateFilterContext.Provider
            value={{
                dateFilter,
                setDateFilter
            }}
        >
            {children}
        </DateFilterContext.Provider>
    )
}
