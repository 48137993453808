/* eslint-disable indent */
import { WeekdayLiteralEnum } from '@/enum/WeekdayLiteralEnum'
import { WeekdayTypeEnum } from '@/enum/WeekdayTypeEnum'

export const getWeekdayListLiteral = (weekday: WeekdayTypeEnum) => {
    switch (weekday) {
        case WeekdayTypeEnum.DOMINGO:
            return WeekdayLiteralEnum.DOMINGO
        case WeekdayTypeEnum.SEGUNDA:
            return WeekdayLiteralEnum.SEGUNDA
        case WeekdayTypeEnum.TERCA:
            return WeekdayLiteralEnum.TERCA
        case WeekdayTypeEnum.QUARTA:
            return WeekdayLiteralEnum.QUARTA
        case WeekdayTypeEnum.QUINTA:
            return WeekdayLiteralEnum.QUINTA
        case WeekdayTypeEnum.SEXTA:
            return WeekdayLiteralEnum.SEXTA
        case WeekdayTypeEnum.SABADO:
            return WeekdayLiteralEnum.SABADO
    }
}
