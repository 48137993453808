import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
    container: {
        flexGrow: 1
    },
    fromInput: {
        width: 260,
        paddingRight: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    filterSelect: {
        width: 160,
        height: theme.spacing(5),
        marginRight: theme.spacing(3),
        marginBottom: theme.spacing(2)
    },
    toInput: {
        width: 260,
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        height: 45
    },
    filterButton: {
        height: 45,
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
    },
    rangeInputLabel: {
        color: theme.palette.info.main
    },
    menuItem: {
        color: theme.palette.secondary.dark
    }
}))

export default useStyles
