import React, { createContext, useEffect, useState } from 'react'

import ApiService from '@/services/Api'

export type WhatsappQRCodeProps = {
    connected: boolean
    qrcode: string,
}

type WhatsappQRCodeContextProps = {
    connected: boolean | undefined
    qrcode: string | undefined
    loading: boolean
}

export const WhatsappQRCodeContext = createContext<WhatsappQRCodeContextProps>({} as WhatsappQRCodeContextProps)

export const WhatsappQRCodeContextProvider: React.FC<any> = ({ children }: any) => {
    const [data, setData] = useState<WhatsappQRCodeProps>()
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        getQRCode()

        const interval = setInterval(getQRCode, 10000)

        return () => clearInterval(interval)
    }, [])

    const getQRCode = async () => {
        setLoading(true)

        const { data } = await ApiService.post<WhatsappQRCodeProps>('whatsapp|/generate/qrcode')

        setData({
            connected: data.connected,
            qrcode: data.qrcode
        })
        setLoading(false)
    }

    return (
        <WhatsappQRCodeContext.Provider
            value={{
                connected: data?.connected,
                qrcode: data?.qrcode,
                loading
            }}
        >
            {children}
        </WhatsappQRCodeContext.Provider>
    )
}
