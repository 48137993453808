import dayjs from 'dayjs'
import React, { useContext, useState } from 'react'

import Divider from '@/components/Divider'
import {
    TrendingDown as TrendingDownIcon,
    TrendingUp as TrendingUpIcon,
    Help as HelpMessageIcon,
    Close as CloseIcon,
    WhatsApp as WhatsAppIcon
} from '@mui/icons-material'
import {
    Skeleton,
    Grid,
    Typography,
    Chip,
    Card,
    CardContent,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton
} from '@mui/material'

import useStyles from './styles'
import { AuthContext } from '@/contexts/auth'

export type DataCardProps = {
    title: string
    value?: string | number
    increased?: boolean
    increasedValue?: string | number
    loading?: boolean
    streamResource?: boolean
    helperMessage?: string
    toComparison?: string
    fromComparison?: string
    typeComparison?: 'day' | 'week' | 'month' | 'year'
    whatsappCard?: boolean
    beta?: boolean
}

const TYPE_COMPARISON = {
    day: 'Hoje',
    week: 'Essa semana',
    month: 'Esse mês',
    year: 'Esse ano'
}

const DataCard: React.FC<DataCardProps> = (props: DataCardProps) => {
    const classes = useStyles()

    const { title, value, increased, increasedValue, loading, helperMessage, toComparison, fromComparison, typeComparison, whatsappCard, beta } = props

    const [openedHelperMessage, setOpenedHelperMessage] = useState<boolean>(false)

    const { darkMode } = useContext(AuthContext)

    return (
        <>
            <Card className={classes.cardContainer} >
                <CardContent className={classes.cardContent} >
                    <Grid container >
                        {
                            loading
                                ? (
                                    <>
                                        <Grid item xs={12} className={classes.valueGrid}>
                                            <Skeleton variant="rectangular" width="65%" height="70%" className={classes.valueText} />
                                        </Grid>

                                        <Grid item xs={6} className={classes.titleGrid} >
                                            <Skeleton variant="rectangular" width="70%" className={classes.titleText} />
                                        </Grid>

                                        <Grid item xs={6} className={classes.titleGrid} >
                                            <Skeleton variant="rectangular" width="40%" className={classes.titleText} />
                                        </Grid>
                                    </>
                                )
                                : (
                                    <>
                                        <Grid container justifyContent="space-around">
                                            <Grid item xs={12} className={classes.titleGrid} >
                                                <Typography className={classes.titleText}>
                                                    {title}
                                                    {beta && <Chip className={classes.betaChip} size='small' label="BETA" color="warning" />}
                                                    {whatsappCard && <WhatsAppIcon className={classes.titleWhatsappIcon} />}
                                                    {
                                                        !!increasedValue &&
                                                        <Chip
                                                            id="datacard-helper-message-chip"
                                                            style={{
                                                                background: increased ? '#73bf69' : '#f2495c'
                                                            }}
                                                            label={increasedValue}
                                                            className={classes.chipIncreasedValue}
                                                            icon={
                                                                increased
                                                                    ? <TrendingUpIcon className={classes.chipIconIncreasedValue} />
                                                                    : <TrendingDownIcon className={classes.chipIconIncreasedValue} />
                                                            }
                                                            size="small"
                                                            onClick={() => setOpenedHelperMessage(!openedHelperMessage)}
                                                        />
                                                    }
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={11} className={classes.valueGrid}>
                                            <Typography className={classes.valueText}>
                                                {value}
                                            </Typography>
                                        </Grid>
                                    </>
                                )
                        }
                    </Grid>
                </CardContent>

                {
                    (!!helperMessage && !loading) &&
                    <Chip
                        className={classes.helperMessageChip}
                        icon={<HelpMessageIcon />}
                        size="small"
                        id="datacard-open-helper-chip"
                        onClick={() => setOpenedHelperMessage(!openedHelperMessage)}
                    />
                }
            </Card>

            <Dialog
                onClose={() => setOpenedHelperMessage(false)}
                aria-labelledby="customized-dialog-title"
                open={openedHelperMessage}
            >
                <DialogTitle id="customized-dialog-title" className={classes.helperMessageDialog}>
                    {title}
                    <IconButton
                        id="datacard-helper-message-iconbutton"
                        aria-label="close"
                        onClick={() => setOpenedHelperMessage(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500]
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers className={classes.helperMessageDialog}>
                    <Typography gutterBottom>
                        {helperMessage}
                    </Typography>

                    {
                        !!increasedValue &&
                        <>
                            <Divider size={2} />
                            <Chip
                                style={{
                                    background: increased ? '#73bf69' : '#f2495c'
                                }}
                                label={increasedValue}
                                className={classes.chipIncreasedValueHelper}
                                icon={
                                    increased
                                        ? <TrendingUpIcon className={classes.chipIconIncreasedValue} />
                                        : <TrendingDownIcon className={classes.chipIconIncreasedValue} />
                                }
                                size="small"
                            />
                            <Typography gutterBottom>
                                {TYPE_COMPARISON[typeComparison || 'day']} você teve {increased ? 'um aumento' : 'uma queda'} de {increasedValue} comparado com o período de {dayjs(fromComparison).format('DD/MM/YYYY')} até {dayjs(toComparison).format('DD/MM/YYYY')}
                            </Typography>
                        </>
                    }
                </DialogContent>
            </Dialog>
        </>
    )
}

export default DataCard
