import { Chart, CategoryScale, BarElement, PointElement, LineElement, TimeSeriesScale, Legend, Tooltip, TooltipModel, TooltipItem } from 'chart.js'
import dayjs from 'dayjs'
import _ from 'lodash'
import React, { useContext, useState, useMemo } from 'react'
import 'chartjs-adapter-dayjs'
import { Bar } from 'react-chartjs-2'

import { DateFilterContext } from '@/contexts/dateFilter'
import ApiService from '@/services/Api'
import {
    Skeleton,
    Grid,
    Typography,
    Card,
    CardContent,
    Badge
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

import useStyles from './styles'

interface ISerie {
    timestamp: string
    quantity: number
}

type IWhatsappTimeseriesResponse = ISerie[]

Chart.register(CategoryScale, BarElement, PointElement, LineElement, TimeSeriesScale, Legend, Tooltip)

const WhatsappTimeseries: React.FC = () => {
    const classes = useStyles()

    const theme = useTheme()

    const { dateFilter } = useContext(DateFilterContext)

    const [chartData, setChartData] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(true)

    const getMessagesData = async () => {
        setLoading(true)

        const { data } = await ApiService.get<IWhatsappTimeseriesResponse>(
            '/metrics/whatsapp/messages/timeseries',
            {
                params: {
                    from: dateFilter.from,
                    to: dateFilter.to
                }
            }
        )

        const labels = data?.map(serie => dayjs(serie.timestamp).subtract(3, 'hour'))

        const datasets = [{
            tension: 0.2,
            fill: false,
            borderColor: '#73bf69',
            backgroundColor: '#73bf6980',
            borderWidth: 1,
            yAxisID: 'y',
            data: data?.map(serie => +serie.quantity)
        }]

        setChartData({
            labels,
            datasets
        })

        setLoading(false)
    }

    useMemo(() => {
        getMessagesData()
    }, [dateFilter])

    return (
        <Card className={classes.cardContainer}>
            <CardContent className={classes.cardContent}>
                <Grid container className={classes.audienceGrid}>
                    <Grid item xs={12} sm={12}>
                        <Grid container justifyContent="center">
                            <Typography className={classes.titleText}>
                                MENSAGENS DO WHATSAPP
                            </Typography>
                            <Badge badgeContent={'BETA'} color="warning" className={classes.betaBadge}></Badge>
                        </Grid>
                    </Grid>
                    {
                        loading
                            ? <Grid item xs={12} sm={12}>
                                {
                                    _.times(28, (i: any) => (
                                        <Skeleton
                                            key={i}
                                            variant="rectangular" width="2.5%" height={(i + 1) * 11}
                                            style={{
                                                float: 'left',
                                                marginLeft: '1%',
                                                marginTop: 360 - ((i + 1) * 11)
                                            }}
                                        />
                                    ))
                                }
                            </Grid>
                            : <Grid item xs={12} sm={12}>
                                {
                                    chartData &&
                                    chartData?.datasets &&
                                    <Bar
                                        height={80}
                                        data={chartData}
                                        options={{
                                            plugins: {
                                                legend: {
                                                    display: false
                                                },
                                                tooltip: {
                                                    backgroundColor: '#202227',
                                                    titleColor: '#ccccdc',
                                                    boxHeight: 1,
                                                    boxPadding: 3,
                                                    bodySpacing: 5,
                                                    callbacks: {
                                                        label: function (this: TooltipModel<'bar'>, tooltipItem: TooltipItem<'bar'>) {
                                                            return tooltipItem.formattedValue + ' mensagens'
                                                        },
                                                        title: function (this: TooltipModel<'bar'>, tooltipItem: TooltipItem<'bar'>[]) {
                                                            return dayjs(tooltipItem[0].label).format('DD/MM/YYYY HH:mm') + 'h'
                                                        }
                                                    }
                                                }
                                            },
                                            responsive: true,
                                            interaction: {
                                                mode: 'index',
                                                intersect: false
                                            },
                                            elements: {
                                                point: {
                                                    radius: 0
                                                }
                                            },
                                            scales: {
                                                x: {
                                                    type: 'time',
                                                    time: {
                                                        unit: 'minute',
                                                        displayFormats: {
                                                            minute: 'HH:mm'
                                                        }
                                                    },
                                                    grid: {
                                                        color: theme.palette.info[theme.palette.mode],
                                                        borderColor: theme.palette.info[theme.palette.mode]
                                                    },
                                                    ticks: {
                                                        color: theme.palette.primary[theme.palette.mode]
                                                    }
                                                },
                                                y: {
                                                    type: 'linear',
                                                    display: true,
                                                    position: 'left',
                                                    grid: {
                                                        color: theme.palette.info[theme.palette.mode],
                                                        borderColor: theme.palette.info[theme.palette.mode]
                                                    },
                                                    ticks: {
                                                        color: theme.palette.primary[theme.palette.mode]
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                }
                            </Grid>
                    }
                </Grid>
            </CardContent>
        </Card>
    )
}

export default WhatsappTimeseries
