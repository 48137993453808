import React, { useContext, useState, useEffect } from 'react'

import Map from '@/components/Map'
import { DateFilterContext } from '@/contexts/dateFilter'
import ApiService from '@/services/Api'
import {
    Grid,
    Typography,
    Card,
    CardContent
} from '@mui/material'

import useStyles from './styles'

// mock de dados
// import mockLocationHeatMap from './mock-location-heatmap.json'

type IListenersLocationResponse = {
    latitude: number
    longitude: number
    listeners: number | string
}[]

const LocationHeatMap: React.FC = () => {
    const classes = useStyles()
    const { dateFilter } = useContext(DateFilterContext)

    const [coordinates, setCoordinates] = useState<IListenersLocationResponse>()

    const getMapLocations = async () => {
        try {
            const { data } = await ApiService.get<IListenersLocationResponse>(
                `/metrics/listeners/location?from=${dateFilter.from}&to=${dateFilter.to}`
            )

            // mock de dados
            // const data = mockLocationHeatMap

            setCoordinates(data)
        } catch (error) {
            console.log(error)
            console.log('Erro ao tentar pegar a concorrencia, então ele retornou um array vazio.')

            return []
        }
    }

    useEffect(() => {
        getMapLocations()
    }, [dateFilter])

    return (
        <Card className={classes.cardContainer}>
            <CardContent className={classes.cardContent}>
                <Grid container className={classes.audienceGrid}>
                    <Grid item xs={12} sm={12}>
                        <Grid container justifyContent="center">
                            <Typography className={classes.titleText}>
                                OUVINTES POR CIDADE/BAIRRO
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} className={classes.mapsContainer}>
                        <Map coordinates={coordinates} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default LocationHeatMap
