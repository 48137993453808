import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
    cardContainer: {
        padding: 0,
        background: theme.palette.secondary[theme.palette.mode],
        border: '1px solid #ccccdc12',
        marginTop: 16,
        borderRadius: 5,
        height: '100%'
    },
    loginGrid: {
        marginTop: '30px',
        padding: '20px'
    },
    skeletonGrid: {
        float: 'left',
        marginLeft: '0.583%',
        marginRight: '0.583%',
        marginTop: 15
    },
    heatMapContainer: {
        padding: 30
    },
    titleText: {
        fontSize: 20,
        fontFamily: 'Roboto',
        textAlign: 'center',
        fontWeight: 600,
        marginRight: 10,
        color: theme.palette.primary[theme.palette.mode]
    },
    titleCard: {
        marginTop: 20
    }
}))

export default useStyles
