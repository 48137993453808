import React, { useContext } from 'react'
import { Route, Routes, useLocation, Navigate } from 'react-router-dom'

import { AuthContext } from '@/contexts/auth'
import { DateFilterContextProvider } from '@/contexts/dateFilter'
import Home from '@/pages/App/Home'
import Program from '@/pages/App/Program'
import Promotion from '@/pages/App/Promotion'
import Telegram from '@/pages/App/Telegram'
import Whatsapp from '@/pages/App/Whatsapp'
import Youtube from '@/pages/App/Youtube'

const PrivateRoutes = () => {
    const { logged, setDarkMode } = useContext(AuthContext)

    if (!logged) setDarkMode(false)

    if (!logged) return <Navigate to="/auth/login" replace />

    const location = useLocation()

    if (location.pathname === '/') return <Navigate to="/home" replace />

    return (
        <Routes>
            <Route path="/telegram" element={<Telegram />} />
            <Route path="/promotions" element={<Promotion />} />
            <Route path="/whatsapp" element={<Whatsapp />} />
            <Route path="/programs" element={<Program />} />
            <Route path="/youtube" element={<Youtube />} />

            <Route path="/home" element={
                <DateFilterContextProvider>
                    <Home />
                </DateFilterContextProvider>
            } />
        </Routes>
    )
}

export default PrivateRoutes
