import { SnackbarProvider } from 'notistack'
import React, { useContext } from 'react'
import { createRoot } from 'react-dom/client'

import App from '@/App'
import { AuthContext, AuthContextProvider } from '@/contexts/auth' // Certifique-se de importar o AuthContext aqui
import { Collapse } from '@mui/material'
import { createTheme, ThemeProvider, Theme, StyledEngineProvider, adaptV4Theme } from '@mui/material/styles'

import '@fontsource/roboto'

import '@/styles/global.css'
declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme { }
}

declare module '@mui/material/Select' {
    interface InputBasePropsColorOverrides {
        grafanaText: true;
    }
}

const RootComponent = () => {
    const { darkMode } = useContext(AuthContext)

    const currentPath = window.location.pathname
    const isAuthLogin = currentPath.includes('auth/login')

    const theme = createTheme(adaptV4Theme({
        palette: {
            mode: isAuthLogin ? 'light' : darkMode ? 'dark' : 'light',
            background: {
                default: darkMode ? '#111217' : '#FAFAFA'
            },
            primary: {
                light: '#0F172A',
                main: '#2E5EAA',
                dark: '#ccccdc',
                contrastText: '#FAFAFA'
            },
            secondary: {
                light: '#F2F2F2',
                main: '#FAFAFA',
                dark: '#181b1f',
                contrastText: '#303030'
            },
            error: {
                light: '#ff8279',
                main: '#ff4d4d',
                dark: '#c50024',
                contrastText: '#FAFAFA'
            },
            info: {
                light: '#ccccdc',
                main: '#ccccdc',
                dark: '#272a2e',
                contrastText: '#ccccdc'
            },
            success: {
                light: '#272a2e',
                main: '#ccccdc',
                dark: '#ccccdc',
                contrastText: '#ccccdc'
            }
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 685,
                md: 1015,
                lg: 1335,
                xl: 1536
            }
        }
    }))

    document.body.style.background = theme.palette.background.default

    return (
        <React.StrictMode>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <SnackbarProvider
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        TransitionComponent={Collapse}
                        preventDuplicate={true}
                    >
                        <App />
                    </SnackbarProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </React.StrictMode>
    )
}

const rootElement = document.getElementById('root')
const root = createRoot(rootElement!)

root.render(
    <AuthContextProvider> {/* Coloque o AuthContextProvider aqui */}
        <RootComponent />
    </AuthContextProvider>
)
