import dayjs from 'dayjs'
import { useSnackbar } from 'notistack'
import React, { useState, useContext, useEffect } from 'react'

import Divider from '@/components/Divider'
import { AuthContext } from '@/contexts/auth'
import { DateFilterContext, DateFilterProps } from '@/contexts/dateFilter'
import { isMobileScreen } from '@/utils/device'
// eslint-disable-next-line import-helpers/order-imports
import {
    Grid,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import useStyles from './styles'

import 'dayjs/locale/pt-br'

type FilterState = {
    value?: string;
    readyToRefresh: boolean;
};

const DateFilter: React.FC = () => {
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()

    const { darkMode } = useContext(AuthContext)

    const { dateFilter, setDateFilter } = useContext(DateFilterContext)

    const [localDateFilter, setLocalDateFilter] = useState<DateFilterProps>({
        from: dayjs(dateFilter.from)
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
            .toISOString(),
        to: dayjs(dateFilter.to).toISOString()
    } as DateFilterProps)

    const [filter, setFilter] = useState<FilterState>({
        value: undefined,
        readyToRefresh: true
    })

    const handleSaveFilterDate = async () => {
        const from = dayjs(localDateFilter.from)
        const to = dayjs(localDateFilter.to)

        if (!from.isValid() || !to.isValid()) {
            enqueueSnackbar('Datas inválidas.', { variant: 'error' })
            return
        }

        const diffDays = to.diff(from, 'day')

        if (diffDays > 31) {
            enqueueSnackbar('O período não pode ser maior que 31 dias.', {
                variant: 'error'
            })
            return
        }

        if (from.isAfter(to)) {
            enqueueSnackbar(
                'A data/hora inicial deve ser menor que a data/hora final.',
                { variant: 'error' }
            )
            return
        }

        const newUrl = new URL(document.location.href)
        newUrl.searchParams.set('from', from.valueOf().toString())
        newUrl.searchParams.set('to', to.valueOf().toString())
        window.history.replaceState({}, '', newUrl.toString())

        setDateFilter({
            from: from.toISOString(),
            to: to.toISOString()
        })
        await new Promise((resolve) => setTimeout(resolve, 5000))
    }

    const handleChangeFilterDate = async (targetValue: String) => {
        const from =
            targetValue === 'yesterday'
                ? dayjs().subtract(1, 'day').hour(0).minute(0).second(0).millisecond(0)
                : targetValue === 'day'
                    ? dayjs().hour(0).minute(0).second(0).millisecond(0)
                    : targetValue === 'week'
                        ? dayjs().day(0).hour(0).minute(0).second(0).millisecond(0)
                        : targetValue === 'month'
                            ? dayjs().date(1).hour(0).minute(0).second(0).millisecond(0)
                            : dayjs(localDateFilter.from)

        setLocalDateFilter({
            from: from.toISOString(),
            to: targetValue === 'yesterday' ? dayjs().subtract(1, 'day').set('hour', 23).set('minute', 59).set('second', 0).set('millisecond', 0).toISOString() : dayjs().toISOString()
        })
    }

    useEffect(() => {
        filter.readyToRefresh
            ? setFilter({ value: undefined, readyToRefresh: true })
            : setFilter({ ...filter, readyToRefresh: true })
    }, [localDateFilter])

    const handleDateChange = (value: any, isFrom: boolean): void => {
        setLocalDateFilter({
            ...localDateFilter,
            [isFrom ? 'from' : 'to']: dayjs(value).toISOString()
        })
    }

    return (
        <Grid
            container
            direction="column"
            alignItems={isMobileScreen ? 'flex-start' : 'flex-end'}
            className={classes.container}
        >
            <Grid item>
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="pt-br"
                >
                    <DateTimePicker
                        ampm={false}
                        label="Início (Data/Hora)"
                        className={classes.toInput}
                        maxDateTime={dayjs()}
                        onChange={(value) => handleDateChange(value, true)}
                        value={dayjs(localDateFilter.from)}
                        sx={{
                            '& ::-webkit-calendar-picker-indicator': {
                                filter: 'invert(1)'
                            },
                            '& .MuiInputLabel-root': {
                                color: `${
                                    darkMode ? '#ccccdc' : '#111217'
                                } !important`
                            },
                            '& .MuiOutlinedInput-root': {
                                color: `${
                                    darkMode ? '#ccccdc' : '#111217'
                                } !important`,
                                '& fieldset': {
                                    borderColor: `${
                                        darkMode ? '#ccccdc' : '#111217'
                                    } !important`
                                }
                            },
                            '& .MuiInputBase-root': {
                                height: 45
                            }
                        }}
                    />

                    {isMobileScreen && <Divider size={2} />}

                    <DateTimePicker
                        ampm={false}
                        label="Final (Data/Hora)"
                        value={dayjs(localDateFilter.to)}
                        maxDateTime={dayjs()}
                        onChange={(value) => handleDateChange(value, false)}
                        className={classes.toInput}
                        sx={{
                            '& ::-webkit-calendar-picker-indicator': {
                                filter: 'invert(1)'
                            },
                            '& .MuiInputLabel-root': {
                                color: `${
                                    darkMode ? '#ccccdc' : '#111217'
                                } !important`
                            },
                            '& .MuiOutlinedInput-root': {
                                color: `${
                                    darkMode ? '#ccccdc' : '#111217'
                                } !important`,
                                '& fieldset': {
                                    borderColor: `${
                                        darkMode ? '#ccccdc' : '#111217'
                                    } !important`
                                }
                            },
                            '& .MuiInputBase-root': {
                                height: 45
                            }
                        }}
                    />
                </LocalizationProvider>

                {isMobileScreen && <Divider size={2} />}

                <FormControl
                    variant="outlined"
                    color="info"
                    sx={{
                        '& .MuiInputLabel-root': {
                            color: `${
                                darkMode ? '#ccccdc' : '#111217'
                            } !important`
                        },
                        '& .MuiOutlinedInput-root': {
                            color: `${
                                darkMode ? '#ccccdc' : '#111217'
                            } !important`,
                            '& fieldset': {
                                borderColor: darkMode ? '#ccccdc' : '#111217'
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: `${
                                    darkMode ? '#ccccdc' : '#111217'
                                } !important`
                            }
                        },
                        '& .MuiSvgIcon-root': {
                            fill: `${
                                darkMode ? '#ccccdc' : '#111217'
                            } !important`
                        }
                    }}
                >
                    <InputLabel htmlFor="periodo" style={{ marginTop: -5 }}>
                        Período
                    </InputLabel>
                    <Select
                        onChange={({ target }) => {
                            setFilter({
                                value: String(target.value),
                                readyToRefresh: false
                            })
                            handleChangeFilterDate(String(target.value))
                        }}
                        variant="outlined"
                        color="info"
                        id="periodo"
                        label="Período"
                        className={classes.toInput}
                        value={filter.value}
                    >
                        <MenuItem value="yesterday">Ontem</MenuItem>
                        <MenuItem value="day">Hoje</MenuItem>
                        <MenuItem value="week">Semana</MenuItem>
                        <MenuItem value="month">Mês</MenuItem>
                    </Select>
                </FormControl>

                {isMobileScreen && <Divider size={2} />}

                <Button
                    id="datafilter-save-date-button"
                    color="primary"
                    className={classes.filterButton}
                    variant="contained"
                    onClick={() => handleSaveFilterDate()}
                >
                    Filtrar
                </Button>
            </Grid>
        </Grid>
    )
}

export default DateFilter
