import React, { useContext } from 'react'

import { AuthContext } from '@/contexts/auth'
import { Menu as MenuIcon } from '@mui/icons-material'
import { Grid, IconButton, Typography } from '@mui/material'

import StreamPlayer from '../Player'
import useStyles from './styles'

type NavbarProps = {
    onOpenMenu: () => void;
};

const Navbar: React.FC<NavbarProps> = ({ onOpenMenu }) => {
    const { company, user } = useContext(AuthContext)
    const classes = useStyles()

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className={classes.container}
        >
            <Grid item xs={3}>
                <IconButton
                    onClick={onOpenMenu}
                    id="navbar-open-menu-iconbutton"
                    size="large"
                >
                    <MenuIcon color="secondary" />
                </IconButton>
            </Grid>
            <Grid item xs={6}>
                <Typography className={classes.companyNameText}>
                    {company?.name} - {company?.address?.city}
                </Typography>
            </Grid>
            <Grid item xs={0} sm={1}></Grid>
            <Grid item xs={3} sm={2}>
                {company?.streamId && user?.isBetaTester && (
                    <StreamPlayer streamId={company.streamId} />
                )}
            </Grid>
        </Grid>
    )
}

export default Navbar
