import React, { useContext, useMemo, useState } from 'react'

import DataCard, { DataCardProps } from '@/components/DataCard'
import Divider from '@/components/Divider'
import { DateFilterContext } from '@/contexts/dateFilter'
import ApiService from '@/services/Api'
import { Grid } from '@mui/material'

import useStyles from './styles'

type CardOptions = {
    quantity: number
    differenceQuantity: number
    toComparison?: string
    fromComparison?: string
    typeComparison?: 'day' | 'week' | 'month' | 'year'
}

type Expression = 'neutral' | 'happy' | 'disgusted' | 'sad' | 'surprised' | 'angry'

type CardOptionsAverageExpression = {
    expression: Expression
}

const WhatsappCards: React.FC = () => {
    const classes = useStyles()

    const { dateFilter } = useContext(DateFilterContext)

    const [newWhatsappContacts, setNewWhatsappContacts] = useState<DataCardProps>({ loading: true } as DataCardProps)
    const [recurrentWhatsappContacts, setRecurrentWhatsappContacts] = useState<DataCardProps>({ loading: true } as DataCardProps)
    const [totalWhatsappContacts, setTotalWhatsappContacts] = useState<DataCardProps>({ loading: true } as DataCardProps)
    const [totalWhatsappMessages, setTotalWhatsappMessages] = useState<DataCardProps>({ loading: true } as DataCardProps)

    const [totalWhatsappGendersMale, setTotalWhatsappGendersMale] = useState<DataCardProps>({ loading: true } as DataCardProps)
    const [totalWhatsappGendersFemale, setTotalWhatsappGendersFemale] = useState<DataCardProps>({ loading: true } as DataCardProps)
    const [totalWhatsappAdults, setTotalWhatsappAdults] = useState<DataCardProps>({ loading: true } as DataCardProps)
    const [totalWhatsappYoungs, setTotalWhatsappYoungs] = useState<DataCardProps>({ loading: true } as DataCardProps)
    const [totalWhatsappFamilies, setTotalWhatsappFamilies] = useState<DataCardProps>({ loading: true } as DataCardProps)
    const [averageWhatsappExpression, setAverageWhatsappExpression] = useState<DataCardProps>({ loading: true } as DataCardProps)

    const getNewWhatsappContacts = async () => {
        const { data } = await ApiService.get<CardOptions>(
            `/metrics/whatsapp/contacts/new?from=${dateFilter.from}&to=${dateFilter.to}`
        )

        setNewWhatsappContacts({
            title: 'Novos contatos',
            value: `${data?.quantity} contato${data?.quantity === 1 ? '' : 's'}`,
            increasedValue: data?.differenceQuantity,
            increased: data?.differenceQuantity > 0,
            toComparison: data?.toComparison,
            fromComparison: data?.fromComparison,
            typeComparison: data?.typeComparison,
            loading: false
            // error: !data || data?.quantity === null || data?.quantity === undefined
        })
    }

    const getRecurrentWhatsappContacts = async () => {
        const { data } = await ApiService.get<CardOptions>(
            `/metrics/whatsapp/contacts/recurrent?from=${dateFilter.from}&to=${dateFilter.to}`
        )

        setRecurrentWhatsappContacts({
            title: 'Contatos recorrentes',
            value: data?.quantity === 1 ? `${data?.quantity} contato` : `${data?.quantity} contatos`,
            increasedValue: data?.differenceQuantity,
            increased: data?.differenceQuantity > 0,
            toComparison: data?.toComparison,
            fromComparison: data?.fromComparison,
            typeComparison: data?.typeComparison,
            loading: false
            // error: !data || data?.quantity === null || data?.quantity === undefined
        })
    }

    const getTotalWhatsappContacts = async () => {
        const { data } = await ApiService.get<CardOptions>(
            `/metrics/whatsapp/contacts/total?from=${dateFilter.from}&to=${dateFilter.to}`
        )

        setTotalWhatsappContacts({
            title: 'Total de contatos',
            value: data?.quantity === 1 ? `${data?.quantity} contato` : `${data?.quantity} contatos`,
            increasedValue: data?.differenceQuantity,
            increased: data?.differenceQuantity > 0,
            toComparison: data?.toComparison,
            fromComparison: data?.fromComparison,
            typeComparison: data?.typeComparison,
            loading: false
            // error: !data || data?.quantity === null || data?.quantity === undefined
        })
    }

    const getTotalWhatsappMessages = async () => {
        const { data } = await ApiService.get<CardOptions>(
            `/metrics/whatsapp/messages/total?from=${dateFilter.from}&to=${dateFilter.to}`
        )

        setTotalWhatsappMessages({
            title: 'Total de mensagens',
            value: data?.quantity === 1 ? `${data?.quantity} mensagem` : `${data?.quantity} mensagens`,
            increasedValue: data?.differenceQuantity,
            increased: data?.differenceQuantity > 0,
            toComparison: data?.toComparison,
            fromComparison: data?.fromComparison,
            typeComparison: data?.typeComparison,
            loading: false
            // error: !data || data?.quantity === null || data?.quantity === undefined
        })
    }

    const getTotalWhatsappGenders = async (gender: string) => {
        const { data } = await ApiService.get<CardOptions>(
            `/metrics/whatsapp/persons/gender/${gender}?from=${dateFilter.from}&to=${dateFilter.to}`
        )

        const titleGenderPlural = gender === 'male' ? 'homens' : 'mulheres'
        const titleGenderSingular = gender === 'male' ? 'homem' : 'mulher'

        const options = {
            title: `Total ${titleGenderPlural}`,
            value: +data?.quantity === 1 ? `${data?.quantity} ${titleGenderSingular}` : `${data?.quantity} ${titleGenderPlural}`,
            increasedValue: data?.differenceQuantity,
            increased: data?.differenceQuantity > 0,
            toComparison: data?.toComparison,
            fromComparison: data?.fromComparison,
            typeComparison: data?.typeComparison,
            loading: false
            // error: !data || data?.quantity === null || data?.quantity === undefined
        }

        gender === 'male' ? setTotalWhatsappGendersMale(options) : setTotalWhatsappGendersFemale(options)
    }

    const getTotalWhatsappAdults = async () => {
        const { data } = await ApiService.get<CardOptions>(
            `/metrics/whatsapp/persons/adults?from=${dateFilter.from}&to=${dateFilter.to}`
        )

        setTotalWhatsappAdults({
            title: 'Total adultos',
            value: +data?.quantity === 1 ? `${data?.quantity} adulto` : `${data?.quantity} adultos`,
            increasedValue: data?.differenceQuantity,
            increased: data?.differenceQuantity > 0,
            toComparison: data?.toComparison,
            fromComparison: data?.fromComparison,
            typeComparison: data?.typeComparison,
            loading: false
            // error: !data || data?.quantity === null || data?.quantity === undefined
        })
    }

    const getTotalWhatsappYoungs = async () => {
        const { data } = await ApiService.get<CardOptions>(
            `/metrics/whatsapp/persons/youngs?from=${dateFilter.from}&to=${dateFilter.to}`
        )

        setTotalWhatsappYoungs({
            title: 'Total jovens',
            value: +data?.quantity === 1 ? `${data?.quantity} jovem` : `${data?.quantity} jovens`,
            increasedValue: data?.differenceQuantity,
            increased: data?.differenceQuantity > 0,
            toComparison: data?.toComparison,
            fromComparison: data?.fromComparison,
            typeComparison: data?.typeComparison,
            loading: false
            // error: !data || data?.quantity === null || data?.quantity === undefined
        })
    }

    const getTotalWhatsappFamilies = async () => {
        const { data } = await ApiService.get<CardOptions>(
            `/metrics/whatsapp/persons/families?from=${dateFilter.from}&to=${dateFilter.to}`
        )

        setTotalWhatsappFamilies({
            title: 'Total famílias',
            value: +data?.quantity === 1 ? `${data?.quantity} família` : `${data?.quantity} famílias`,
            increasedValue: data?.differenceQuantity,
            increased: data?.differenceQuantity > 0,
            toComparison: data?.toComparison,
            fromComparison: data?.fromComparison,
            typeComparison: data?.typeComparison,
            loading: false
            // error: !data || data?.quantity === null || data?.quantity === undefined
        })
    }

    const getAverageWhatsappExpression = async () => {
        const { data } = await ApiService.get<CardOptionsAverageExpression>(
            `/metrics/whatsapp/persons/average/expression?from=${dateFilter.from}&to=${dateFilter.to}`
        )

        const expressions = {
            neutral: 'Neutro',
            happy: 'Feliz',
            disgusted: 'Enojado',
            sad: 'Triste',
            surprised: 'Surpreso',
            angry: 'Irritado'
        }

        setAverageWhatsappExpression({
            title: 'Maioria das expressões',
            value: expressions[data.expression],
            loading: false
            // error: !data || data?.quantity === null || data?.quantity === undefined
        })
    }

    const getCardsData = async () => {
        setNewWhatsappContacts({ ...newWhatsappContacts, loading: true })
        setRecurrentWhatsappContacts({ ...recurrentWhatsappContacts, loading: true })
        setTotalWhatsappContacts({ ...totalWhatsappContacts, loading: true })
        setTotalWhatsappMessages({ ...totalWhatsappMessages, loading: true })
        setTotalWhatsappGendersMale({ ...totalWhatsappGendersMale, loading: true })
        setTotalWhatsappGendersFemale({ ...totalWhatsappGendersFemale, loading: true })
        setTotalWhatsappAdults({ ...totalWhatsappAdults, loading: true })
        setTotalWhatsappYoungs({ ...totalWhatsappYoungs, loading: true })
        setTotalWhatsappFamilies({ ...totalWhatsappFamilies, loading: true })
        setAverageWhatsappExpression({ ...averageWhatsappExpression, loading: true })

        getNewWhatsappContacts()
        getRecurrentWhatsappContacts()
        getTotalWhatsappContacts()
        getTotalWhatsappMessages()

        getTotalWhatsappGenders('male')
        getTotalWhatsappGenders('female')
        getTotalWhatsappAdults()
        getTotalWhatsappYoungs()
        getTotalWhatsappFamilies()
        getAverageWhatsappExpression()
    }

    useMemo(() => {
        getCardsData()
    }, [dateFilter])

    return (
        <>
            <Grid container spacing={2} className={classes.cardsGrid}>
                <Divider size={3} />
                <Grid item xs={12} sm={6} md={4} lg={3} className={classes.cardGrid}>
                    <DataCard
                        title={totalWhatsappMessages.title}
                        value={totalWhatsappMessages.value}
                        increased={totalWhatsappMessages.increased}
                        increasedValue={totalWhatsappMessages.increasedValue}
                        loading={totalWhatsappMessages.loading}
                        toComparison={totalWhatsappMessages.toComparison}
                        fromComparison={totalWhatsappMessages.fromComparison}
                        typeComparison={totalWhatsappMessages.typeComparison}
                        helperMessage={`
                            O total de mensagens recebidas.
                            Essa informação deve ser interpretada indivualmente, não se deve somar/subtrair com outras informações.
                        `}
                        whatsappCard={true}
                        beta={true}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} className={classes.cardGrid}>
                    <DataCard
                        title={totalWhatsappContacts.title}
                        value={totalWhatsappContacts.value}
                        increased={totalWhatsappContacts.increased}
                        increasedValue={totalWhatsappContacts.increasedValue}
                        loading={totalWhatsappContacts.loading}
                        toComparison={totalWhatsappContacts.toComparison}
                        fromComparison={totalWhatsappContacts.fromComparison}
                        typeComparison={totalWhatsappContacts.typeComparison}
                        helperMessage={`
                            O total de contatos que enviaram mensagem.
                            Essa informação deve ser interpretada indivualmente, não se deve somar/subtrair com outras informações.
                        `}
                        whatsappCard={true}
                        beta={true}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3} className={classes.cardGrid}>
                    <DataCard
                        title={newWhatsappContacts.title}
                        value={newWhatsappContacts.value}
                        increased={newWhatsappContacts.increased}
                        increasedValue={newWhatsappContacts.increasedValue}
                        loading={newWhatsappContacts.loading}
                        toComparison={newWhatsappContacts.toComparison}
                        fromComparison={newWhatsappContacts.fromComparison}
                        typeComparison={newWhatsappContacts.typeComparison}
                        helperMessage={`
                            Contatos que enviaram mensagem pela primeira vez.
                            Essa informação deve ser interpretada indivualmente, não se deve somar/subtrair com outras informações.
                        `}
                        whatsappCard={true}
                        beta={true}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3} className={classes.cardGrid}>
                    <DataCard
                        title={recurrentWhatsappContacts.title}
                        value={recurrentWhatsappContacts.value}
                        increased={recurrentWhatsappContacts.increased}
                        increasedValue={recurrentWhatsappContacts.increasedValue}
                        loading={recurrentWhatsappContacts.loading}
                        toComparison={recurrentWhatsappContacts.toComparison}
                        fromComparison={recurrentWhatsappContacts.fromComparison}
                        typeComparison={recurrentWhatsappContacts.typeComparison}
                        helperMessage={`
                            Contatos que já enviaram mensagem antes.
                            Essa informação deve ser interpretada indivualmente, não se deve somar/subtrair com outras informações.
                        `}
                        whatsappCard={true}
                        beta={true}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3} className={classes.cardGrid}>
                    <DataCard
                        title={totalWhatsappGendersMale.title}
                        value={totalWhatsappGendersMale.value}
                        increased={totalWhatsappGendersMale.increased}
                        increasedValue={totalWhatsappGendersMale.increasedValue}
                        loading={totalWhatsappGendersMale.loading}
                        toComparison={totalWhatsappGendersMale.toComparison}
                        fromComparison={totalWhatsappGendersMale.fromComparison}
                        typeComparison={totalWhatsappGendersMale.typeComparison}
                        helperMessage={`
                            Total de homens que estavam presentes na foto de perfil do Whatsapp das mensagens recebidas.
                            Essa informação deve ser interpretada indivualmente, não se deve somar/subtrair com outras informações.
                        `}
                        whatsappCard={true}
                        beta={true}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3} className={classes.cardGrid}>
                    <DataCard
                        title={totalWhatsappGendersFemale.title}
                        value={totalWhatsappGendersFemale.value}
                        increased={totalWhatsappGendersFemale.increased}
                        increasedValue={totalWhatsappGendersFemale.increasedValue}
                        loading={totalWhatsappGendersFemale.loading}
                        toComparison={totalWhatsappGendersFemale.toComparison}
                        fromComparison={totalWhatsappGendersFemale.fromComparison}
                        typeComparison={totalWhatsappGendersFemale.typeComparison}
                        helperMessage={`
                            Total de mulheres que estavam presentes na foto de perfil do Whatsapp das mensagens recebidas.
                            Essa informação deve ser interpretada indivualmente, não se deve somar/subtrair com outras informações.
                        `}
                        whatsappCard={true}
                        beta={true}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3} className={classes.cardGrid}>
                    <DataCard
                        title={totalWhatsappAdults.title}
                        value={totalWhatsappAdults.value}
                        increased={totalWhatsappAdults.increased}
                        increasedValue={totalWhatsappAdults.increasedValue}
                        loading={totalWhatsappAdults.loading}
                        toComparison={totalWhatsappAdults.toComparison}
                        fromComparison={totalWhatsappAdults.fromComparison}
                        typeComparison={totalWhatsappAdults.typeComparison}
                        helperMessage={`
                            Total de adultos que estavam presentes na foto de perfil do Whatsapp das mensagens recebidas.
                            Essa informação deve ser interpretada indivualmente, não se deve somar/subtrair com outras informações.
                        `}
                        whatsappCard={true}
                        beta={true}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3} className={classes.cardGrid}>
                    <DataCard
                        title={totalWhatsappYoungs.title}
                        value={totalWhatsappYoungs.value}
                        increased={totalWhatsappYoungs.increased}
                        increasedValue={totalWhatsappYoungs.increasedValue}
                        loading={totalWhatsappYoungs.loading}
                        toComparison={totalWhatsappYoungs.toComparison}
                        fromComparison={totalWhatsappYoungs.fromComparison}
                        typeComparison={totalWhatsappYoungs.typeComparison}
                        helperMessage={`
                            Total de jovens que estavam presentes na foto de perfil do Whatsapp das mensagens recebidas.
                            Essa informação deve ser interpretada indivualmente, não se deve somar/subtrair com outras informações.
                        `}
                        whatsappCard={true}
                        beta={true}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3} className={classes.cardGrid}>
                    <DataCard
                        title={totalWhatsappFamilies.title}
                        value={totalWhatsappFamilies.value}
                        increased={totalWhatsappFamilies.increased}
                        increasedValue={totalWhatsappFamilies.increasedValue}
                        loading={totalWhatsappFamilies.loading}
                        toComparison={totalWhatsappFamilies.toComparison}
                        fromComparison={totalWhatsappFamilies.fromComparison}
                        typeComparison={totalWhatsappFamilies.typeComparison}
                        helperMessage={`
                            Total de familias (dois ou mais membros) que estavam presentes na foto de perfil do Whatsapp das mensagens recebidas.
                            Essa informação deve ser interpretada indivualmente, não se deve somar/subtrair com outras informações.
                        `}
                        whatsappCard={true}
                        beta={true}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3} className={classes.cardGrid}>
                    <DataCard
                        title={averageWhatsappExpression.title}
                        value={averageWhatsappExpression.value}
                        loading={averageWhatsappExpression.loading}
                        helperMessage={`
                            A maioria das expressões no rosto das pessoas que estavam presentes na foto de perfil do Whatsapp das mensagens recebidas.
                            Opções disponiveis: Neutro, Feliz, Enojado, Triste, Surpreso e Irritado
                        `}
                        whatsappCard={true}
                        beta={true}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default WhatsappCards
