import React, { createContext, useEffect, useState } from 'react'

import ApiService from '@/services/Api'

export type CurrentAudienceProps = {
    sessions: number
    sessionsDate: Date
    externalSessions: number
    externalSessionsDate: Date
}

type CurrentAudienceContextProps = {
    sessions: number | undefined,
    sessionsDate: Date | undefined
    externalSessions: number | undefined,
    externalSessionsDate: Date | undefined
}

interface IAudience {
    sessions: number
    sessionsDate: Date
    externalSessions: number
    externalSessionsDate: Date
}

export const CurrentAudienceContext = createContext<CurrentAudienceContextProps>({} as CurrentAudienceContextProps)

export const CurrentAudienceContextProvider: React.FC<any> = ({ children }: any) => {
    const [audience, setAudience] = useState<CurrentAudienceProps>()

    useEffect(() => {
        getLastAudience()

        const interval = setInterval(getLastAudience, 60000)

        return () => clearInterval(interval)
    }, [])

    const getLastAudience = async () => {
        const { data } = await ApiService.get<{ audience: IAudience }>('/metrics/audience/realtime')

        setAudience({
            sessions: data?.audience.sessions,
            sessionsDate: data?.audience.sessionsDate,
            externalSessions: data?.audience.externalSessions,
            externalSessionsDate: data?.audience.externalSessionsDate
        })
    }

    return (
        <CurrentAudienceContext.Provider
            value={{
                sessions: audience?.sessions,
                sessionsDate: audience?.sessionsDate,
                externalSessions: audience?.externalSessions,
                externalSessionsDate: audience?.externalSessionsDate
            }}
        >
            {children}
        </CurrentAudienceContext.Provider>
    )
}
