export const utcConvert = (time: string, gmt: number) => {
    const [hour, minutes] = time.split(':')

    const hourWithGmt = (parseInt(hour) + gmt)

    const convertedHour = hourWithGmt >= 24
        ? hourWithGmt - 24
        : hourWithGmt < 0
            ? hourWithGmt + 24
            : hourWithGmt

    return `${convertedHour < 10 ? '0' : ''}${convertedHour}:${minutes}`
}
