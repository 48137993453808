import {
    Chart,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    TimeSeriesScale,
    Legend,
    Tooltip,
    Filler,
    TooltipModel,
    TooltipItem,
} from "chart.js";

import Annotation from "chartjs-plugin-annotation";
import _ from "lodash";
import React, { useContext, useState, useMemo } from "react";
import "chartjs-adapter-dayjs";
import { Line } from "react-chartjs-2";

import { AuthContext } from "@/contexts/auth";
import { DateFilterContext } from "@/contexts/dateFilter";
import ApiService from "@/services/Api";
import {
    Skeleton,
    Grid,
    Typography,
    Card,
    CardContent,
    Select,
    MenuItem,
} from "@mui/material";
import dayjs from "dayjs";
import { useTheme } from "@mui/material/styles";

import useStyles from "./styles";

interface IAudience {
    timestamp: string;
    sessions: number;
}

type IAudienceResponse = IAudience[];

interface ILoadingAudience {
    gridLoading: boolean;
    audienceLoading: boolean;
}

Chart.register(
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    TimeSeriesScale,
    Legend,
    Tooltip,
    Annotation,
    Filler
);

const Lives: React.FC = () => {
    const classes = useStyles();
    const theme = useTheme();

    const [livestreamings, setLivestreamings] = useState<any>({});
    const [liveStreamingsViewers, setLiveStreamingsViewers] = useState<any>({});
    const [chartData, setChartData] = useState<any>([]);

    const { dateFilter } = useContext(DateFilterContext);
    const { darkMode } = useContext(AuthContext);

    const [loading, setLoading] = useState<ILoadingAudience>({
        gridLoading: false,
        audienceLoading: true,
    });

    const getLiveStreamings = async () => {
        setLoading({ ...loading, audienceLoading: true });

        const { data } = await ApiService.get<IAudienceResponse>(
            `/metrics/livestreamings`,
            {}
        );
        setLivestreamings(data);
        setLoading({ ...loading, audienceLoading: false });
    };

    const getLiveStreamingsViewers = async () => {
        setLoading({ ...loading, audienceLoading: true });

        const { data } = await ApiService.get<any>(
            `/metrics/livestreamings/viewers/${"59838473-6476-476b-89d9-8331c1606cac"}`,
            {
                params: {
                    from: "2024-10-24T03:00:00.000Z",
                    to: "2024-10-24T15:31:08.027Z",
                },
            }
        );
        const labels = data?.liveStreamingsViewers.map(
            (item: {
                createdAt:
                    | string
                    | number
                    | Date
                    | dayjs.Dayjs
                    | null
                    | undefined;
            }) => dayjs(item.createdAt).subtract(3, "hour")
        );

        const datasets = [
            {
                tension: 0.2,
                fill: true,
                borderColor: "#73bf69",
                backgroundColor: darkMode ? "#73bf6980" : "#299a1a80",
                borderWidth: 1,
                yAxisID: "y",
                data: data?.liveStreamingsViewers.map(
                    (item: { concurrentViewers: string | number }) =>
                        +item.concurrentViewers
                ),
            },
        ];

        setChartData({
            labels,
            datasets,
        });

        setLiveStreamingsViewers(data.liveStreamingsViewers);
        setLoading({ ...loading, audienceLoading: false });
    };

    useMemo(() => {
        getLiveStreamings();
        getLiveStreamingsViewers();
    }, [dateFilter, darkMode]);

    return (
        <Card className={classes.cardContainer}>
            <CardContent className={classes.cardContent}>
                <Grid container className={classes.audienceGrid}>
                    <Grid item xs={12} sm={12}>
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="center"
                            flexDirection="column"
                        >
                            <Typography className={classes.titleText}>
                                🔴 VTV SBT - Ao vivo 24h
                            </Typography>
                        </Grid>
                    </Grid>

                    {loading.audienceLoading || loading.gridLoading ? (
                        <Grid item xs={12} sm={12}>
                            {_.times(28, (i: any) => (
                                <Skeleton
                                    key={i}
                                    variant="rectangular"
                                    width="2.5%"
                                    height={(i + 1) * 11}
                                    style={{
                                        float: "left",
                                        marginLeft: "1%",
                                        marginTop: 360 - (i + 1) * 11,
                                    }}
                                />
                            ))}
                        </Grid>
                    ) : (
                        <>
                            <Grid item xs={12} sm={12}>
                                <Line
                                    height={80}
                                    data={chartData}
                                    options={{
                                        plugins: {
                                            legend: {
                                                display: false,
                                            },
                                            tooltip: {
                                                backgroundColor: "#202227",
                                                titleColor: "#ccccdc",
                                                boxHeight: 1,
                                                boxPadding: 3,
                                                bodySpacing: 5,
                                                callbacks: {
                                                    label: function (
                                                        this: TooltipModel<"line">,
                                                        tooltipItem: TooltipItem<"line">
                                                    ) {
                                                        return (
                                                            tooltipItem.formattedValue +
                                                            " ouvintes"
                                                        );
                                                    },
                                                    title: function (
                                                        this: TooltipModel<"line">,
                                                        tooltipItem: TooltipItem<"line">[]
                                                    ) {
                                                        return (
                                                            dayjs(
                                                                tooltipItem[0]
                                                                    .label
                                                            ).format(
                                                                "DD/MM/YYYY HH:mm"
                                                            ) + "h"
                                                        );
                                                    },
                                                },
                                            },
                                        },
                                        responsive: true,
                                        interaction: {
                                            mode: "index",
                                            intersect: false,
                                        },
                                        elements: {
                                            point: {
                                                radius: 0,
                                            },
                                        },
                                        scales: {
                                            x: {
                                                type: "time",
                                                time: {
                                                    unit: "minute",
                                                    displayFormats: {
                                                        minute: "HH:mm",
                                                    },
                                                },
                                                grid: {
                                                    color: theme.palette.info[
                                                        theme.palette.mode
                                                    ],
                                                    borderColor:
                                                        theme.palette.info[
                                                            theme.palette.mode
                                                        ],
                                                },
                                                ticks: {
                                                    color: theme.palette
                                                        .primary[
                                                        theme.palette.mode
                                                    ],
                                                },
                                            },
                                            y: {
                                                type: "linear",
                                                display: true,
                                                position: "left",
                                                grid: {
                                                    color: darkMode
                                                        ? theme.palette.info[
                                                              theme.palette.mode
                                                          ]
                                                        : "#ccccdc",
                                                    borderColor: darkMode
                                                        ? theme.palette.info[
                                                              theme.palette.mode
                                                          ]
                                                        : "#ccccdc",
                                                },
                                                ticks: {
                                                    color: theme.palette
                                                        .primary[
                                                        theme.palette.mode
                                                    ],
                                                },
                                                min: 0,
                                            },
                                        },
                                    }}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default Lives;
