/* eslint-disable no-unmodified-loop-condition */
/* eslint-disable array-callback-return */
/* eslint-disable react/react-in-jsx-scope */
import L from 'leaflet'
import { useContext, useEffect } from 'react'
import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import 'react-leaflet-markercluster'

import 'leaflet/dist/leaflet.css'
import 'react-leaflet-markercluster/dist/styles.min.css'
import { AuthContext } from '@/contexts/auth'

type IListenersLocationResponse = {
    latitude: number
    longitude: number
    listeners: number | string
}[]

interface MapProps {
    coordinates: IListenersLocationResponse | undefined
    company?: any
}

function MapUpdater({ company, coordinates }: MapProps) {
    const map = useMap()
    const _window: any = window

    async function getCoordinates(city?: string, state?: string, country?: string) {
        if (city) {
            try {
                const geocoder = await new _window.google.maps.Geocoder()
                const geocoderCities = await geocoder.geocode({ address: `${city}, ${state}, ${country}` })

                if (geocoderCities?.results?.length) return { ...geocoderCities.results[0].geometry.location, zoom: 10 }
            } catch (e) { }
        }

        return {
            lat: () => -20.58087,
            lng: () => -57.977812,
            zoom: 4
        }
    }

    async function initialize(map: any) {
        const address = company?.address

        const { lat, lng, zoom } = (address?.longitude && address?.latitude)
            ? { lat: () => address?.latitude, lng: () => address?.longitude, zoom: 10 }
            : await getCoordinates(address?.city, address?.state, address?.country)

        map.setView([lat(), lng()], zoom)

        const cluster = new L.MarkerClusterGroup({
            disableClusteringAtZoom: 20,
            animateAddingMarkers: true,
            spiderfyOnMaxZoom: false,
            singleMarkerMode: true,
            polygonOptions: {
                opacity: 0,
                fillOpacity: 0
            }
        })

        coordinates?.map(coord => {
            const listeners = Number(coord.listeners)

            for (let m = 0; m < listeners; m++) {
                L.marker(new L.LatLng(coord.latitude, coord.longitude), { icon: undefined }).addTo(cluster)
            }
        })

        map.addLayer(cluster)
    }

    useEffect(() => {
        if (map) {
            initialize(map)
        }
    }, [map, company, coordinates])

    return null
}

export default function Map({ coordinates }: MapProps) {
    const { company, darkMode } = useContext(AuthContext)

    return (
        <MapContainer
            id='map'
            style={{ width: '100%', height: '100%', backgroundColor: darkMode ? '#181B1F' : '#F2F2F2' }}
            // @ts-ignore
            center={[-20.58087, -57.977812]}
            zoom={4}
            scrollWheelZoom={true}
            attributionControl={false}
            minZoom={2}
        >
            {darkMode
                ? <TileLayer
                    url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
                    // @ts-ignore
                    subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                />
                : <TileLayer
                    url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
                />
            }

            <MapUpdater company={company} coordinates={coordinates} />
        </MapContainer>
    )
}
