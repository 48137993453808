import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import loopertLogo from '@/assets/illustrations/LogoHighLight.png'
import useDidMount from '@/hooks/useMount'
import ApiService from '@/services/Api'
import { CheckCircle as CheckCircleIcon, Warning as WarningIcon } from '@mui/icons-material'
import {
    Grid,
    Button,
    TextField,
    CircularProgress,
    Card,
    CardContent,
    FormControlLabel,
    Checkbox,
    DialogActions,
    Dialog,
    DialogTitle,
    SvgIcon,
    Typography
} from '@mui/material'

import useStyles from './styles'

const RecoveryPassword: React.FC = () => {
    const classes = useStyles()
    const params = useParams()
    const navigate = useNavigate()
    const { token } = params

    const { enqueueSnackbar } = useSnackbar()

    const [password, setPassword] = useState<String>('')
    const [passwordConfirmation, setPasswordConfirmation] = useState<String>('')

    const [loading, setLoading] = useState<boolean>(true)
    const [loadingPasswordRecovery, setLoadingPasswordRecovery] = useState<boolean>(false)
    const [loadingNewPasswordRecovery, setLoadingNewPasswordRecovery] = useState<boolean>(false)
    const [passwordRecoveryFailed, setPasswordRecoveryFailed] = useState<boolean>(false)
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [successfulRecovery, setSuccessfulRecovery] = useState<boolean>(false)

    const handleSendNewPassword = async () => {
        try {
            setLoadingPasswordRecovery(true)

            await ApiService.put(
                `recovery-password/${token}`,
                { newPassword: password }
            )

            setSuccessfulRecovery(true)

            enqueueSnackbar(
                'Senha definida com sucesso',
                { variant: 'success' }
            )
        } catch (error: any) {
            if (error.status === 401) {
                setPasswordRecoveryFailed(true)

                return setLoadingPasswordRecovery(false)
            }

            enqueueSnackbar(
                error?.data || 'Ocorreu um erro na atualização da senha.',
                { variant: 'error' }
            )
        }

        setLoadingPasswordRecovery(false)
    }

    const requestNewPasswordRecovery = async () => {
        try {
            setLoadingNewPasswordRecovery(true)

            await ApiService.get(`recovery-password/token/${token}`)

            enqueueSnackbar(
                'O link para recuperação da senha foi enviado com sucesso para o seu email. Retornando a página inicial em 5 segundos.',
                { variant: 'success' }
            )
        } catch (error: any) {
            enqueueSnackbar(
                error?.data || 'Ocorreu um erro ao requisitar a troca de senha. Retornando a página inicial em 5 segundos.',
                { variant: 'error' }
            )
        }

        setLoadingNewPasswordRecovery(false)

        await setTimeout(() => navigate('/'), 5000)
    }

    const handleValidateToken = async () => {
        try {
            await ApiService.get(`recovery-password/validate-token/${token}`)
        } catch (error: any) {
            setPasswordRecoveryFailed(true)
        }

        setLoading(false)
    }

    useDidMount(() => {
        handleValidateToken()
    })

    return (
        <Grid container className={classes.container} alignContent="center" justifyContent="center">
            <Grid container alignContent="center" justifyContent="center" className={classes.logoGrid}>
                <img src={loopertLogo} className={classes.logoImage} alt="Loopert" />
            </Grid>
            <Card className={classes.cardContainer}>
                {
                    (loading || loadingPasswordRecovery) &&
                    <>
                        <CardContent className={classes.cardContent}>
                            <Grid className={classes.loadingContainer} container alignContent="center" justifyContent="center">
                                <CircularProgress size={80} color="primary" />
                            </Grid>
                        </CardContent>
                    </>
                }
                {
                    passwordRecoveryFailed &&
                    <>
                        <CardContent className={classes.cardContent}>
                            <Grid container alignContent="center" justifyContent="center">
                                <SvgIcon component={WarningIcon} className={classes.imageWarning}></SvgIcon>
                                <Typography className={classes.message}>
                                    O link para redefinição de senha expirou. Por favor, solicite um novo link.
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Button
                                    fullWidth
                                    id="login-signin-button"
                                    className={classes.commitButton}
                                    color="primary"
                                    variant="contained"
                                    disabled={loadingNewPasswordRecovery}
                                    onClick={() => requestNewPasswordRecovery()}
                                    endIcon={loadingNewPasswordRecovery && (
                                        <CircularProgress size={20} color="inherit" />
                                    )}
                                >
                                    Solicitar novo link
                                </Button>
                            </Grid>
                        </CardContent>
                    </>
                }
                {
                    !passwordRecoveryFailed && !loadingPasswordRecovery && !loading && successfulRecovery &&
                    <>
                        <CardContent className={classes.cardContent}>
                            <Grid container alignContent="center" justifyContent="center">
                                <SvgIcon component={CheckCircleIcon} className={classes.imageSuccess}></SvgIcon>
                            </Grid>

                            <Grid item>
                                <Button
                                    fullWidth
                                    id="login-signin-button"
                                    type="submit"
                                    className={classes.commitButton}
                                    color="primary"
                                    variant="contained"
                                    onClick={() => navigate('/')}
                                >
                                    Ir para a página de login
                                </Button>
                            </Grid>
                        </CardContent>
                    </>
                }
                {
                    !passwordRecoveryFailed && !loadingPasswordRecovery && !loading && !successfulRecovery &&
                    <>
                        <CardContent className={classes.cardContent}>
                            <Grid item className={classes.input}>
                                <TextField
                                    label="Senha"
                                    variant="outlined"
                                    id="login-set-password-textfield"
                                    size="small"
                                    fullWidth
                                    type={showPassword ? 'text' : 'password'}
                                    onChange={
                                        ({ target }) => setPassword(target.value)
                                    }
                                />
                            </Grid>

                            <Grid item className={classes.input}>
                                <TextField
                                    label="Confirmação da senha"
                                    variant="outlined"
                                    id="login-set-password-confirmation-textfield"
                                    size="small"
                                    fullWidth
                                    type={showPassword ? 'text' : 'password'}
                                    onChange={
                                        ({ target }) => setPasswordConfirmation(target.value)
                                    }
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="login-show-password-confirmation-checkbox"
                                            checked={showPassword}
                                            onChange={() => setShowPassword(!showPassword)}
                                            color="primary"
                                        />
                                    }
                                    label="Mostrar senha"
                                />
                            </Grid>

                            <Grid item>
                                <Button
                                    fullWidth
                                    id="login-signin-button"
                                    type="submit"
                                    className={classes.commitButton}
                                    color="primary"
                                    variant="contained"
                                    onClick={handleSendNewPassword}
                                    disabled={
                                        loadingPasswordRecovery ||
                                        password?.length < 5 ||
                                        passwordConfirmation?.length < 3 ||
                                        password !== passwordConfirmation
                                    }
                                    endIcon={loadingPasswordRecovery && (
                                        <CircularProgress size={20} color="inherit" />
                                    )}
                                >
                                    Redefinir senha
                                </Button>
                            </Grid>
                        </CardContent>
                    </>
                }
            </Card>
        </Grid>
    )
}

export default RecoveryPassword
