import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
    cardContainer: {
        padding: 0,
        background: theme.palette.secondary[theme.palette.mode],
        border: '1px solid #ccccdc12'
    },
    cardContent: {
        paddingBottom: `${theme.spacing(1)} !important`
    },
    audienceGrid: {
        flexGrow: 1
    },
    tooltipContainer: {
        background: theme.palette.background.default,
        borderRadius: theme.spacing(1),
        color: theme.palette.secondary.contrastText,
        width: '300px'
    },
    tooltipValueText: {
        fontSize: '15px'
    },
    tooltipDatetimeText: {
        fontWeight: 700,
        fontSize: '20px'
    },
    headerContainer: {
        paddingBottom: theme.spacing(1)
    },
    selectIntervalInMinutes: {
        color: theme.palette.secondary.contrastText,
        height: '40px',
        width: '150px'
    },
    titleText: {
        fontSize: 20,
        fontWeight: 600,
        color: theme.palette.primary[theme.palette.mode]
    },
    subtitle: {
        fontSize: 14,
        fontWeight: 600,
        color: theme.palette.primary[theme.palette.mode]
    },
    skeletonInsideGrid: {
        height: '356px'
    }
}))

export default useStyles
