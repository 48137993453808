import { makeStyles } from '@mui/styles'

export const navbarHeight = '50px'

const useStyles = makeStyles(theme => ({
    container: {
        background: theme.palette.primary.main,
        height: navbarHeight
    },
    logoutIconButton: {
        marginLeft: theme.spacing(1)
    },
    companyNameText: {
        fontSize: 22,
        fontWeight: 600,
        color: theme.palette.primary.contrastText,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        textAlign: 'center'
    }
}))

export default useStyles
