import dayjs from 'dayjs'
import Markdown from 'markdown-to-jsx'
import React, { useContext, useEffect, useState } from 'react'

import { AuthContext } from '@/contexts/auth'
import { WhatsappQRCodeContext } from '@/contexts/qrcode'
import Audience from '@/pages/App/Home/Audience'
import AudienceHeatMap from '@/pages/App/Home/AudienceHeatMap'
import Cards from '@/pages/App/Home/Cards'
import Columns from '@/pages/App/Home/Columns'
import Concurrence from '@/pages/App/Home/Concurrence'
import DateFilter from '@/pages/App/Home/DateFilter'
import LocationHeatMap from '@/pages/App/Home/LocationHeatMap'
import WhatsappCards from '@/pages/App/Home/WhatsappCards'
import WhatsappTimeseries from '@/pages/App/Home/WhatsappTimeseries'
import ApiService from '@/services/Api'
import CloseIcon from '@mui/icons-material/Close'
import { Alert, Grid, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'

import useStyles from './styles'

interface NoticeItem {
    showAs: string;
    uuid: string;
    message: string;
    companyUuid: string | undefined;
}

interface LocalstorageObject {
    code: string;
    date: string;
    type?: string;
}

const Home: React.FC = () => {
    const { company, user } = useContext(AuthContext)
    const [notices, setNotices] = useState<any>()
    const { connected } = useContext(WhatsappQRCodeContext)
    const [previousConnected, setPreviousConnected] = useState<boolean | undefined>(connected)
    // @ts-ignore
    const [openStates, setOpenStates] = useState<Record<React.Key, boolean>>({})
    const [open, setOpen] = useState(true)

    const classes = useStyles()
    const link = `https://metrics-v2.loopert.com/login?token=${user?.metricsv2Token}`

    document.title = 'Home | Loopert'

    const getNotices = async () => {
        const { data } = await ApiService.get<any>('/notices')

        const currentArrayString = localStorage.getItem('noticesViewed')

        if (currentArrayString) {
            const currentArray = JSON.parse(currentArrayString)
            setNotices(
                currentArray?.length > 0
                    ? data.filter((item: { uuid: string }) => {
                        return !currentArray.find(
                            (obj: { code: string }) => obj.code === item.uuid
                        )
                    })
                    : data
            )
        } else {
            setNotices(data)
        }

        // @ts-ignore

        const initialOpenStates: Record<string, boolean> = {}
        // eslint-disable-next-line no-return-assign
        data.forEach((item: NoticeItem) => initialOpenStates[item.uuid] = true)
        setOpenStates(initialOpenStates)
    }

    useEffect(() => {
        getNotices()

        handleFilterLocalStorage()
    }, [])

    useEffect(() => {
        if (
            typeof connected === 'boolean' &&
            typeof previousConnected === 'boolean'
        ) {
            if (connected !== previousConnected) {
                if (!connected) {
                    const currentArrayString =
                        localStorage.getItem('noticesViewed')
                    if (currentArrayString) {
                        const currentArray = JSON.parse(currentArrayString)
                        const oldNotice = currentArray.find(
                            (item: { type: string }) =>
                                item.type === 'temporary'
                        )
                        if (oldNotice) {
                            const index = currentArray.indexOf(oldNotice)
                            currentArray.splice(index, 1)
                            localStorage.setItem(
                                'noticesViewed',
                                JSON.stringify(currentArray)
                            )
                        }
                    }
                }
                getNotices()
            }
        }
        handleFilterLocalStorage()

        setPreviousConnected(connected)
    }, [connected, previousConnected])

    const handleFilterLocalStorage = () => {
        const currentArrayString = localStorage.getItem('noticesViewed')
        let currentArray = []

        if (currentArrayString) {
            currentArray = JSON.parse(currentArrayString)

            const filteredArray = currentArray.filter(
                (object: {
                    date:
                    | string
                    | number
                    | dayjs.Dayjs
                    | Date
                    | null
                    | undefined;
                }) => {
                    const objectDate = dayjs(object.date, 'YYYY-MM-DD')
                    const daysDifference = dayjs().diff(objectDate, 'day')

                    return daysDifference <= 1
                }
            )

            if (currentArray.length !== filteredArray.length) {
                localStorage.setItem(
                    'noticesViewed',
                    JSON.stringify(filteredArray)
                )
            }
        }
    }

    const handleCollapseToggle = (
        uuid: React.Key,
        companyUuid: string | undefined
    ) => {
        const currentArrayString = localStorage.getItem('noticesViewed')
        const object: LocalstorageObject = {
            code: String(uuid),
            date: dayjs().format('YYYY-MM-DD')
        }
        let currentArray = []

        if (currentArrayString) {
            currentArray = JSON.parse(currentArrayString)
        }

        const alreadyExists = currentArray.find(
            (item: { code: React.Key }) => item.code === uuid
        )
        if (alreadyExists) {
            const index = currentArray.indexOf(alreadyExists)
            currentArray.splice(index, 1)
        }

        if (!connected && companyUuid) {
            // Validação específica para o check de notificações do whatsapp (revisar em casos de alterações dos avisos)
            object.type = 'temporary'
        }

        currentArray.push(object)

        localStorage.setItem('noticesViewed', JSON.stringify(currentArray))

        setOpenStates((prevStates) => ({
            ...prevStates,
            [uuid as string]: !prevStates[uuid as string]
        }))
    }

    const addWidthAttributeToImages = (message: string) => {
        const regex = /<img[^>]*>/g
        const messageWithWidth = message.replace(regex, (match) => {
            return match.replace('>', ' width="100%">')
        })

        return messageWithWidth
    }

    const handleCloseNotice = () => {
        setOpen(false)
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Collapse in={open}>
                    <Alert
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={handleCloseNotice}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        variant="filled"
                        elevation={3}
                        severity="info"
                        style={{
                            marginBottom: 20,
                            backgroundColor: '#fafafa',
                            color: '#111217'
                        }}
                    >
                        <span>
                            {'Conheça a nova interface do Metrics. '}
                            <a href={link} style={{ color: '#2e5eaa', textDecoration: 'underline' }}>
                                Clique aqui para acessar agora!
                            </a>
                        </span>
                    </Alert>
                </Collapse>
            </Grid>
            <Grid item xs={12}>
                {notices?.map((item: NoticeItem) =>
                    item.showAs === 'alert'
                        ? (
                            <Collapse in={openStates[item.uuid]} key={item.uuid}>
                                <Alert
                                    action={
                                        <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() =>
                                                handleCollapseToggle(
                                                    item.uuid,
                                                    item.companyUuid
                                                )
                                            }
                                        >
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                    variant="filled"
                                    elevation={3}
                                    severity="info"
                                    onClose={() => {}}
                                    style={{
                                        marginBottom: 20,
                                        backgroundColor: '#fafafa',
                                        color: '#111217'
                                    }}
                                >
                                    <Markdown>{item.message}</Markdown>
                                </Alert>
                            </Collapse>
                        )
                        : (
                            <Dialog open={openStates[item.uuid]} key={item.uuid}>
                                <DialogContent
                                    style={{
                                        color: '#111217',
                                        backgroundColor: '#fafafa',
                                        fontFamily: 'Roboto, sans-serif'
                                    }}
                                >
                                    <Markdown>
                                        {addWidthAttributeToImages(item.message)}
                                    </Markdown>
                                </DialogContent>
                                <DialogActions
                                    style={{ backgroundColor: '#fafafa' }}
                                >
                                    <Button
                                        color="primary"
                                        onClick={() =>
                                            handleCollapseToggle(
                                                item.uuid,
                                                item.companyUuid
                                            )
                                        }
                                    >
                                    Confirmar Leitura
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        )
                )}
            </Grid>

            <Grid item xs={12}>
                <DateFilter />
            </Grid>

            <Grid item xs={12}>
                <Cards />
            </Grid>

            <Grid item xs={12} className={classes.concurrenceContainer}>
                <Audience />
            </Grid>

            <Grid item xs={12}>
                <AudienceHeatMap />
            </Grid>

            {company?.whatsappEnabled && user?.isBetaTester && (
                <>
                    <Grid item xs={12}>
                        <WhatsappCards />
                    </Grid>

                    <Grid item xs={12} className={classes.concurrenceContainer}>
                        <WhatsappTimeseries />
                    </Grid>
                </>
            )}

            <Grid item xs={12} className={classes.concurrenceContainer}>
                <LocationHeatMap />
            </Grid>

            <Grid item xs={12} className={classes.concurrenceContainer}>
                <Concurrence />
            </Grid>

            <Grid item xs={12} sm={6} className={classes.originsContainer}>
                <Columns columnName="origins" fieldName="origin" />
            </Grid>

            <Grid item xs={12} sm={6} className={classes.platformsContainer}>
                <Columns columnName="platforms" fieldName="platform" />
            </Grid>

            <Grid item xs={12}>
                <Typography
                    variant="body2"
                    align="center"
                    className={classes.footer}
                ></Typography>
            </Grid>
        </Grid>
    )
}

export default Home
