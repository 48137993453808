import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
    table: {
        background: theme.palette.secondary.light
    },
    promotionDialogContainer: {
        // padding: theme.spacing(3)
    },
    dialogTitle: {
        textAlign: 'center'
    },
    dialogItemInput: {
        padding: theme.spacing(1)
    },
    itemInput: {
        width: '100%'
    },
    formControl: {
        minWidth: '100%'
    },
    select: {
        height: theme.spacing(5)
    }
}))

export default useStyles
