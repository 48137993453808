import dayjs from 'dayjs'

export function formatDurationToTime(durationInMilliseconds: number, withDay: boolean = false): string {
    const durationInSeconds = Math.abs(durationInMilliseconds) / 1000

    const days = withDay ? Math.floor(durationInSeconds / 86400) : 0
    const hours = Math.floor((durationInSeconds - (days * 86400)) / 3600)
    const minutes = Math.floor((durationInSeconds - (days * 86400) - (hours * 3600)) / 60)
    const seconds = Math.floor(durationInSeconds - (days * 86400) - (hours * 3600) - (minutes * 60))

    if (days && withDay) return `${days}d ${hours < 10 ? '0' + hours : hours}h ${('0' + minutes).slice(-2)}m`
    if (hours) return `${hours < 10 ? '0' + hours : hours}h ${('0' + minutes).slice(-2)}m ${('0' + seconds).slice(-2)}s`

    return `${('0' + minutes).slice(-2)}m ${('0' + seconds).slice(-2)}s`
}

export function formattedCurrentAudience(date: Date | undefined): string | undefined {
    if (!date) return

    const currentDate = dayjs(date)

    const now = dayjs()

    if (currentDate.isSame(now, 'day')) return `hoje às ${currentDate.format('HH:mm')}`
    if (currentDate.isSame(now.clone().subtract(1, 'days'), 'day')) return `ontem às ${currentDate.format('HH:mm')}`

    return `- ${currentDate.format('DD/MM/YYYY HH:mm')}`
}
