import { useSnackbar } from 'notistack'
import React, { FormEvent, useState } from 'react'

import Divider from '@/components/Divider'
import ApiService from '@/services/Api'
import {
    Grid,
    Button,
    TextField,
    CircularProgress,
    Card,
    CardContent,
    Avatar
} from '@mui/material'

import useStyles from './styles'

type User = {
    name: string
    email: string
    password: string
    confirmPassword: string
    pictureBase64: string
}

const Signup: React.FC = () => {
    const classes = useStyles()

    const { enqueueSnackbar } = useSnackbar()

    const [user, setUser] = useState<User>({} as User)
    const [loadingUser, setLoadingUser] = useState<boolean>(false)

    const params = new URLSearchParams(window.location.search)

    const inviteCode = params.get('invite')

    // const readFileToBase64 = async (file) => {
    //     return await new Promise((resolve, reject) => {
    //         try {
    //             const reader = new FileReader()
    //             reader.readAsDataURL(file)

    //             reader.onloadend = (evt) => {
    //                 if (evt.target.readyState == FileReader.DONE) {
    //                     const base64 = evt.target.result

    //                     resolve(base64)
    //                 }
    //             }
    //         } catch (e) {
    //             reject(e)
    //         }
    //     })
    // }

    const handleClickSelectPicture = async (event: any) => {
        try {
            const fileData = event.target.files[0]

            const fileBase64 = await new Promise((resolve) => {
                const reader = new FileReader()
                reader.readAsDataURL(fileData)

                reader.onloadend = (evt) => {
                    if (evt?.target?.readyState === FileReader.DONE) {
                        const base64 = evt.target.result

                        resolve(base64)
                    }
                }
            })

            setUser({ ...user, pictureBase64: String(fileBase64) })
        } catch (e) {
            enqueueSnackbar('Ocorreu um erro ao tentar ler a imagem.', { variant: 'warning' })
        }
    }

    const handleSignup = async (event: FormEvent<HTMLFormElement> | null = null) => {
        if (event) event.preventDefault()
        if (loadingUser) return

        setLoadingUser(true)

        if (!user.name) {
            setLoadingUser(false)
            return enqueueSnackbar('Você precisa inserir um nome', { variant: 'error' })
        }
        if (!user.email) {
            setLoadingUser(false)
            return enqueueSnackbar('Você precisa inserir um email', { variant: 'error' })
        }
        if (!user.password) {
            setLoadingUser(false)
            return enqueueSnackbar('Você precisa inserir uma senha', { variant: 'error' })
        }
        if (!user.confirmPassword) {
            setLoadingUser(false)
            return enqueueSnackbar('Você precisa inserir a confirmação de senha', { variant: 'error' })
        }
        if (user.password !== user.confirmPassword) {
            setLoadingUser(false)
            return enqueueSnackbar('As senhas precisam que ser identicas.', { variant: 'error' })
        }

        try {
            const { data } = await ApiService.post(`/auth/signup?code=${inviteCode}`, user)

            localStorage.setItem('token', data.token)

            window.location.href = '/'
        } catch ({ data }) {
            setLoadingUser(false)

            enqueueSnackbar(String(data || 'Ocorreu um erro ao tentar se cadastrar.'), { variant: 'error' })
        }
    }

    return (
        <Grid container className={classes.container} alignContent="center" justifyContent="center">
            <Card className={classes.cardContainer}>
                <CardContent className={classes.cardContent}>
                    <form onSubmit={handleSignup}>
                        <Grid item className={classes.input} alignContent="center" justifyContent="center">
                            <Avatar src={user.pictureBase64} className={classes.avatar}></Avatar>

                            <Divider size={2} />

                            <Button
                                fullWidth
                                id="signup-get-fileselector-element-button"
                                color="primary"
                                variant="outlined"
                                disabled={loadingUser}
                                onClick={() => document.getElementById('fileselector')?.click()}
                            >
                                ESCOLHER FOTO
                            </Button>
                            <input
                                id="fileselector"
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                onChange={handleClickSelectPicture}
                                multiple={false}
                                style={{ display: 'none' }}
                            />

                            <Divider size={6} />

                            <TextField
                                label="Nome"
                                id="signup-user-name-textfield"
                                variant="outlined"
                                size="small"
                                fullWidth
                                required
                                value={user.name}
                                onChange={
                                    ({ target }) => setUser({ ...user, name: target.value })
                                }
                            />

                            <Divider size={2} />

                            <TextField
                                label="Email"
                                variant="outlined"
                                size="small"
                                id="signup-user-email-textfield"
                                fullWidth
                                type="email"
                                required
                                value={user.email}
                                onChange={
                                    ({ target }) => setUser({ ...user, email: target.value })
                                }
                            />

                            <Divider size={2} />

                            <TextField
                                label="Senha"
                                variant="outlined"
                                size="small"
                                fullWidth
                                id="signup-user-password-textfield"
                                type="password"
                                required
                                value={user.password}
                                onChange={
                                    ({ target }) => setUser({ ...user, password: target.value })
                                }
                            />

                            <Divider size={2} />

                            <TextField
                                label="Confirmar Senha"
                                variant="outlined"
                                size="small"
                                id="signup-user-confirmpassword-textfield"
                                fullWidth
                                type="password"
                                required
                                value={user.confirmPassword}
                                onChange={
                                    ({ target }) => setUser({ ...user, confirmPassword: target.value })
                                }
                            />

                        </Grid>

                        <Grid item>
                            <Button
                                id="signup-register-button"
                                fullWidth
                                type="submit"
                                className={classes.signinButton}
                                color="primary"
                                variant="contained"
                                disabled={loadingUser}
                                endIcon={loadingUser && (
                                    <CircularProgress size={20} color="inherit" />
                                )}
                            >
                                CADASTRAR
                            </Button>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default Signup
