import dayjs from 'dayjs'
import React, { useContext, useMemo, useState } from 'react'

import DataCard, { DataCardProps } from '@/components/DataCard'
import { DateFilterContext } from '@/contexts/dateFilter'
import ApiService from '@/services/Api'
import { formatDurationToTime } from '@/utils/datetime'
import { Grid } from '@mui/material'

import useStyles from './styles'

type NewListenersResponseType = {
    quantity: number
    differenceQuantity: number
    toComparison?: string
    fromComparison?: string
    typeComparison?: 'day' | 'week' | 'month' | 'year'
}

type TotalSessionsResponseType = NewListenersResponseType
type TotalListenersResponseType = NewListenersResponseType
type TotalCampaignDistributedResponseType = NewListenersResponseType
type TotalCampaignBonusDistributedResponseType = NewListenersResponseType

type AudiencePeakResponseType = {
    sessions: number
    timestamp: string
    differenceSessions: number,
    toComparison?: string
    fromComparison?: string
    typeComparison?: 'day' | 'week' | 'month' | 'year'
}

type AverageDurationResponseType = {
    duration: number
    differenceDuration: number
    toComparison?: string
    fromComparison?: string
    typeComparison?: 'day' | 'week' | 'month' | 'year'
}

const Cards: React.FC = () => {
    const classes = useStyles()

    const { dateFilter } = useContext(DateFilterContext)

    const [totalSessions, setTotalSessions] = useState<DataCardProps>({ loading: true } as DataCardProps)

    const [newListeners, setNewListeners] = useState<DataCardProps>({ loading: true } as DataCardProps)
    // const [recurrentListeners, setRecurrentListeners] = useState<DataCardProps>({ loading: true } as DataCardProps)
    // const [totalListeners, setTotalListeners] = useState<DataCardProps>({ loading: true } as DataCardProps)

    const [audienceTimePeak, setAudienceTimePeak] = useState<DataCardProps>({ loading: true } as DataCardProps)
    const [audienceSessionsPeak, setAudienceSessionsPeak] = useState<DataCardProps>({ loading: true } as DataCardProps)
    const [averageDuration, setAverageDuration] = useState<DataCardProps>({ loading: true } as DataCardProps)
    const [totalListened, setTotalListened] = useState<DataCardProps>({ loading: true } as DataCardProps)

    const [totalCampaignDistributed, setTotalCampaignDistributed] = useState<DataCardProps>({ loading: true } as DataCardProps)
    const [totalCampaignBonusDistributed, setTotalCampaignBonusDistributed] = useState<DataCardProps>({ loading: true } as DataCardProps)

    const getNewListeners = async () => {
        const { data } = await ApiService.get<NewListenersResponseType>(
            `/metrics/listeners/new?from=${dateFilter.from}&to=${dateFilter.to}`
        )

        // mock de dados
        // const data = {
        //     quantity: 54,
        //     differenceQuantity: -2,
        //     toComparison: '2024-04-17T11:43:00.000Z',
        //     fromComparison: '2024-04-17T03:00:00.000Z',
        //     typeComparison: 'day' as 'day' | 'week' | 'month' | 'year'
        // }

        setNewListeners({
            title: 'Novos ouvintes',
            value: `${data?.quantity} ouvinte${data?.quantity === 1 ? '' : 's'}`,
            increasedValue: data?.differenceQuantity,
            increased: data?.differenceQuantity > 0,
            toComparison: data?.toComparison,
            fromComparison: data?.fromComparison,
            typeComparison: data?.typeComparison,
            loading: false
            // error: !data || data?.quantity === null || data?.quantity === undefined
        })
    }

    // const getRecurrentListeners = async () => {
    //     const { data } = await ApiService.get<TotalListenersResponseType>(
    //         `/metrics/listeners/recurrent?from=${dateFilter.from}&to=${dateFilter.to}`
    //     )

    //     setRecurrentListeners({
    //         title: 'Ouvintes recorrentes',
    //         value: data?.quantity === 1 ? `${data?.quantity} ouvinte` : `${data?.quantity} ouvintes`,
    //         increasedValue: data?.differenceQuantity,
    //         increased: data?.differenceQuantity > 0,
    //         toComparison: data?.toComparison,
    //         fromComparison: data?.fromComparison,
    //         typeComparison: data?.typeComparison,
    //         loading: false
    //         // error: !data || data?.quantity === null || data?.quantity === undefined
    //     })
    // }

    const getTotalListened = async () => {
        const { data } = await ApiService.get<TotalListenersResponseType>(
            `/metrics/session/listened/total?from=${dateFilter.from}&to=${dateFilter.to}`
        )

        // mock de dados
        // const data = {
        //     quantity: 483637991,
        //     differenceQuantity: -1827584946,
        //     toComparison: '2024-04-17T11:43:00.000Z',
        //     fromComparison: '2024-04-17T03:00:00.000Z',
        //     typeComparison: 'day' as 'day' | 'week' | 'month' | 'year'
        // }

        setTotalListened({
            title: 'Tempo total ouvido',
            value: formatDurationToTime(data?.quantity, true),
            increasedValue: formatDurationToTime(data?.differenceQuantity, true),
            increased: data?.differenceQuantity > 0,
            toComparison: data?.toComparison,
            fromComparison: data?.fromComparison,
            typeComparison: data?.typeComparison,
            loading: false
            // error: !data || data?.quantity === null || data?.quantity === undefined
        })
    }

    // const getTotalListeners = async () => {
    //     const { data } = await ApiService.get<TotalListenersResponseType>(
    //         `/metrics/listeners/total?from=${dateFilter.from}&to=${dateFilter.to}`
    //     )

    //     setTotalListeners({
    //         title: 'Alcance de ouvintes',
    //         value: data?.quantity === 1 ? `${data?.quantity} ouvinte` : `${data?.quantity} ouvintes`,
    //         increasedValue: data?.differenceQuantity,
    //         increased: data?.differenceQuantity > 0,
    //         toComparison: data?.toComparison,
    //         fromComparison: data?.fromComparison,
    //         typeComparison: data?.typeComparison,
    //         loading: false
    //         // error: !data || data?.quantity === null || data?.quantity === undefined
    //     })
    // }

    const getTotalSessions = async () => {
        const { data } = await ApiService.get<TotalSessionsResponseType>(
            `/metrics/session/total?from=${dateFilter.from}&to=${dateFilter.to}`
        )

        // mock de dados
        // const data = {
        //     quantity: 394,
        //     differenceQuantity: -15,
        //     toComparison: '2024-04-17T11:43:00.000Z',
        //     fromComparison: '2024-04-17T03:00:00.000Z',
        //     typeComparison: 'day' as 'day' | 'week' | 'month' | 'year'
        // }

        setTotalSessions({
            title: 'Total de sessões',
            value: data?.quantity === 1 ? `${data?.quantity ?? 0} sessão` : `${data?.quantity ?? 0} sessões`,
            increasedValue: data?.differenceQuantity,
            increased: data?.differenceQuantity > 0,
            toComparison: data?.toComparison,
            fromComparison: data?.fromComparison,
            typeComparison: data?.typeComparison,
            loading: false
            // error: !data || data?.quantity === null || data?.quantity === undefined
        })
    }

    const getAudiencePeak = async () => {
        const { data } = await ApiService.get<AudiencePeakResponseType>(
            `/metrics/audience/peak?from=${dateFilter.from}&to=${dateFilter.to}`
        )

        // mock de dados
        // const data = {
        //     application: 'Metrics Backend',
        //     sessions: 130,
        //     timestamp: '2024-04-18T11:35:00.000Z',
        //     differenceSessions: 0,
        //     toComparison: '2024-04-17T11:43:00.000Z',
        //     fromComparison: '2024-04-17T03:00:00.000Z',
        //     typeComparison: 'day' as 'day' | 'week' | 'month' | 'year'
        // }

        setAudienceSessionsPeak({
            title: 'Pico de audiência',
            value: data?.sessions === 1 ? `${data?.sessions} sessão` : `${data?.sessions} ouvintes`,
            increasedValue: data?.differenceSessions,
            increased: data?.differenceSessions > 0,
            toComparison: data?.toComparison,
            fromComparison: data?.fromComparison,
            typeComparison: data?.typeComparison,
            loading: false
            // error: !data || data?.sessions === null || data?.sessions === undefined
        })

        setAudienceTimePeak({
            title: 'Horário de pico',
            value: dayjs(new Date(data?.timestamp)).format('HH:mm'),
            loading: false
            // error: !data || data?.timestamp === null || data?.timestamp === undefined
        })
    }

    const getAverageDuration = async () => {
        try {
            const { data } = await ApiService.get<AverageDurationResponseType>(
                `/metrics/session/listened/average?from=${dateFilter.from}&to=${dateFilter.to}`
            )

            // mock de dados
            // const data = {
            //     duration: '1116946.861431870670',
            //     differenceDuration: -4646701.360513266,
            //     toComparison: '2024-04-17T11:43:00.000Z',
            //     fromComparison: '2024-04-17T03:00:00.000Z',
            //     typeComparison: 'day' as 'day' | 'week' | 'month' | 'year'
            // }

            setAverageDuration({
                title: 'Tempo médio ouvido',
                value: formatDurationToTime(+data?.duration),
                increasedValue: formatDurationToTime(+data?.differenceDuration),
                increased: data?.differenceDuration > 0,
                toComparison: data?.toComparison,
                fromComparison: data?.fromComparison,
                typeComparison: data?.typeComparison,
                loading: false
                // error: !data || data?.duration === null || data?.duration === undefined
            })
        } catch (e) {
            console.log(e)
        }
    }

    const getTotalCampaignDistributed = async () => {
        const { data } = await ApiService.get<TotalCampaignDistributedResponseType>(
            `/metrics/campaigns/distributed/total?from=${dateFilter.from}&to=${dateFilter.to}`
        )

        // mock de dados
        // const data = {
        //     quantity: 0,
        //     differenceQuantity: 1,
        //     toComparison: '2024-04-17T11:43:00.000Z',
        //     fromComparison: '2024-04-17T03:00:00.000Z',
        //     typeComparison: 'day' as 'day' | 'week' | 'month' | 'year'
        // }

        setTotalCampaignDistributed({
            title: 'Propagandas veiculadas',
            value: data?.quantity === 1 ? `${data?.quantity} propaganda` : `${data?.quantity} propagandas`,
            increasedValue: data?.differenceQuantity,
            increased: data?.differenceQuantity > 0,
            toComparison: data?.toComparison,
            fromComparison: data?.fromComparison,
            typeComparison: data?.typeComparison,
            loading: false
            // error: !data || data?.sessions === null || data?.sessions === undefined
        })
    }
    const getTotalCampaignBonusDistributed = async () => {
        const { data } = await ApiService.get<TotalCampaignBonusDistributedResponseType>(
            `/metrics/campaigns/distributed/bonus/total?from=${dateFilter.from}&to=${dateFilter.to}`
        )

        // mock de dados
        // const data = {
        //    quantity: 0,
        //    differenceQuantity: 1,
        //    toComparison: '2024-04-17T11:43:00.000Z',
        //    fromComparison: '2024-04-17T03:00:00.000Z',
        //    typeComparison: 'day' as 'day' | 'week' | 'month' | 'year'
        // }

        setTotalCampaignBonusDistributed({
            title: 'Propagandas bônus veiculadas',
            value: data?.quantity === 1 ? `${data?.quantity} propaganda` : `${data?.quantity} propagandas`,
            increasedValue: data?.differenceQuantity,
            increased: data?.differenceQuantity > 0,
            toComparison: data?.toComparison,
            fromComparison: data?.fromComparison,
            typeComparison: data?.typeComparison,
            loading: false
            // error: !data || data?.sessions === null || data?.sessions === undefined
        })
    }

    const getCardsData = async () => {
        setNewListeners({ ...newListeners, loading: true })
        // setRecurrentListeners({ ...recurrentListeners, loading: true })
        // setTotalListeners({ ...totalListeners, loading: true })
        setTotalSessions({ ...totalSessions, loading: true })
        setAudienceTimePeak({ ...audienceTimePeak, loading: true })
        setAudienceSessionsPeak({ ...audienceSessionsPeak, loading: true })
        setAverageDuration({ ...averageDuration, loading: true })
        setTotalListened({ ...totalListened, loading: true })
        setTotalCampaignDistributed({ ...totalCampaignDistributed, loading: true })
        setTotalCampaignBonusDistributed({ ...totalCampaignBonusDistributed, loading: true })

        getNewListeners()
        // getRecurrentListeners()
        // getTotalListeners()
        getTotalSessions()
        getAudiencePeak()
        getAverageDuration()
        getTotalCampaignDistributed()
        getTotalCampaignBonusDistributed()

        getTotalListened()
    }

    useMemo(() => {
        getCardsData()
    }, [dateFilter])

    return (
        <>
            <Grid container spacing={2} className={classes.cardsGrid}>
                <Grid item xs={12} sm={6} md={4} lg={4} className={classes.cardGrid}>
                    <DataCard
                        title={totalSessions.title}
                        value={totalSessions.value}
                        increased={totalSessions.increased}
                        increasedValue={totalSessions.increasedValue}
                        loading={totalSessions.loading}
                        toComparison={totalSessions.toComparison}
                        fromComparison={totalSessions.fromComparison}
                        typeComparison={totalSessions.typeComparison}
                        helperMessage={`
                            Consideramos uma sessão como um IP válido. O total de sessões é a soma de todos os IPs válidos recorrentes cadastrados cumulativamente no métrics.
                        `}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4} className={classes.cardGrid}>
                    <DataCard
                        title={audienceSessionsPeak.title}
                        value={audienceSessionsPeak.value}
                        increased={audienceSessionsPeak.increased}
                        increasedValue={audienceSessionsPeak.increasedValue}
                        loading={audienceSessionsPeak.loading}
                        toComparison={audienceSessionsPeak.toComparison}
                        fromComparison={audienceSessionsPeak.fromComparison}
                        typeComparison={audienceSessionsPeak.typeComparison}
                        helperMessage={`
                            Total de ouvintes conectados ao streaming simultâneamente.
                        `}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4} className={classes.cardGrid}>
                    <DataCard
                        title={newListeners.title}
                        value={newListeners.value}
                        increased={newListeners.increased}
                        increasedValue={newListeners.increasedValue}
                        loading={newListeners.loading}
                        toComparison={newListeners.toComparison}
                        fromComparison={newListeners.fromComparison}
                        typeComparison={newListeners.typeComparison}
                        helperMessage={`
                            Ouvintes que acessaram o streaming pela primeira vez.
                        `}
                    />
                </Grid>

                {/* <Grid item xs={12} sm={6} md={4} lg={4} className={classes.cardGrid}>
                    <DataCard
                        title={recurrentListeners.title}
                        value={recurrentListeners.value}
                        increased={recurrentListeners.increased}
                        increasedValue={recurrentListeners.increasedValue}
                        loading={recurrentListeners.loading}
                        toComparison={recurrentListeners.toComparison}
                        fromComparison={recurrentListeners.fromComparison}
                        typeComparison={recurrentListeners.typeComparison}
                        helperMessage={`
                            Ouvintes que já acessaram o streaming antes.
                        `}
                    />
                </Grid> }
                {
                <Grid item xs={12} sm={6} md={4} lg={4} className={classes.cardGrid}>
                    <DataCard
                        title={totalListeners.title}
                        value={totalListeners.value}
                        increased={totalListeners.increased}
                        increasedValue={totalListeners.increasedValue}
                        loading={totalListeners.loading}
                        toComparison={totalListeners.toComparison}
                        fromComparison={totalListeners.fromComparison}
                        typeComparison={totalListeners.typeComparison}
                        helperMessage={`
                            O total de ouvintes alcançados.
                        `}
                    />
                </Grid> */}

                <Grid item xs={12} sm={6} md={4} lg={4} className={classes.cardGrid}>
                    <DataCard
                        title={audienceTimePeak.title}
                        value={audienceTimePeak.value}
                        increased={audienceTimePeak.increased}
                        increasedValue={audienceTimePeak.increasedValue}
                        loading={audienceTimePeak.loading}
                        toComparison={audienceTimePeak.toComparison}
                        fromComparison={audienceTimePeak.fromComparison}
                        typeComparison={audienceTimePeak.typeComparison}
                        helperMessage={`
                            Horário de maior audiência na emissora em relação ao período pesquisado.
                        `}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4} className={classes.cardGrid}>
                    <DataCard
                        title={averageDuration.title}
                        value={averageDuration.value}
                        increased={averageDuration.increased}
                        increasedValue={averageDuration.increasedValue}
                        loading={averageDuration.loading}
                        toComparison={averageDuration.toComparison}
                        fromComparison={averageDuration.fromComparison}
                        typeComparison={averageDuration.typeComparison}
                        helperMessage={`
                            O tempo médio é obtido dividindo o número total de horas ouvidas, pelo total de sessões válidas.
                        `}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4} className={classes.cardGrid}>
                    <DataCard
                        title={totalListened.title}
                        value={totalListened.value}
                        increased={totalListened.increased}
                        increasedValue={totalListened.increasedValue}
                        loading={totalListened.loading}
                        toComparison={totalListened.toComparison}
                        fromComparison={totalListened.fromComparison}
                        typeComparison={totalListened.typeComparison}
                        helperMessage={`
                            Soma do tempo ouvido por todos os ouvintes.
                        `}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default Cards
