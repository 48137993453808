import { Chart, ArcElement, Legend, Tooltip, Plugin } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import React from 'react'
import { Doughnut } from 'react-chartjs-2'

interface GenderData {
    female: number;
    male: number;
}

interface GenderDoughnutChartProps {
    data: GenderData;
}

const GenderDoughnutChart: React.FC<GenderDoughnutChartProps> = ({ data }) => {
    Chart.register(ArcElement, Tooltip, Legend)

    const chartData = {
        labels: ['Feminino', 'Masculino'],
        datasets: [{
            label: 'Porcentagem de Pessoas',
            data: [data.female, data.male],
            backgroundColor: [
                'rgba(255, 99, 132, 0.6)',
                'rgba(54, 162, 235, 0.6)'
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)'
            ],
            borderWidth: 1
        }]
    }

    return (
        <Doughnut data={chartData}
            options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: true,
                        position: 'top',
                        reverse: true
                    },
                    tooltip: {
                        enabled: false
                    },
                    datalabels: {
                        display: true,
                        color: 'white',
                        font: {
                            weight: 'bold',
                            size: 16
                        },
                        formatter: (value) => `${value}%`
                    }
                }
            }}
            style={{ height: '300px' }}
            plugins={[ChartDataLabels as Plugin<'doughnut'>]}
        />
    )
}

export default GenderDoughnutChart
