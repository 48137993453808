import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        height: '100%'
    },
    cardContainer: {
        maxWidth: '90%',
        width: '350px',
        background: theme.palette.secondary.light,
        borderRadius: theme.spacing(1),
        padding: theme.spacing(2)
    },
    cardContent: {
        borderBottom: `${theme.spacing(1)} !important`
    },
    input: {
        paddingBottom: theme.spacing(2)
    },
    signinButton: {
        marginTop: theme.spacing(2),
        paddingTop: theme.spacing(1),
        fontWeight: 700
    },
    avatar: {
        height: 200,
        width: 200,
        marginLeft: '43px'
    }
}))

export default useStyles
