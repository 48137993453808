import React, { useContext, useEffect, useState } from 'react'

import { WhatsappQRCodeContext } from '@/contexts/qrcode'
import useInterval from '@/hooks/useInterval'
import useDidMount from '@/hooks/useMount'
import ApiService from '@/services/Api'
import { CircularProgress, Grid } from '@mui/material'

import ValidationValidation from './Authentication'
import WhatsappConnected from './Connected'
import useStyles from './styles'

const Home: React.FC = () => {
    const classes = useStyles()

    document.title = 'Whatsapp | Loopert'

    const { connected, loading } = useContext(WhatsappQRCodeContext)

    return loading && !connected
        ? <Grid container className={classes.loadingContainer} justifyContent="center" alignContent="center">
            <CircularProgress size={80} color="primary" />
        </Grid>
        : connected
            ? <WhatsappConnected />
            : <ValidationValidation />
}

export default Home
