import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
    container: {
        flexGrow: 1
    },
    imageBoxGrid: {
        background: '#ffffff',
        margin: 'auto',
        padding: theme.spacing(2),
        minWidth: '264px',
        minHeight: '264px',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    },
    imageCircularProgress: {
        margin: 'auto',
        minWidth: '100px',
        minHeight: '100px'
    },
    imageRefresh: {
        margin: 'auto',
        minWidth: '140px',
        minHeight: '140px'
    },
    imageContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: theme.spacing(2)
    },
    messageContainer: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    messageExplanation: {
        color: theme.palette.primary[theme.palette.mode],
        fontSize: 20,
        fontWeight: 500
    },
    messageExpiration: {
        color: theme.palette.primary[theme.palette.mode],
        paddingTop: theme.spacing(8),
        fontSize: 24,
        fontWeight: 500
    },
    messageRemember: {
        color: theme.palette.primary[theme.palette.mode],
        paddingTop: theme.spacing(8),
        fontSize: 20,
        fontWeight: 500
    }
}))

export default useStyles
