import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
    cardsGrid: {
        flexGrow: 1,
        marginTop: theme.spacing(3)
    },
    cardGrid: {
        // padding: theme.spacing(2)
    }
}))

export default useStyles
