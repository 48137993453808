import { Chart, CategoryScale, BarElement, PointElement, LineElement, TimeSeriesScale, Legend, Tooltip, TooltipModel, TooltipItem, Filler } from 'chart.js'
import Annotation from 'chartjs-plugin-annotation'
import dayjs from 'dayjs'
import _ from 'lodash'
import React, { useContext, useState, useMemo, useEffect } from 'react'
import 'chartjs-adapter-dayjs'
import { Line } from 'react-chartjs-2'

import { AuthContext } from '@/contexts/auth'
import { CurrentAudienceContext } from '@/contexts/currentAudience'
import { DateFilterContext } from '@/contexts/dateFilter'
import { utcConvert } from '@/helpers/utcConvert'
import ApiService from '@/services/Api'
import { formattedCurrentAudience } from '@/utils/datetime'
import { smoothFluctuation } from '@/utils/smoothFluctuation'
import {
    Skeleton,
    Grid,
    Typography,
    Card,
    CardContent
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

// mock de dados
// import mockGrid from './grid.json'
// import mockAudience from './mock-audience.json'
import useStyles from './styles'

interface IAudience {
    timestamp: string
    sessions: number
}

interface IGrid {
    startAt: string
    endAt: string
    weekdays: number[]
}

interface IProgramGrid {
    name: string
    grid: IGrid[]
}

interface IProgramByTimestamps {
    name: string
    timestamp: string
}

type IAudienceResponse = IAudience[]
type IProgramGridResponse = IProgramGrid[]

interface ILoadingAudience {
    gridLoading: boolean,
    audienceLoading: boolean
}

interface CustomTooltipItem extends TooltipItem<'line'> {
    dataset: {
        realValues: number[]
        data: number[]
    };
}

Chart.register(CategoryScale, BarElement, PointElement, LineElement, TimeSeriesScale, Legend, Tooltip, Annotation, Filler)

const Audience: React.FC = () => {
    const classes = useStyles()

    const theme = useTheme()

    const { sessions, sessionsDate } = useContext(CurrentAudienceContext)

    const { dateFilter } = useContext(DateFilterContext)
    const { company, darkMode, user } = useContext(AuthContext)
    const [currentSessionsQuantity, setCurrentSessionsQuantity] = useState<number>()
    const [chartData, setChartData] = useState<any>([])
    const [programByTimestamps, setProgramByTimestamps] = useState<IProgramByTimestamps[]>([])
    const [programGridsData, setProgramGridsData] = useState<IProgramGrid[]>([])
    const [loading, setLoading] = useState<ILoadingAudience>({ gridLoading: false, audienceLoading: true })

    const handleProgramsByTimestamps = () => {
        let timestamp = dayjs(dateFilter.from)
        setProgramByTimestamps([])
        const _programByTimestamps: IProgramByTimestamps[] = []

        if (
            dayjs(dateFilter.from).add(1, 'days') < dayjs(dateFilter.to) ||
            programGridsData?.length === 0 ||
            _.isEmpty(chartData) ||
            chartData.length === 0 ||
            chartData.labels.length === 0
        ) return

        while (timestamp <= dayjs(dateFilter.to)) {
            const firstChartTimestamp = dayjs(chartData?.labels[0])
            const lastChartTimestamp = dayjs(chartData?.labels[chartData?.labels.length - 1])

            programGridsData
                .filter(programGrid =>
                    programGrid.grid.some(grid => grid.weekdays.includes(timestamp.day()))
                ).forEach(programGrid =>
                    programGrid.grid.forEach(grid => {
                        const [hour, minute] = grid.startAt.split(':').map(time => parseInt(time))

                        const gridTimestamp = timestamp.hour(hour).minute(minute).subtract(3, 'hours')

                        if (timestamp.toISOString() === dayjs(dateFilter.from).toISOString()) {
                            const [hourEnd, minuteEnd] = grid.endAt.split(':').map(time => parseInt(time))
                            let endGridTimestamp = timestamp.hour(hourEnd).minute(minuteEnd).subtract(3, 'hours')

                            if (gridTimestamp.toISOString() < endGridTimestamp.toISOString()) endGridTimestamp = endGridTimestamp.add(1, 'day')

                            if (firstChartTimestamp.toISOString() >= gridTimestamp.toISOString() && firstChartTimestamp.toISOString() < endGridTimestamp.toISOString()) {
                                _programByTimestamps.push({
                                    name: programGrid.name,
                                    timestamp: firstChartTimestamp.toISOString()
                                })
                                return
                            }
                        }

                        if (gridTimestamp < timestamp || gridTimestamp >= dayjs(dateFilter.to) || gridTimestamp > lastChartTimestamp) return

                        _programByTimestamps.push({
                            name: programGrid.name,
                            timestamp: gridTimestamp.toISOString()
                        })
                    })
                )

            timestamp = timestamp.add(1, 'day').startOf('hour').startOf('minute').startOf('second')
        }

        setProgramByTimestamps(_programByTimestamps)
    }

    const getProgramGridData = async () => {
        setLoading({ ...loading, gridLoading: true })
        const { data } = await ApiService.get<IProgramGridResponse>(
            '/metrics/program-grid'
        )

        // mock de dados
        // const data: IProgramGridResponse = mockGrid

        setProgramGridsData(data)
        setLoading({ ...loading, gridLoading: false })
    }

    const getAudienceData = async () => {
        setLoading({ ...loading, audienceLoading: true })

        const { data } = await ApiService.get<IAudienceResponse>(
            '/metrics/audience',
            {
                params: {
                    from: dateFilter.from,
                    to: dateFilter.to
                }
            }
        )

        // mock de dados
        // const data = mockAudience
        const labels = data.map(audience => dayjs(audience.timestamp).subtract(3, 'hour'))

        setCurrentSessionsQuantity(Number(data?.[data.length - 1]?.sessions))

        const ALLOWED_COMPANIES_FOR_SCRIPT =
            JSON.parse(process.env.REACT_APP_ALLOWED_COMPANIES ?? '[]') as string[]

        const sessionsData = data.map(audience => ({
            sessions: +audience.sessions,
            timestamp: audience.timestamp
        }))
        const limitedSessions = ALLOWED_COMPANIES_FOR_SCRIPT.includes(company!.uuid) ? smoothFluctuation(sessionsData, 20) : sessionsData.map(item => item.sessions)

        const normalDataset = {
            tension: 0.4,
            fill: true,
            borderColor: '#73bf69',
            backgroundColor: darkMode ? '#73bf6980' : '#299a1a80',
            borderWidth: 1,
            yAxisID: 'y',
            data: limitedSessions,
            realValues: data.map(item => +item.sessions)
        }

        setChartData({
            labels,
            datasets: [normalDataset]
        })

        setLoading({ ...loading, audienceLoading: false })
    }

    const getProgramName = (_timestamp: string) => {
        if (programGridsData?.length === 0) return ''

        const program = programGridsData?.find(programGrid => programGrid.grid.some(grid => {
            const timestamp = dayjs(_timestamp)
            const day = timestamp.day()

            const { weekdays, startAt, endAt } = grid

            const [hour, minute] = [timestamp.hour(), timestamp.minute()]
            const [hourStart, minuteStart] = utcConvert(startAt, -0).split(':').map(time => parseInt(time))
            const [hourEnd, minuteEnd] = utcConvert(endAt, -0).split(':').map(time => parseInt(time))

            return (weekdays.includes(day) &&
                (
                    hour > hourStart || (
                        hour === hourStart && minute >= minuteStart
                    )
                ) &&
                (
                    hour < hourEnd ||
                    (
                        hour === hourEnd && minute < minuteEnd
                    )
                )
            ) || (
                hourStart > hourEnd && ((
                    weekdays.includes(day) && (
                        hour >= hourStart ||
                            (
                                hour === hourStart && minute >= minuteStart
                            )
                    )
                ) || (
                    weekdays.includes(day === 0 ? 7 : day - 1) && (
                        hour < hourEnd ||
                                (
                                    hour === hourEnd && minute < minuteEnd
                                )
                    )
                )
                )
            )
        }))

        return program?.name || ''
    }

    useEffect(() => {
        if (user?.isBetaTester) {
            handleProgramsByTimestamps()
        }

        // handleProgramsByTimestamps()
    }, [programGridsData])

    useEffect(() => {
        if (user?.isBetaTester) {
            getProgramGridData()
        }

        // getProgramGridData()
    }, [chartData])

    useMemo(() => {
        getAudienceData()
    }, [dateFilter, darkMode])

    return (
        <Card className={classes.cardContainer}>
            <CardContent className={classes.cardContent}>
                <Grid container className={classes.audienceGrid}>
                    <Grid item xs={12} sm={12}>
                        <Grid container alignItems='center' justifyContent="center" flexDirection='column'>
                            <Typography className={classes.titleText}>
                                AUDIÊNCIA
                            </Typography>
                            {sessions && (
                                <Typography className={classes.subtitle}>{sessions} sessões {formattedCurrentAudience(sessionsDate)}</Typography>
                            )}
                        </Grid>
                    </Grid>
                    {
                        loading.audienceLoading || loading.gridLoading
                            ? <Grid item xs={12} sm={12}>
                                {
                                    _.times(28, (i: any) => (
                                        <Skeleton
                                            key={i}
                                            variant="rectangular" width="2.5%" height={(i + 1) * 11}
                                            style={{
                                                float: 'left',
                                                marginLeft: '1%',
                                                marginTop: 360 - ((i + 1) * 11)
                                            }}
                                        />
                                    ))
                                }
                            </Grid>
                            : <Grid item xs={12} sm={12}>
                                {
                                    chartData &&
                                    chartData?.datasets &&
                                    <Line
                                        height={80}
                                        data={chartData}
                                        options={{
                                            plugins: {
                                                annotation: {
                                                    annotations: programByTimestamps.map(program => {
                                                        return {
                                                            type: 'line',
                                                            scaleID: 'x',
                                                            borderWidth: 2,
                                                            borderColor: `${theme.palette.info[theme.palette.mode]}98`,
                                                            value: program.timestamp,
                                                            label: {
                                                                opacity: 5,
                                                                rotation: 'auto',
                                                                position: 'start',
                                                                backgroundColor: `${theme.palette.info[theme.palette.mode]}98`,
                                                                content: program.name,
                                                                display: true,
                                                                color: `${theme.palette.primary[theme.palette.mode]}98`,
                                                                font: {
                                                                    size: 10
                                                                }
                                                            }
                                                        }
                                                    })
                                                },
                                                legend: {
                                                    display: false
                                                },
                                                tooltip: {
                                                    backgroundColor: '#202227',
                                                    titleColor: '#ccccdc',
                                                    boxHeight: 1,
                                                    boxPadding: 3,
                                                    bodySpacing: 5,
                                                    callbacks: {
                                                        label: function (this: TooltipModel<'line'>, tooltipItem: CustomTooltipItem) {
                                                            const currentValue = Number(tooltipItem.formattedValue)
                                                            const originalValue = Number(tooltipItem.dataset.realValues[tooltipItem.dataIndex])

                                                            return currentValue !== originalValue ? `${currentValue} ouvintes*` : `${currentValue} ouvintes`
                                                        },
                                                        title: function (this: TooltipModel<'line'>, tooltipItem: TooltipItem<'line'>[]) {
                                                            return dayjs(tooltipItem[0].label).format('DD/MM/YYYY HH:mm') + 'h'
                                                        },
                                                        beforeBody: function (this: TooltipModel<'line'>, tooltipItem: TooltipItem<'line'>[]) {
                                                            return getProgramName(tooltipItem[0].label)
                                                        }
                                                    }
                                                }
                                            },
                                            responsive: true,
                                            interaction: {
                                                mode: 'index',
                                                intersect: false
                                            },
                                            elements: {
                                                point: {
                                                    radius: 0
                                                }
                                            },
                                            scales: {
                                                x: {
                                                    type: 'time',
                                                    time: {
                                                        unit: 'minute',
                                                        displayFormats: {
                                                            minute: 'HH:mm'
                                                        }
                                                    },
                                                    grid: {
                                                        color: theme.palette.info[theme.palette.mode],
                                                        borderColor: theme.palette.info[theme.palette.mode]
                                                    },
                                                    ticks: {
                                                        color: theme.palette.primary[theme.palette.mode]
                                                    }
                                                },
                                                y: {
                                                    type: 'linear',
                                                    display: true,
                                                    position: 'left',
                                                    grid: {
                                                        color: darkMode ? theme.palette.info[theme.palette.mode] : '#ccccdc',
                                                        borderColor: darkMode ? theme.palette.info[theme.palette.mode] : '#ccccdc'
                                                    },
                                                    ticks: {
                                                        color: theme.palette.primary[theme.palette.mode]
                                                    },
                                                    min: 0
                                                }
                                            }
                                        }}
                                    />
                                }
                            </Grid>
                    }
                </Grid>
            </CardContent>
        </Card>
    )
}

export default Audience
