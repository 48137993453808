import React, { createContext, useEffect, useState } from 'react'

import useMount from '@/hooks/useMount'
import apiService from '@/services/Api'

type IProduct = 'metrics' | 'club'

type CompanyType = {
    streamURL: string | undefined
    uuid: string
    name: string
    streamEnabled: boolean
    whatsappEnabled: boolean
    youtubeEnabled: boolean
    streamId: number
    address?: {
        latitude?: number,
        longitude?: number,
        city?: string,
        state?: string,
        country?: string
    }
}

type UserType = {
    uuid: string
    email: string
    pictureKey: string
    product: IProduct
    isBetaTester: boolean
    whatsappEnabled: boolean,
    youtubeEnabled: boolean,
    preferences: UserPreferencesType,
    metricsv2Token: string
}

type UserPreferencesType = {
    darkMode: object
}

type AuthContextProps = {
    logged: boolean,
    loading: boolean,
    darkMode: boolean,
    product: IProduct,
    user: UserType | null,
    company: CompanyType | null,
    whatsappEnabled: boolean,
    youtubeEnabled: boolean,
    setUser: (user: UserType | null) => void
    setCompany: (company: CompanyType | null) => void,
    setDarkMode: (darkMode: boolean) => void
}

export const AuthContext = createContext<AuthContextProps>({} as AuthContextProps)

export const AuthContextProvider: React.FC<any> = ({ children }: any) => {
    const [user, setUser] = useState<UserType | null>({} as UserType)
    const [company, setCompany] = useState<CompanyType | null>({} as CompanyType)
    const [loading, setLoading] = useState<boolean>(true)
    const [darkMode, setDarkMode] = useState<boolean>(true)
    const params = new URLSearchParams(window.location.search)
    const token = params.get('token')

    if (token) localStorage.setItem('token', token)

    useMount(async () => {
        try {
            const { data } = await apiService.get('/auth/check')

            try {
                // @ts-ignore
                // eslint-disable-next-line no-undef
                cookieStore.set('companyUuid', data.company.uuid)
                // @ts-ignore
                // eslint-disable-next-line no-undef
                cookieStore.set('userUuid', data.user.uuid)
            } catch (e) { }

            setUser(data.user)
            setCompany(data.company)
        } catch (e) {
            localStorage.removeItem('token')

            setUser(null)
            setCompany(null)
        }

        setTimeout(() => {
            setLoading(false)
        }, 2000)
    })

    useEffect(() => {
        setDarkMode(typeof user?.preferences?.darkMode === 'boolean' ? user.preferences.darkMode : true)
    }, [user])

    return (
        <AuthContext.Provider
            value={{
                logged: !!user,
                product: user?.product || 'club',
                user,
                company,
                loading,
                whatsappEnabled: company?.whatsappEnabled || false,
                youtubeEnabled: company?.youtubeEnabled || false,
                setUser,
                setCompany,
                darkMode,
                setDarkMode
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
