import {
    Chart,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeSeriesScale,
    Legend,
    Tooltip,
    TooltipModel,
    TooltipItem,
    ChartEvent,
    LegendItem
} from 'chart.js'
import 'chartjs-adapter-dayjs'
import dayjs from 'dayjs'
import _ from 'lodash'
import React, { useContext, useMemo, useState } from 'react'
import { Line } from 'react-chartjs-2'

import { AuthContext } from '@/contexts/auth'
import { CurrentAudienceContext } from '@/contexts/currentAudience'
import { DateFilterContext } from '@/contexts/dateFilter'
import ApiService from '@/services/Api'
import { formattedCurrentAudience } from '@/utils/datetime'
import { smoothFluctuation } from '@/utils/smoothFluctuation'
import {
    Grid,
    Typography,
    Card,
    CardContent,
    Skeleton
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

import useStyles from './styles'

// mock de dados
// import mockConcurrence from './mock-concurrence.json'

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, TimeSeriesScale, Legend, Tooltip)

interface IAveragePositionResponse {
    position: number
}

interface IConcurrenceResponse {
    [key: string]: {
        company_uuid: string,
        name: string,
        sessions: number,
        timestamp: string,
    }[]
}

const COLOR_GREEN = '#7dbb62'
const COLORS = [
    '#819000',
    '#98531e',
    '#9f1423',
    '#bf6969',
    '#69bfa5',
    '#699bbf',
    '#696abf',
    '#8a69bf',
    '#a869bf',
    '#bf69b8',
    '#bf698a'
]

const Concurrence: React.FC = () => {
    const classes = useStyles()

    const theme = useTheme()

    const { company, darkMode } = useContext(AuthContext)
    const { dateFilter } = useContext(DateFilterContext)
    const { externalSessions, externalSessionsDate } = useContext(CurrentAudienceContext)

    const [averageAudiencePosition, setAverageAudiencePosition] = useState<number>()
    const [chartData, setChartData] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(true)

    const getConcurrenceData = async () => {
        setLoading(true)

        const { data } = await ApiService.get<IConcurrenceResponse>(
            `/metrics/audience/concurrence?from=${dateFilter.from}&to=${dateFilter.to}`
        )

        // const data: {
        //     [key: string]: {
        //         name: string;
        //         company_uuid: string;
        //         timestamp: string;
        //         sessions: string;
        //     }[];
        // } = mockConcurrence

        if (!Object.keys(data).length) return

        const companyUuids = Object.keys(data).sort(a => a === company?.uuid ? -1 : 1)

        const labels = (data as Record<string, any>)[companyUuids[0]]?.map((row: any) => dayjs(row.timestamp).subtract(3, 'hour'))

        let count = 0
        const datasets: any = companyUuids.map(companyUuid => {
            const rows = data[companyUuid] ? data[companyUuid].slice(0, data[companyUuids[0]].length) : []
            const color = companyUuid === company?.uuid ? COLOR_GREEN : COLORS[count++]

            if (companyUuid === company?.uuid) {
                const ALLOWED_COMPANIES_FOR_SCRIPT =
                    JSON.parse(process.env.REACT_APP_ALLOWED_COMPANIES ?? '[]') as string[]

                const sessionsData = data[companyUuid].map(audience => ({
                    sessions: +audience.sessions,
                    timestamp: audience.timestamp
                }))
                const adjustedSessionsData = ALLOWED_COMPANIES_FOR_SCRIPT.includes(company!.uuid) ? smoothFluctuation(sessionsData, 20) : sessionsData.map(item => item.sessions)

                return {
                    label: rows[0].name,
                    tension: 0.2,
                    fill: false,
                    borderColor: color,
                    backgroundColor: color + '80',
                    borderWidth: 1,
                    yAxisID: 'y',
                    data: adjustedSessionsData || []
                }
            }

            return {
                label: rows[0].name,
                tension: 0.2,
                fill: false,
                borderColor: color,
                backgroundColor: color + '80',
                borderWidth: 1,
                yAxisID: 'y',
                data: rows?.map((row: any) => +row.sessions)
            }
        })

        if (chartData.datasets) {
            const hiddenLegends = chartData.datasets.filter((dataset: any) => dataset.hidden)

            if (hiddenLegends.length > 0) {
                hiddenLegends.forEach((hiddenLegend: any) => {
                    const index = chartData.datasets.findIndex((dataset: any) => dataset.label === hiddenLegend.label)
                    if (index !== -1) {
                        datasets[index].hidden = true
                    }
                })
            }
        }

        setChartData({
            labels,
            datasets
        })

        setLoading(false)
    }

    const getPositionData = async () => {
        const { data } = await ApiService.get<IAveragePositionResponse>(
            '/metrics/position',
            {
                params: {
                    from: dateFilter.from,
                    to: dateFilter.to
                }
            }
        )

        // mock de dados
        // const data = { position: 1 }

        setAverageAudiencePosition(data.position)
    }

    const legendClickHandler = (e: ChartEvent, legendItem: LegendItem) => {
        const index = legendItem.datasetIndex
        const hidden = !legendItem.hidden

        setChartData((prevState: any) => {
            const datasets = prevState.datasets.map((dataset: any, i: number) => {
                if (i === index) {
                    return {
                        ...dataset,
                        hidden
                    }
                }
                return dataset
            })

            return {
                ...prevState,
                datasets
            }
        })
    }

    useMemo(() => {
        getPositionData()
        getConcurrenceData()
    }, [dateFilter])

    return (
        <Card className={classes.cardContainer}>
            <CardContent className={classes.cardContent}>
                <Grid container className={classes.audienceGrid}>
                    {
                        loading
                            ? (
                                <Grid item xs={12} sm={12} className={classes.skeletonInsideGrid}>
                                    {
                                        _.times(28, (i: any) => (
                                            <Skeleton
                                                key={i}
                                                variant="rectangular" width="2.5%" height={(i + 1) * 11}
                                                style={{
                                                    float: 'left',
                                                    marginLeft: '1%',
                                                    marginTop: 346 - ((i + 1) * 11)
                                                }}
                                            />
                                        ))
                                    }
                                </Grid>
                            )
                            : (
                                <>
                                    <Grid item xs={12} sm={12}>
                                        <Grid container alignItems='center' justifyContent="center" flexDirection='column'>
                                            <Typography className={classes.titleText}>
                                                AUDIÊNCIA DA CONCORRÊNCIA ({averageAudiencePosition}º lugar em média)
                                            </Typography>
                                            {externalSessions && (
                                                <Typography className={classes.subtitle}>{externalSessions} sessões {formattedCurrentAudience(externalSessionsDate)}</Typography>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        {
                                            chartData &&
                                            chartData?.datasets &&
                                            <Line
                                                height={80}
                                                data={chartData}
                                                options={{
                                                    plugins: {
                                                        legend: {
                                                            position: 'top',
                                                            align: 'start',
                                                            onClick: legendClickHandler,
                                                            labels: {
                                                                color: theme.palette.primary[theme.palette.mode],
                                                                padding: 20
                                                            }
                                                        },
                                                        tooltip: {
                                                            backgroundColor: '#202227',
                                                            titleColor: '#ccccdc',
                                                            caretPadding: 150,
                                                            boxHeight: 1,
                                                            boxPadding: 3,
                                                            bodySpacing: 5,
                                                            itemSort: (a: any, b: any) => b.raw - a.raw,
                                                            callbacks: {
                                                                label: function (this: TooltipModel<'line'>, tooltipItem: TooltipItem<'line'>) {
                                                                    return `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue} ouvintes`
                                                                },
                                                                title: function (this: TooltipModel<'line'>, tooltipItem: TooltipItem<'line'>[]) {
                                                                    return dayjs(tooltipItem[0].label).format('DD/MM/YYYY HH:mm') + 'h'
                                                                }
                                                            }
                                                        }
                                                    },
                                                    responsive: true,
                                                    interaction: {
                                                        mode: 'index',
                                                        intersect: false
                                                    },
                                                    elements: {
                                                        point: {
                                                            radius: darkMode ? 1 : 0
                                                        }
                                                    },
                                                    scales: {
                                                        x: {
                                                            type: 'time',
                                                            time: {
                                                                unit: 'minute',
                                                                displayFormats: {
                                                                    minute: 'HH:mm'
                                                                }
                                                            },
                                                            grid: {
                                                                color: theme.palette.info[theme.palette.mode],
                                                                borderColor: theme.palette.info[theme.palette.mode]
                                                            },
                                                            ticks: {
                                                                color: theme.palette.primary[theme.palette.mode]
                                                            }
                                                        },
                                                        y: {
                                                            type: 'linear',
                                                            display: true,
                                                            position: 'left',
                                                            grid: {
                                                                color: theme.palette.info[theme.palette.mode],
                                                                borderColor: theme.palette.info[theme.palette.mode]
                                                            },
                                                            ticks: {
                                                                color: theme.palette.primary[theme.palette.mode]
                                                            }
                                                        }
                                                    }
                                                }}
                                            />
                                        }
                                    </Grid>
                                </>
                            )
                    }
                </Grid>
            </CardContent>
        </Card>
    )
}

export default Concurrence
