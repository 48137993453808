import { isMobileScreen } from '@/utils/device'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
    stack: {
        width: '100%',
        paddingRight: isMobileScreen ? theme.spacing(0) : theme.spacing(3),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'right'
    },
    slider: {
        marginLeft: theme.spacing(1)
    },
    iconButton: {
        marginRight: isMobileScreen ? theme.spacing(0) : theme.spacing(1)
    }
}))

export default useStyles
