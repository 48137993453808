import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
    cardContainer: {
        padding: 0,
        background: theme.palette.secondary[theme.palette.mode],
        border: '1px solid #ccccdc12'
    },
    cardContent: {
        paddingBottom: `${theme.spacing(1)} !important`
    },
    containerGrid: {
        flexGrow: 1,
        height: '100%'
    },
    tooltipContainer: {
        background: theme.palette.background.default,
        color: theme.palette.secondary.contrastText,
        width: '100%'
    },
    tooltipTitleText: {
        fontWeight: 700
    },
    tooltipValueText: {
        fontWeight: 600,
        fontSize: '14px'
    },
    tooltipDatetimeText: {
        fontSize: '13px'
    },
    headerContainer: {
        paddingBottom: theme.spacing(1)
    },
    selectLimit: {
        color: theme.palette.secondary.contrastText,
        height: '40px',
        width: '125px'
    },
    warningIllustration: {
        height: '180px',
        width: 'auto'
    },
    warningIllustrationGridContainer: {
        marginTop: '25px'
    },
    warningIllustrationText: {
        color: '#ccccdc', // theme.palette.secondary.contrastText,
        fontSize: '30px',
        textAlign: 'center'
    },
    titleText: {
        fontSize: 20,
        fontWeight: 600,
        color: theme.palette.primary[theme.palette.mode]
    },

    skeletonInsideGrid: {
        height: '348px'
    },

    cardTitle: {
        paddingBottom: '30px'
    }
}))

export default useStyles
