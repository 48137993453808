import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
    cardContainer: {
        padding: 0,
        background: theme.palette.secondary[theme.palette.mode],
        border: '1px solid #ccccdc12'
    },
    cardContent: {
        paddingBottom: `${theme.spacing(1)} !important`
    },
    audienceGrid: {
        flexGrow: 1
    },
    tooltipContainer: {
        padding: theme.spacing(2),
        background: theme.palette.secondary.main,
        borderRadius: theme.spacing(1),
        color: theme.palette.secondary.contrastText,
        boxShadow: '2px 2px gray'
    },
    tooltipValueText: {
        fontSize: '19px',
        fontWeight: 700
    },
    tooltipDatetimeText: {
        fontSize: '13px'
    },
    headerContainer: {
        paddingBottom: theme.spacing(1)
    },
    titleText: {
        fontSize: 20,
        fontWeight: 600,
        color: theme.palette.primary[theme.palette.mode]
    },
    selectIntervalInMinutes: {
        color: '#ccccdc', // theme.palette.secondary.contrastText,
        height: '40px',
        width: '150px'
    },
    averagePositionText: {
        fontSize: 18,
        fontWeight: 600,
        color: '#ccccdc' // theme.palette.secondary.contrastText
    },
    betaBadge: {
        marginTop: theme.spacing(1.8),
        marginLeft: theme.spacing(5)
    },
    filterChartCheckboxForm: {
        color: theme.palette.secondary.main
    },
    filterCheckbox: {
        color: '#fafafa'
    }
}))

export default useStyles
