import { useSnackbar } from 'notistack'
import React, { FormEvent, useState } from 'react'

import loopertLogo from '@/assets/illustrations/LogoHighLight.png'
import ApiService from '@/services/Api'
import {
    Grid,
    Button,
    TextField,
    CircularProgress,
    Card,
    CardContent,
    FormControlLabel,
    Checkbox,
    Link
} from '@mui/material'

import useStyles from './styles'

type Credentials = {
    email: string,
    password: string
}

const Login: React.FC = () => {
    document.body.style.background = '#111217'

    const classes = useStyles()

    const { enqueueSnackbar } = useSnackbar()

    const [credentials, setCredentials] = useState<Credentials>({
        email: '',
        password: ''
    })
    const [loadingCredentials, setLoadingCredentials] = useState<boolean>(false)
    const [timeoutId, setTimeoutId] = useState<any>(null)
    const [timeoutLoading, setTimeoutLoading] = useState<boolean>(false)
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [loadingRecoveryPassword, setLoadingRecoveryPassword] = useState<boolean>(false)
    const [loadingRecoveryRequest, setLoadingRecoveryRequest] = useState<boolean>(false)

    const requestRecovery = async () => {
        setLoadingRecoveryRequest(true)

        try {
            if (credentials?.email?.length < 5) {
                throw new Error('É necessário preencher o campo de email para solicitar a recuperação da senha.')
            }

            if (loadingRecoveryPassword) {
                setTimeoutLoading(true)
                throw new Error('Aguarde 30 segundos para solicitar a recuperação de senha novamente.')
            }

            setLoadingRecoveryPassword(true)

            await ApiService.get(
                `recovery-password/email/${credentials.email}`
            )

            if (timeoutId) {
                clearTimeout(timeoutId)
            }

            setLoadingRecoveryPassword(true)

            setTimeoutId(
                (await setTimeout(
                    () => {
                        setLoadingRecoveryPassword(false)
                        setTimeoutLoading(false)
                    }, 30000
                ))
            )

            enqueueSnackbar(
                'O link para recuperação da senha foi enviado com sucesso para o seu email.',
                { variant: 'success' }
            )
        } catch (error: any) {
            if (!timeoutLoading) {
                setLoadingRecoveryPassword(false)
            }

            enqueueSnackbar(
                error?.message || error?.data || 'Ocorreu um erro ao requisitar a troca de senha.',
                { variant: 'error' }
            )
        }
        setLoadingRecoveryRequest(false)
    }

    const handleLogin = async () => {
        if (loadingCredentials) return

        setLoadingCredentials(true)

        try {
            const { data } = await ApiService.post('/auth/login', credentials)

            localStorage.setItem('token', data.token)

            window.location.href = '/'
        } catch (e) {
            setLoadingCredentials(false)

            enqueueSnackbar('Ocorreu um erro ao fazer login', {
                variant: 'error'
            })
        }
    }

    return (
        <Grid container className={classes.container} alignContent="center" justifyContent="center">
            <Grid container alignContent="center" justifyContent="center" className={classes.logoGrid}>
                <img src={loopertLogo} className={classes.logoImage} alt="Loopert" />
            </Grid>
            <Card className={classes.cardContainer}>
                <CardContent className={classes.cardContent}>
                    <Grid item className={classes.input}>
                        <TextField
                            label="Email"
                            id="login-set-email-textfield"
                            variant="outlined"
                            size="small"
                            value={credentials.email}
                            fullWidth
                            onChange={
                                ({ target }) => setCredentials({ ...credentials, email: target.value.toLowerCase() })
                            }
                        />
                    </Grid>

                    <Grid item className={classes.input}>
                        <TextField
                            label="Senha"
                            variant="outlined"
                            id="login-set-password-textfield"
                            size="small"
                            fullWidth
                            type={showPassword ? 'text' : 'password'}
                            onChange={
                                ({ target }) => setCredentials({ ...credentials, password: target.value })
                            }
                        />
                        <Grid container justifyContent="space-between">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id="login-show-password-checkbox"
                                        checked={showPassword}
                                        onChange={() => setShowPassword(!showPassword)}
                                        color="primary"
                                    />
                                }
                                label="Mostrar"
                            />
                            <Link
                                component="button"
                                variant="body2"
                                className={classes.recoveryLink}
                                onClick={requestRecovery}
                                disabled={loadingRecoveryRequest}
                            >
                                Esqueci minha senha
                            </Link>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Button
                            fullWidth
                            id="login-signin-button"
                            type="button"
                            className={classes.signinButton}
                            color="primary"
                            variant="contained"
                            onClick={() => handleLogin()}
                            disabled={loadingCredentials || credentials?.email?.length < 5 || credentials?.password?.length < 3}
                            endIcon={loadingCredentials && (
                                <CircularProgress size={20} color="inherit" />
                            )}
                        >
                            Entrar
                        </Button>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default Login
